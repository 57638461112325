import { Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createNewProcess } from "../Redux/slice/process";

const HomePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const runOtherPrescription = async () => {
    // create a new process and start it
    localStorage.setItem("prescriptionID", "647d797ab1fb680b488cb3ea");
    const res = await dispatch(
      createNewProcess({ prescriptionId: "647d797ab1fb680b488cb3ea" })
    );
    if (res) {
      const { processId, nextActivity } = res.payload.data;
      localStorage.setItem("processID", res.payload.data.processId);
      localStorage.setItem("nextActivity", res.payload.data.nextActivity);
      navigate("/preview");
    }
  };
  useEffect(() => {
    if (localStorage.getItem("access_token")) {
      navigate("home/activities");
    } else {
      runOtherPrescription();
    }
  }, [localStorage.getItem("access_token")]);

  return <div style={{ padding: "2%", textAlign: "justify" }}></div>;
};

export default HomePage;

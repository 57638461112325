import React from "react";
import Layout from "../Ui/Layout/Layout";
import { useParams } from "react-router-dom";
import Activities from "../Component/HomeActivity/Activities/Activities";
import Collaborators from "../Component/HomeActivity/Collaborators/Collaborators";
import CreateActivities from "../Component/HomeActivity/CreateActivities/CreateActivities";
import PublicActivities from "../Component/HomeActivity/PublicActivities/PublicActivities";
import Messenger from "../Chat/Messenger/Messenger";
import Collaborator from "../Component/HomeActivity/Collaborator";
import ViewActivity from "../Component/HomeActivity/ViewActivity";
import Settings from "../Component/HomeActivity/Settings/Settings";
import ViewActivityEngagement from "../Component/HomeActivity/ViewActivityEngagement/ViewActivityEngagement";
import HelpContact from "../Component/HomeActivity/HelpContact/HelpContact";
import CreatActivityLayout from "../Component/HomeActivity/CreateActivities/CreatActivityLayout";
import CreateActivity from "../Component/HomeActivity/CreateActivities/CreateActivity";

const HomeActivity = () => {
  const params = useParams();
  let torender = null;
  if (params.id === "create-activity") {
    return <CreatActivityLayout />;
  } else {
    if (params.id === "activities") {
      torender = <Activities />;
    } else if (params.id === "collaborators") {
      torender = <Collaborators />;
    } else if (params.id === "create-activity") {
      torender = <CreateActivities />;
    } else if (params.id === "communicate") {
      torender = <Messenger />;
    } else if (params.id === "view-actiivity") {
      torender = <ViewActivity />;
    } else if (params.id === "help_&_contact") {
      torender = <HelpContact />;
    } else if (params.id === "view-actiivity-engagement") {
      torender = <ViewActivityEngagement />;
    } else if (params.id === "settings") {
      torender = <Settings />;
    } else if (params.id === "collaborator") {
      torender = <Collaborator />;
    } else if (
      !localStorage.getItem("access_token") ||
      params.id === "public_activities"
    ) {
      torender = <PublicActivities />;
    }
    return <Layout>{torender}</Layout>;
  }
};

export default HomeActivity;

import React from "react";
import { Route, Routes } from "react-router-dom";
import ForgotPassword from "../Component/Auth/ForgotPassword";
import Login from "../Component/Auth/Login";
import Register from "../Component/Auth/Register";
import HomePage from "../Pages/HomePage";
import DesignView from "../Component/DesignView/DesignView";
import RequireAuth from "./RequireAuth";
import PrescriptionList from "../Component/PrescriptionList/PrescriptionList";
import ReadOnlyView from "../Component/ReadOnlyView/ReadOnlyView";
import Messenger from "../Chat/Messenger/Messenger";
import Preview from "../Component/Preview/Preview";
import ShowFullLog from "../Component/ShowFullLog/ShowFullLog";
import ShowUserLog from "../Component/ShowUserLog/ShowUserLog";
import HomeActivity from "../Pages/HomeActivity";
import PageNotFound from "../Pages/PageNotFound";
import TermsCondition from "../Component/Terms/TermsCondition";

const Routers = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/home/:id" element={<HomeActivity />} />
      <Route path="/chat" element={<Messenger />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/terms" element={<TermsCondition />} />
      <Route path="/login/forgotPassword" element={<ForgotPassword />} />
      <Route path="/prescriptionlist" element={<PrescriptionList />} />
      <Route path="/readonly" element={<ReadOnlyView />} />
      <Route path="/preview" element={<Preview />} />
      <Route element={<RequireAuth />}>
        <Route path="/userlog" element={<ShowUserLog />} />
        <Route path="/design" element={<DesignView />} />
        <Route path="/fulllog" element={<ShowFullLog />} />
      </Route>
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default Routers;

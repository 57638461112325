import {
  Box,
  Button,
  Divider,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import AppsOutlinedIcon from "@mui/icons-material/AppsOutlined";
import IosShareIcon from "@mui/icons-material/IosShare";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import ArchiveIcon from "@mui/icons-material/Archive";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Hover = {
  "&:hover": {
    backgroundColor: "gray.100",
  },
};

const FontStyle = {
  backgroundColor: "transparent",
  color: "#344054",
  fontFamily: "Roboto",
};

const iconStyle = {
  color: "#666666",
};

const OptionsPop = ({
  idOptions,
  openOptions,
  anchorElOptions,
  handleCloseOptions,
  item,
  handlePoperClickOptions,
  shareLink,
  duplicateActivity,
  handlePublish,
  handleArchive,
  selectedIndex,
  editActivity,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Box>
      <Stack
        component={"button"}
        sx={{ backgroundColor: "transparent" }}
        aria-describedby={idOptions}
        variant="contained"
        onClick={handlePoperClickOptions}
        direction={"row"}
        alignItems={"center"}
        spacing={"10px"}
      >
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 400,
            fontFamily: "Roboto",
            textTransform: "capitalize",
            color: "gray.800",
          }}
        >
          {t("view_more")}
        </Typography>
        <KeyboardArrowDownOutlinedIcon color="gray.400" />
      </Stack>
      <Popover
        elevation={1}
        id={idOptions}
        open={openOptions}
        anchorEl={anchorElOptions}
        onClose={handleCloseOptions}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box
          padding={1}
          display={"flex"}
          flexDirection={"column"}
          gap={1}
          position={"relative"}
        >
          <Box display={"flex"} flexDirection={"column"} gap={1}>
            <Stack sx={Hover}>
              <Stack alignItems={"center"} direction={"row"}>
                <ModeEditOutlineOutlinedIcon sx={iconStyle} />
                <Typography
                  sx={FontStyle}
                  component="button"
                  onClick={() => {
                    editActivity(selectedIndex);
                    handleCloseOptions();
                  }}
                >
                  {t("edit")}
                </Typography>
              </Stack>
              <Divider />
            </Stack>
            <Stack sx={Hover}>
              <Stack alignItems={"center"} direction={"row"}>
                <IosShareIcon sx={iconStyle} />
                <Typography
                  sx={FontStyle}
                  component="button"
                  onClick={() => {
                    shareLink(selectedIndex);
                    handleCloseOptions();
                  }}
                >
                  {t("share")}
                </Typography>
              </Stack>
              <Divider />
            </Stack>
            <Stack sx={Hover}>
              <Stack alignItems={"center"} direction={"row"}>
                <ContentCopyIcon sx={iconStyle} />
                <Typography
                  component="button"
                  sx={FontStyle}
                  onClick={() => {
                    duplicateActivity(selectedIndex);
                    handleCloseOptions();
                  }}
                >
                  {t("duplicate")}
                </Typography>
              </Stack>
              <Divider />
            </Stack>

            <Stack sx={Hover}>
              <Stack alignItems={"center"} direction={"row"}>
                <CompareArrowsIcon sx={iconStyle} />
                <Typography
                  component="button"
                  sx={FontStyle}
                  onClick={() => {
                    handlePublish(selectedIndex);
                    handleCloseOptions();
                  }}
                >
                  {t("submit_for_publishing")}
                </Typography>
              </Stack>
              <Divider />
            </Stack>
            <Stack sx={Hover}>
              <Stack alignItems={"center"} direction={"row"}>
                <Inventory2OutlinedIcon sx={iconStyle} />
                <Typography
                  component="button"
                  sx={FontStyle}
                  onClick={() => {
                    navigate("/home/view-actiivity", { state: item });
                  }}
                >
                  {t("view_engagements")}
                </Typography>
              </Stack>
              <Divider />
            </Stack>

            <Stack sx={Hover}>
              <Stack alignItems={"center"} direction={"row"}>
                <ArchiveIcon sx={iconStyle} />
                <Typography
                  component="button"
                  sx={{ backgroundColor: "transparent", color: "#F95345" }}
                  onClick={() => {
                    handleArchive(selectedIndex);
                    handleCloseOptions();
                  }}
                >
                  {t("archive")}
                </Typography>
              </Stack>
              <Divider />
            </Stack>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};

export default OptionsPop;

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { getActivityEngagementsByCollaborator } from "../../../Redux/slice/collaborators";
import { useDispatch } from "react-redux";
import { getEngagementsByProcess } from "../../../Redux/slice/process";
import CardActivity from "../Card/CardActivity";
import EngagementActivity from "../Card/EngagementActivity";
import { getPrescriptionsById } from "../../../Redux/slice/prescriptions";

const steps = [{}, {}, {}];
const ViewActivityEngagement = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const [activeStep, setActiveStep] = React.useState();
  const [expanded, setExpanded] = React.useState(null);
  const [egngagements, setEgngagements] = React.useState();
  const dispatch = useDispatch();
  const [engagmentCardValue, setEngagementCardValue] = useState();
  const [engagementData, setEngagementData] = useState();
  const [activity, setActivity] = useState();
  const [engagement, setEngagement] = useState();

  const handleChange = (panel) => async (event, newExpanded) => {
    setExpanded(newExpanded ? panel._id : false);
    if (activity) {
      const data = activity?.cells?.find(
        (cell) => cell.id === panel.activity && cell.type === "Activity"
      );
      setEngagementData(data);
    }
    if (panel?.userData) {
      setEngagementCardValue(JSON.parse(panel.userData));
      setEngagement(panel);
    }
    if (newExpanded) {
    }
  };

  const fetchActivityById = async () => {
    try {
      const res = await dispatch(getPrescriptionsById(state.state._id));
      if (res.payload) {
        setActivity(JSON.parse(res.payload.data.diagramJSON));
      }
    } catch (error) {}
  };

  const fetchGetEngagementsByProcess = async () => {
    try {
      const res = await dispatch(getEngagementsByProcess(state.data._id));
      if (res.payload) {
        setEgngagements(res.payload.data);
        setActiveStep(res.payload?.data?.length);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchGetEngagementsByProcess();
    fetchActivityById();
    setActiveStep();
  }, [dispatch]);

  return (
    <Box display="flex" flexDirection="column">
      <Box>
        <Typography fontSize={"22px"} fontWeight={500} padding={2}>
          {state.state.name}/Engagments
        </Typography>
        <Divider />
      </Box>
      <Box display="flex">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="65%"
        >
          <EngagementActivity
            engagement={engagement}
            engagementData={engagementData}
            engagmentCardValue={engagmentCardValue}
            setEngagementCardValue={setEngagementCardValue}
          />
        </Box>
        <Box
          padding={2}
          display="flex"
          flexDirection="column"
          sx={{
            backgroundColor: "#D3D3D3",
            height: "calc(100vh - 130px)",
            overflow: "auto",
          }}
          width="35%"
        >
          <Box>
            <Typography fontSize={"14px"} fontWeight={500} padding={2}>
              Operation 1
            </Typography>
            <Divider />
          </Box>
          <Box display="flex" padding={2}>
            <Box>
              <Stepper activeStep={activeStep} orientation="vertical">
                {egngagements &&
                  egngagements?.map((step, index) => (
                    <Step key={step._id}>
                      <StepLabel></StepLabel>
                    </Step>
                  ))}
              </Stepper>
            </Box>
            <Box width="100%" display="flex" flexDirection={"column"} gap={2}>
              {egngagements &&
                egngagements.map((item) => (
                  <Accordion
                    key={item._id}
                    expanded={expanded === item?._id}
                    onChange={handleChange(item)}
                  >
                    <AccordionSummary>
                      <Box
                        width="100%"
                        display="flex"
                        justifyContent="space-between"
                      >
                        <Typography fontSize={"14px"}>
                          {item?.message}
                        </Typography>
                        <Typography fontSize={"14px"}>
                          {new Date(item.createdAt).toLocaleDateString()}
                        </Typography>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box
                        width="90%"
                        mx={"auto"}
                        display="flex"
                        flexDirection={"column"}
                        gap={1}
                      >
                        <Box
                          display="flex"
                          width="100%"
                          justifyContent="space-between"
                        >
                          <Typography fontWeight="bold" fontSize="14px">
                            Activity
                          </Typography>
                          <Typography fontSize="14px">
                            {item?.activityName ? item?.activityName : "None"}
                          </Typography>
                        </Box>
                        <Divider />
                        <Box
                          display="flex"
                          width="100%"
                          justifyContent="space-between"
                        >
                          <Typography fontWeight="bold" fontSize="14px">
                            Option
                          </Typography>
                          <Typography fontSize="14px">
                            {item?.selectedOptionId
                              ? item?.selectedOptionId
                              : "None"}
                          </Typography>
                        </Box>
                        <Divider />
                        <Box
                          display="flex"
                          width="100%"
                          justifyContent="space-between"
                        >
                          <Typography fontWeight="bold" fontSize="14px">
                            User Data
                          </Typography>
                          <Typography fontSize="14px">
                            {item?.userData ? item?.userData : "None"}
                          </Typography>
                        </Box>
                        <Divider />
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ViewActivityEngagement;

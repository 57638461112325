import {
  Box,
  Button,
  Divider,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import CustomizedAccordions from "./Accordian";
import { useDispatch, useSelector } from "react-redux";
import PersonIcon from "@mui/icons-material/Person";
import { getCollaboratorActivitiesById } from "../../Redux/slice/collaborators";

const Collaborator = () => {
  const { state } = useLocation();
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredCollaborators, setFilteredCollaborators] = useState([]);

  const collaborators = useSelector((s) => s.collaborators?.data?.data);
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  useEffect(() => {
    if (searchQuery.trim()) {
      if (collaborators) {
        const filteredData = collaborators?.filter((collaborator) =>
          collaborator?.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredCollaborators(filteredData);
      }
    } else {
      setFilteredCollaborators(collaborators);
    }
  }, [collaborators, searchQuery]);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const getAllCollaboratorEngagements = async () => {
    try {
      const res = await dispatch(getCollaboratorActivitiesById(state._id));
    } catch (error) {}
  };
  useEffect(() => {
    getAllCollaboratorEngagements();
  }, [dispatch]);
  const handlePoperClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <>
      <Box>
        <Box>
          <Box
            display={"flex"}
            alignItems={"center"}
            fontSize={"22px"}
            fontWeight={500}
            padding={2}
          >
            <Typography> {t("collaborators")}</Typography>
            <ChevronRightIcon />
            <Typography>{state.fullName}</Typography>
          </Box>
          <Divider />
          <Box
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            paddingX={6}
          >
            <PersonIcon sx={{ fontSize: "3rem" }} />
            <Box padding={2} display={"flex"} flexDirection={"column"}>
              <Typography variant="subtitle1">{state.fullName}</Typography>
              <Typography variant="caption">{state.email}</Typography>
              <Typography variant="caption">{state.role}</Typography>
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            gap={2}
            width="80%"
            paddingX={6}
          >
            <Box
              paddingX={2}
              alignItems={"center"}
              display={"flex"}
              justifyContent={"space-between"}
            >
              <Typography>Activities shared with {state.fullName}</Typography>
              {localStorage.getItem("collaboratorPrivate") ? null : (
                <TextField
                  name="search"
                  label="search"
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              )}
            </Box>
            <CustomizedAccordions
              state={state}
              collaborators={filteredCollaborators}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Collaborator;

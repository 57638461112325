import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

function DesignMenus({ onClick }) {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        position: "absolute",
        zIndex: 1000,
        top: "50%",
        right: 40,
      }}
    >
      <div>
        <ul onClick={onClick}>
          <li
            style={{
              backgroundColor: "#175384",
              padding: "6px",
              color: "#fff",
              margin: "4px",
              cursor: "pointer",
            }}
            data-id="zoomin"
          >
            {t("zoom_plus")}
          </li>
          <li
            style={{
              backgroundColor: "#175384",
              padding: "6px",
              color: "#fff",
              margin: "4px",
              cursor: "pointer",
            }}
            data-id="zoomout"
          >
            {t("zoom_minus")}
          </li>
        </ul>
      </div>
    </Box>
  );
}

export default DesignMenus;

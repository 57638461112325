import {
  Box,
  Button,
  Divider,
  Popover,
  TablePagination,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import AppsOutlinedIcon from "@mui/icons-material/AppsOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import PlayCircleFilledWhiteOutlinedIcon from "@mui/icons-material/PlayCircleFilledWhiteOutlined";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";

import { useDispatch, useSelector } from "react-redux";
import useAuth from "../../../hooks/useAuth";
import {
  getPublicPrescriptions,
  searchPublicPrescription,
} from "../../../Redux/slice/publicPrescriptions";
import {
  copyPublicPrescription,
  sharePrescriptionById,
} from "../../../Redux/slice/prescriptions";
import CustomizedDialogs from "../../../Ui/Shared/Modal/Modal";
import { createNewProcess } from "../../../Redux/slice/process";
import OptionsPopUpPublic from "./OptionPopUpPublic";

const PublicActivities = () => {
  const { t } = useTranslation();
  const publicPrescription = useSelector(
    (s) => s.publicPrescription?.data?.data
  );
  const dispatch = useDispatch();
  const { user } = useAuth();
  const [refetch, setRefetch] = useState(false);
  const [anchorElOptions, setAnchorElOptions] = React.useState(null);
  const [openModal, setOpnenModal] = useState(false);
  const [messageShow, setMessageShow] = useState("");
  const [lableSearches, setLableSearches] = useState([]);
  const [lable, setLable] = useState("");
  const [userSearches, setUserSearches] = useState([]);
  const [userlable, setUserLable] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(4);
  const [page, setPage] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleChangeUser = (e) => {
    setUserLable(e.target.value);
  };

  const handleKeyDownUser = (event) => {
    if (event.key === "Enter") {
      setUserSearches([...userSearches, userlable]);
      setUserLable("");
    }
  };

  const runOtherPrescription = async (idx) => {
    // create a new process and start it
    localStorage.setItem("prescriptionID", idx._id);
    const res = await dispatch(createNewProcess({ prescriptionId: idx._id }));
    if (res) {
      localStorage.setItem("processID", res.payload.data.processId);
      localStorage.setItem("nextActivity", res.payload.data.nextActivity);
      window.open("/preview", "_blank");
    }
  };

  const handleChange = (e) => {
    setLable(e.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      setLableSearches([...lableSearches, lable]);
      setLable("");
    }
  };

  const handlePoperClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteUser = (labelToDelete) => {
    setUserSearches(userSearches.filter((label) => label !== labelToDelete));
  };

  const handleDelete = (labelToDelete) => {
    setLableSearches(lableSearches.filter((label) => label !== labelToDelete));
  };

  const getPrescriptions = async () => {
    try {
      const res = await dispatch(getPublicPrescriptions());
      if (res) {
        setRefetch(true);
      }
    } catch (error) {}
  };

  const shareLink = async (id) => {
    try {
      const res = await dispatch(sharePrescriptionById(id));
      if (res.payload.id) {
        navigator.clipboard.writeText(
          `${window.location.origin}/preview?id=${res.payload.id}`
        );
        setMessageShow("Activity Copy to clipboard Successfully");
        setOpnenModal(true);
      }
    } catch (error) {}
  };

  const duplicateActivity = async (id) => {
    try {
      const res = await dispatch(copyPublicPrescription(id));
      if (res.payload) {
      }
    } catch (error) {}
  };

  const handlePoperClickOptions = (event, id) => {
    setSelectedIndex(id);
    setAnchorElOptions(event.currentTarget);
  };

  const handleCloseOptions = () => {
    setAnchorElOptions(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const openOptions = Boolean(anchorElOptions);
  const idOptions = openOptions ? "simple-popover" : undefined;
  useEffect(() => {
    if (lableSearches.length !== 0 || userSearches.length !== 0) {
      dispatch(
        searchPublicPrescription({
          labels: lableSearches,
          users: userSearches,
        })
      );
    } else if (lableSearches.length === 0 && userSearches.length === 0) {
      getPrescriptions();
    }
  }, [lableSearches, userSearches]);

  useEffect(() => {
    getPrescriptions();
  }, [user, refetch]);
  return (
    <>
      <Box>
        <Typography fontSize={"22px"} fontWeight={500} padding={2}>
          {t("public_activities")}
        </Typography>
        <Divider />
      </Box>
      <Box
        display={"flex"}
        justifyContent={"space-around"}
        alignItems={"center"}
      >
        <Box>
          <Typography></Typography>
        </Box>
      </Box>
      <Box display={"flex"} flexDirection={"column"} gap={2} width={"75%"}>
        <Box padding={2} display={"flex"} justifyContent={"flex-end"}>
          <Box
            sx={{
              backgroundColor: "#fff",
              borderRadius: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* Filters */}
            <Button
              aria-describedby={id}
              variant="contained"
              onClick={handlePoperClick}
            >
              <FilterAltOutlinedIcon />
            </Button>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              sx={{
                left: 70,
              }}
            >
              <Box
                padding={2}
                display={"flex"}
                flexDirection={"column"}
                gap={2}
                position={"relative"}
                zIndex={0}
              >
                <Box
                  onClick={handleClose}
                  sx={{
                    position: "absolute",
                    zIndex: 10000,
                    top: 0,
                    right: 0,
                    borderRadius: "100%",
                    backgroundColor: "grey",
                    width: 30,
                    height: 30,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  X
                </Box>
                <Box display={"flex"} flexDirection={"column"} gap={2}>
                  <TextField
                    variant="outlined"
                    name="name"
                    value={lable}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                  />
                  <Typography>Label</Typography>
                  <Box display={"flex"} flexWrap={"wrap"} gap={1}>
                    {lableSearches.length !== 0 &&
                      lableSearches.map((item) => (
                        <Box
                          fontSize={"12px"}
                          position={"relative"}
                          px={2}
                          py={1}
                          border={2}
                          borderColor={"#000"}
                          borderRadius={20}
                        >
                          {item}
                          <Box
                            component={"button"}
                            onClick={() => handleDelete(item)}
                            sx={{
                              position: "absolute",
                              top: -10,
                              right: -10,
                              borderRadius: "100%",
                              backgroundColor: "grey",
                              width: 30,
                              height: 30,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              cursor: "pointer",
                              color: "#fff",
                            }}
                          >
                            X
                          </Box>
                        </Box>
                      ))}
                  </Box>
                  <Divider />
                </Box>
                <Box display={"flex"} flexDirection={"column"} gap={2}>
                  <TextField
                    variant="outlined"
                    name="name"
                    value={userlable}
                    onChange={handleChangeUser}
                    onKeyDown={handleKeyDownUser}
                  />
                  <Typography>User</Typography>
                  <Box display={"flex"} flexWrap={"wrap"} gap={1}>
                    {userSearches.length !== 0 &&
                      userSearches.map((item) => (
                        <Box
                          fontSize={"12px"}
                          position={"relative"}
                          px={2}
                          py={1}
                          border={2}
                          borderColor={"#000"}
                          borderRadius={20}
                        >
                          {item}
                          <Box
                            component={"button"}
                            onClick={() => handleDeleteUser(item)}
                            sx={{
                              position: "absolute",
                              top: -10,
                              right: -10,
                              borderRadius: "100%",
                              backgroundColor: "grey",
                              width: 30,
                              height: 30,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              cursor: "pointer",
                              color: "#fff",
                            }}
                          >
                            X
                          </Box>
                        </Box>
                      ))}
                  </Box>
                  <Divider />
                </Box>
                <Box display={"flex"} flexDirection={"column"} gap={2}>
                  <Typography>Status</Typography>
                  <Box display={"flex"} flexDirection={"column"} gap={1}>
                    <Typography>Active</Typography>
                    <Typography>Recently Used</Typography>
                    <Typography>Overdue</Typography>
                  </Box>
                </Box>
              </Box>
            </Popover>
          </Box>
        </Box>
        <Divider />
        <Box
          sx={{
            height: "45vh",
            overflow: "auto",

            "&::-webkit-scrollbar": {
              width: "0.3em",
            },
            "&::-webkit-scrollbar-track": {
              "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#175384",
            },
          }}
        >
          {publicPrescription &&
            publicPrescription
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item) => (
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  padding={2}
                >
                  <Box alignItems={"center"} display={"flex"} gap={1}>
                    <PersonOutlineIcon fontSize="large" />
                    <Box display={"flex"} flexDirection={"column"}>
                      <Typography fontSize={"14px"}>
                        {`${t("created_by")} ${item.fiUser.fullName}`}
                      </Typography>
                      <Typography fontSize={"10px"}>
                        {t("last_edited")}
                        {new Date(item.updatedAt).toLocaleDateString()}
                      </Typography>
                    </Box>
                  </Box>
                  <Box display={"flex"} gap={2} alignItems={"center"}>
                    <PlayCircleFilledWhiteOutlinedIcon
                      onClick={() => runOtherPrescription(item)}
                    />
                    <Box
                      sx={{
                        backgroundColor: "#fff",
                        borderRadius: "5px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <OptionsPopUpPublic
                        idOptions={idOptions}
                        openOptions={openOptions}
                        anchorElOptions={anchorElOptions}
                        handleCloseOptions={handleCloseOptions}
                        item={item}
                        selectedIndex={selectedIndex}
                        handlePoperClickOptions={(event) =>
                          handlePoperClickOptions(event, item._id)
                        }
                        shareLink={shareLink}
                        duplicateActivity={duplicateActivity}
                      />
                    </Box>
                  </Box>
                </Box>
              ))}
          <CustomizedDialogs open={openModal} setOpen={setOpnenModal}>
            {messageShow}
          </CustomizedDialogs>
        </Box>
        <TablePagination
          rowsPerPageOptions={[4, 10, 25]}
          component="div"
          count={publicPrescription && publicPrescription.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={t("rows_per_page")}
          dir="ltr"
        />
      </Box>
    </>
  );
};

export default PublicActivities;

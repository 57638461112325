/* eslint-disable no-unused-expressions */
import {
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  Container,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Popover,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PlayCircleFilledWhiteOutlinedIcon from "@mui/icons-material/PlayCircleFilledWhiteOutlined";
import { useDispatch, useSelector } from "react-redux";
import useAuth from "../../../hooks/useAuth";
import FilterIcon from "../../../../src/Assets/filterIcon.svg";
import {
  archivePrescription,
  copyPublicPrescription,
  getUserPrescriptions,
  searchUserPrescription,
  sharePrescriptionById,
  sharePrescriptions,
  userPrescriptionName,
} from "../../../Redux/slice/prescriptions";
import { Link, useNavigate } from "react-router-dom";
import CustomizedDialogs from "../../../Ui/Shared/Modal/Modal";
import { getUsersActivtyData } from "../../../Redux/slice/user";
import { createNewProcess } from "../../../Redux/slice/process";
import OptionsPop from "./OptionsPop";
import UserListHead from "./UserListHead";
import { accessed, statuses } from "./utils";
import CancelIcon from "@mui/icons-material/Cancel";
import { filter } from "lodash";
import i18next from "i18next";

const serachInitialValues = {
  status: "",
  lastAccessed: "",
  activityName: "",
};
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis?.map((el) => el[0]);
}

const Activities = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const prescription = useSelector((s) => s.prescription?.data?.data);
  const dispatch = useDispatch();
  const { auth, user } = useAuth();
  const [refetch, setRefetch] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElOptions, setAnchorElOptions] = React.useState(null);
  const [openModal, setOpnenModal] = useState(false);
  const [messageShow, setMessageShow] = useState("");
  const [lableSearches, setLableSearches] = useState([]);
  const [lable, setLable] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(7);
  const [page, setPage] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [searchValues, setSearchValues] = useState(serachInitialValues);
  const prescriptionName = useSelector((s) => s.prescription?.presName?.data);
  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const TABLE_HEAD = [
    { id: "", label: "", alignRight: false },
    { id: "name", label: t("activity_name"), alignRight: false },
    { id: "fiUser", label: t("created_by"), alignRight: false },
    { id: "tags", label: t("tags"), alignRight: false },
    { id: "updatedAt", label: t("last_accessed"), alignRight: false },
    { id: "status", label: t("status"), alignRight: false },
    { id: "" },
  ];

  const userActivity = useSelector((s) => s.user?.activityData);
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const handleSearchChange = (e) => {
    const { name, value } = e.target;
    setSearchValues({
      ...searchValues,
      [name]: value,
    });
  };

  const handleKeyDownCapture = (e) => {};

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = prescription?.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  useEffect(() => {
    localStorage.removeItem("prescriptionID");
    localStorage.removeItem("draftprescription");
  }, []);

  const runOtherPrescription = async (idx) => {
    // create a new process and start it
    localStorage.setItem("prescriptionID", idx._id);
    const res = await dispatch(createNewProcess({ prescriptionId: idx._id }));
    if (res) {
      const { processId, nextActivity } = res.payload.data;
      localStorage.setItem("processID", res.payload.data.processId);
      localStorage.setItem("nextActivity", res.payload.data.nextActivity);
      window.open("/preview", "_blank");
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleChange = (e) => {
    setLable(e.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      setLableSearches([...lableSearches, lable]);
      setLable("");
    }
  };

  const handleDelete = (labelToDelete) => {
    setLableSearches(lableSearches.filter((label) => label !== labelToDelete));
  };
  const getPrescriptions = async () => {
    if (user?.email) {
      try {
        const res = await dispatch(getUserPrescriptions());
        const response = await dispatch(getUsersActivtyData());
        const presName = await dispatch(userPrescriptionName());
        if (res) {
          setRefetch(true);
        }
      } catch (error) {}
    } else {
    }
  };

  const handlePublish = async (item) => {
    try {
      const res = await dispatch(sharePrescriptions(item));
    } catch (error) {}
  };

  const handleEditActivity = (item) => {
    localStorage.setItem("prescriptionID", item);
    localStorage.setItem("userID", item);
    navigate("/home/create-activity");
  };

  const handleArchive = async (item) => {
    try {
      const res = await dispatch(archivePrescription(item));
      if (res.payload) {
        setRefetch(!refetch);
      }
    } catch (error) {}
  };
  const duplicateActivity = async (item) => {
    try {
      const res = await dispatch(copyPublicPrescription(item));
      if (res.payload) {
        setRefetch(!refetch);
      }
    } catch (error) {}
  };

  const shareLink = async (item) => {
    try {
      const res = await dispatch(sharePrescriptionById(item));
      if (res.payload.id) {
        navigator.clipboard.writeText(
          `${window.location.origin}/preview?id=${res.payload.id}&activityId=${item}`
        );
        setMessageShow("Activity Copy Successfully");
        setOpnenModal(true);
      }
    } catch (error) {}
  };

  const handlePoperClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClickOpenOptions = (event, id) => {
    setSelectedIndex(id);
    setAnchorElOptions(event.currentTarget);
  };
  const handlePoperClickOptions = (event) => {
    setAnchorElOptions(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseOptions = () => {
    setAnchorElOptions(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const openOptions = Boolean(anchorElOptions);
  const idOptions = openOptions ? "simple-popover" : undefined;

  const handleDeleteSearch = (item) => {
    const itmStatus = statuses.find((itm) => itm.name === item);
    const itmDate = accessed.find((itm) => itm.date === item);
    const itmName = prescriptionName.find((itm) => itm.name === item);

    if (itmName) {
      setSearchValues({
        ...searchValues,
        activityName: "",
      });
    }
    if (itmStatus) {
      setSearchValues({
        ...searchValues,
        status: "",
      });
    }
    if (itmDate) {
      setSearchValues({
        ...searchValues,
        lastAccessed: "",
      });
    }
  };

  useEffect(() => {
    if (searchValues.length !== 0) {
      dispatch(searchUserPrescription(searchValues));
    } else if (searchValues.length === 0) {
      getPrescriptions();
    }
  }, [searchValues]);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - prescription?.length) : 0;

  const filteredUsers = applySortFilter(
    prescription,
    getComparator(order, orderBy)
  );

  const isNotFound = !filteredUsers?.length;

  useEffect(() => {
    getPrescriptions();
  }, [user, refetch]);

  const status = [];
  return (
    <>
      {localStorage.getItem("access_token") ? (
        <>
          <Stack
            paddingX={"24px"}
            paddingY={"21.5px"}
            direction={"row"}
            justifyContent="flex-end"
            alignItems="center"
          >
            <Link to="/home/create-activity">
              <Typography
                fontSize={"16px"}
                lineHeight="22px"
                fontWeight={400}
                fontFamily={"Roboto"}
                color="primary.600"
                border={1}
                borderColor="primary.600"
                borderRadius={"2px"}
                paddingY={"10px"}
                paddingX={"24px"}
              >
                {t("new_activity")}
              </Typography>
            </Link>
          </Stack>

          <Container maxWidth="md">
            <Box
              width={"100%"}
              display={"flex"}
              justifyContent={"space-evenly"}
              alignItems={"center"}
              gap={6}
              padding={1}
            >
              <Box
                width={"184px"}
                height={"90px"}
                sx={{
                  backgroundColor: "primary.100",
                  borderRadius: "8px",
                  padding: "14px 28.5px 18px 15.5px",
                  direction: i18next.dir(),
                }}
                color={"black"}
                display={"flex"}
                alignItems={"center"}
              >
                <Typography
                  fontFamily={"Roboto"}
                  fontWeight={500}
                  fontSize={"32px"}
                  sx={{
                    marginInlineEnd: "10px",
                  }}
                >
                  {userActivity?.data?.accessCount}
                </Typography>
                <Typography
                  fontFamily={"Roboto"}
                  lineHeight={"18px"}
                  fontSize={"14px"}
                  fontWeight={500}
                >
                  {t("activities_the_past_week")}
                </Typography>
              </Box>
              <Box
                width={"184px"}
                height={"90px"}
                sx={{
                  backgroundColor: "primary.200",
                  borderRadius: "8px",
                  padding: "14px 28.5px 18px 15.5px",
                  direction: i18next.dir(),
                }}
                color={"black"}
                display={"flex"}
                alignItems={"center"}
              >
                <Typography
                  fontFamily={"Roboto"}
                  fontWeight={500}
                  fontSize={"32px"}
                  sx={{
                    marginInlineEnd: "10px",
                  }}
                >
                  {userActivity?.data?.pendingActivityCount}
                </Typography>
                <Typography
                  fontFamily={"Roboto"}
                  lineHeight={"18px"}
                  fontSize={"14px"}
                  fontWeight={500}
                >
                  {t("activities_pending_for_publishing")}
                </Typography>
              </Box>
              <Box
                width={"184px"}
                height={"90px"}
                sx={{
                  backgroundColor: "primary.100",
                  borderRadius: "8px",
                  padding: "14px 28.5px 18px 15.5px",
                  direction: i18next.dir(),
                }}
                color={"black"}
                display={"flex"}
                alignItems={"center"}
              >
                <Typography
                  fontFamily={"Roboto"}
                  fontWeight={500}
                  fontSize={"32px"}
                  sx={{
                    marginInlineEnd: "10px",
                  }}
                >
                  {userActivity?.data?.sharedActivityCount}
                </Typography>
                <Typography
                  fontFamily={"Roboto"}
                  lineHeight={"18px"}
                  fontSize={"14px"}
                  fontWeight={500}
                >
                  {t("new_activities_shared_with_you")}
                </Typography>
              </Box>
            </Box>
          </Container>
          <Box display={"flex"} flexDirection={"column"} gap={1} width={"100%"}>
            <Box paddingX={2} display={"flex"} justifyContent={"space-between"}>
              <Stack direction={"row"} spacing={1}>
                {Object.values(searchValues)?.map(
                  (item) =>
                    item !== "" && (
                      <Stack
                        direction={"row"}
                        spacing={1}
                        paddingX={2}
                        alignItems={"center"}
                        sx={{ backgroundColor: "#E0E0E0", borderRadius: 20 }}
                      >
                        <Typography>
                          {typeof item === "object"
                            ? item.toLocaleDateString()
                            : item === ""
                            ? null
                            : item}
                        </Typography>
                        <IconButton onClick={() => handleDeleteSearch(item)}>
                          <CancelIcon />
                        </IconButton>
                      </Stack>
                    )
                )}
              </Stack>
              <Box
                sx={{
                  backgroundColor: "white",
                  borderRadius: "5px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  paddingX: 2,
                  "&:hover": {
                    backgroundColor: "gray.100",
                  },
                }}
              >
                <Typography>{t("filter")}</Typography>
                <IconButton>
                  <img
                    src={FilterIcon}
                    alt="filte_icon"
                    aria-describedby={id}
                    onClick={handlePoperClick}
                  />
                </IconButton>

                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  sx={{
                    left: -60,
                  }}
                >
                  <Box
                    width={305}
                    display={"flex"}
                    flexDirection={"column"}
                    paddingX={"37.5px"}
                    paddingY={"54px"}
                    gap={"24px"}
                    position={"relative"}
                    zIndex={0}
                  >
                    <Stack spacing={"24px"}>
                      <Stack spacing={1}>
                        <Stack
                          direction={"row"}
                          justifyContent={"space-between"}
                          sx={{
                            direction: i18next.dir(),
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "16px",
                              fontWeight: 500,
                              fontFamily: "Roboto",
                              textTransform: "uppercase",
                            }}
                          >
                            {t("filters")}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "16px",
                              fontWeight: 400,
                              cursor: "pointer",
                            }}
                            color={"#0099FF"}
                            onClick={() => setSearchValues(serachInitialValues)}
                          >
                            {t("reset")}
                          </Typography>
                        </Stack>
                        <Divider />
                      </Stack>
                      <FormControl fullWidth size="small">
                        <InputLabel id="demo-simple-select-standard-label">
                          {t("status")}
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          name="status"
                          value={searchValues.status}
                          onChange={handleSearchChange}
                          onChangeCapture={handleKeyDownCapture}
                          sx={{
                            paddingX: "0px",
                            paddingY: "0px",
                          }}
                          label={t("status")}
                          placeholder={t("status")}
                          dir={i18next.dir()}
                        >
                          {statuses.map((item) => (
                            <MenuItem value={item.name} key={item.id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl fullWidth size="small">
                        <InputLabel id="demo-simple-select-standard-label">
                          {t("activity_name")}
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          name="activityName"
                          value={searchValues.activityName}
                          onChange={handleSearchChange}
                          label={t("activity_name")}
                          dir={i18next.dir()}
                        >
                          {prescriptionName?.map((item) => (
                            <MenuItem value={item.name} key={item._id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl fullWidth size="small">
                        <InputLabel id="demo-simple-select-standard-label">
                          {t("last_accessed")}
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          name="lastAccessed"
                          value={searchValues.lastAccessed}
                          onChange={handleSearchChange}
                          onKeyDownCapture={handleKeyDownCapture}
                          label="Last Accessed"
                          dir={i18next.dir()}
                        >
                          {accessed.map((item) => (
                            <MenuItem value={item.date} key={item.id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Stack>
                  </Box>
                </Popover>
              </Box>
            </Box>
            <Divider />

            <TableContainer>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={prescription?.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((row) => {
                      const { _id, name, fiUser, status, company, updatedAt } =
                        row;
                      const selectedUser = selected.indexOf(name) !== -1;

                      return (
                        <TableRow
                          sx={{ padding: 0 }}
                          hover
                          key={_id}
                          tabIndex={-1}
                        >
                          <TableCell sx={{ width: "150px", paddingY: 0 }}>
                            <PlayCircleFilledWhiteOutlinedIcon
                              sx={{ color: "#7B61FF" }}
                              onClick={() => runOtherPrescription(row)}
                            />
                          </TableCell>

                          <TableCell
                            sx={{
                              fontSize: "16px",
                              fontWeight: 400,
                              fontFamily: "Roboto",
                              textTransform: "capitalize",
                              paddingY: 0,
                              letterSpacing: 0,
                            }}
                          >
                            {" "}
                            {name}
                          </TableCell>

                          <TableCell
                            sx={{
                              fontSize: "16px",
                              fontWeight: 400,
                              fontFamily: "Roboto",
                              textTransform: "capitalize",
                              paddingY: 0,
                              letterSpacing: 0,
                            }}
                          >
                            {fiUser?.fullName}
                          </TableCell>

                          <TableCell
                            sx={{
                              fontSize: "16px",
                              fontWeight: 400,
                              fontFamily: "Roboto",
                              textTransform: "capitalize",
                              paddingY: 0,
                              letterSpacing: 0,
                            }}
                          >
                            {company}
                          </TableCell>

                          <TableCell
                            sx={{
                              fontSize: "16px",
                              fontWeight: 400,
                              fontFamily: "Roboto",
                              textTransform: "capitalize",
                              paddingY: 0,
                              letterSpacing: 0,
                            }}
                          >
                            {new Date(updatedAt).toLocaleDateString()}
                          </TableCell>

                          <TableCell
                            sx={{
                              fontSize: "16px",
                              fontWeight: 400,
                              fontFamily: "Roboto",
                              textTransform: "capitalize",
                              paddingY: 0,
                              letterSpacing: 0,
                            }}
                          >
                            {status}
                          </TableCell>

                          <TableCell align="right">
                            <OptionsPop
                              idOptions={idOptions}
                              openOptions={openOptions}
                              anchorElOptions={anchorElOptions}
                              handleCloseOptions={handleCloseOptions}
                              item={row}
                              selectedIndex={selectedIndex}
                              handlePoperClickOptions={(event) =>
                                handleClickOpenOptions(event, row._id)
                              }
                              editActivity={handleEditActivity}
                              shareLink={shareLink}
                              duplicateActivity={duplicateActivity}
                              handlePublish={handlePublish}
                              handleArchive={handleArchive}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: "center",
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[7, 10, 25]}
              component="div"
              count={prescription && prescription.length}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage={t("rows_per_page")}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              dir={"ltr"}
            />
            <CustomizedDialogs open={openModal} setOpen={setOpnenModal}>
              {messageShow}
            </CustomizedDialogs>
          </Box>
        </>
      ) : (
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          height={"calc(100vh - 65px)"}
        >
          <Typography>
            Please{" "}
            <Link to={"/login"}>
              <span
                style={{
                  color: "#175384",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
              >
                Sign In
              </span>
            </Link>{" "}
            for activities
          </Typography>
        </Box>
      )}
    </>
  );
};

export default Activities;

import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import logoImg from "../Assets/brand.svg";
import { Link } from "react-router-dom";
import LanguageIcon from "@mui/icons-material/Language";
import { Menu, MenuItem } from "@mui/material";
import Routers from "../Utils/Routers";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { distributionService } from "../services/distributionService";
import { directionService } from "../services/directionService";
import Anchor from "./Anchor";
import { TbUserCircle } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { getUsersData, handleLogout } from "../Redux/slice/user";
import useAuth from "../hooks/useAuth";

const drawerWidth = 240;
const navItems = ["Home", "About", "Contact"];
const availableLanguages = [
  {
    code: "en",
    name: "English",
    country_code: "GB-UKM",
  },
  {
    code: "he",
    name: "עברית",
    country_code: "IL",
    dir: "rtl",
  },
];

function Navbar(props) {
  const { window } = props;
  const dispatch = useDispatch();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  // Header
  const { auth, user } = useAuth();

  const [currentLanguageCode, setCurrentLanguageCode] = React.useState("");
  const [currentLanguage, setCurrentLanguage] = React.useState({});
  const [previousLanguage, setPreviousLanguage] = React.useState({});

  const { t } = useTranslation();
  React.useEffect(() => {
    document.body.dir = currentLanguage?.dir || "ltr";

    // register change of RTL<->LTR
    if (previousLanguage?.dir !== currentLanguage?.dir) {
      directionService.sendDirectionChange();
    }
    setPreviousLanguage(currentLanguage);
  }, [currentLanguage]);

  React.useEffect(() => {
    let newLanguage = availableLanguages.find(
      (l) => l.code === currentLanguageCode
    );

    setCurrentLanguage(newLanguage);
  }, [currentLanguageCode]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const selectLanguage = (code) => {
    i18next.changeLanguage(code);
    setCurrentLanguageCode(code);
    handleClose();
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography color="textColors.secondary" variant="h6" sx={{ my: 2 }}>
        MUI
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: "center" }}>
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box width={"100%"} sx={{ display: "flex" }} height={"65px"}>
      <AppBar
        component="nav"
        sx={{ backgroundColor: "primary.500", boxShadow: "none" }}
      >
        <Toolbar
          sx={{ display: "flex", justifyContent: "space-between" }}
          className="navbar-toolbar"
        >
          <Box width={"100%"} display={"flex"} gap={2}>
            <Link
              to={
                localStorage.getItem("access_token") ? "/home/activities" : "/"
              }
            >
              <Box display={"flex"} alignItems="center" gap={2}>
                <img width={"31px"} height={"25px"} src={logoImg} alt="" />
                <Typography
                  fontSize={"20px"}
                  lineHeight={"23px"}
                  fontWeight={500}
                  fontFamily={"Roboto"}
                  color={"gray.50"}
                >
                  ClaryNext
                </Typography>
              </Box>
            </Link>
          </Box>

          <Box
            width={"100%"}
            display="flex"
            gap={2}
            justifyContent={"flex-end"}
            alignItems="center"
          >
            {localStorage.getItem("access_token") ? (
              <Box color={"white"} display={"flex"} alignItems="center" gap={1}>
                <TbUserCircle
                  size={30}
                  onClick={() => dispatch(getUsersData())}
                />
                <Typography variant="basic">
                  {auth?.fullName || user?.fullName}
                </Typography>
                <Link to="login">
                  <Typography
                    paddingX={2}
                    color="white"
                    onClick={() => {
                      localStorage.removeItem("access_token");
                      dispatch(handleLogout());
                    }}
                  >
                    {t("logout")}
                  </Typography>
                </Link>
              </Box>
            ) : (
              <Link to="/login">
                <Box
                  color={"white"}
                  display={"flex"}
                  alignItems="center"
                  gap={1}
                >
                  <TbUserCircle size={30} />
                  <Typography variant="basic">Sign in</Typography>
                </Box>
              </Link>
            )}

            <LanguageIcon
              className="lang-icon"
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            />
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {availableLanguages.map((lang, idx) => (
                <MenuItem
                  key={idx}
                  onClick={() => selectLanguage(lang.code)}
                  dir={lang?.dir}
                >
                  {lang.name}
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

Navbar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Navbar;

import { callPrivateAPi } from "./http-common";

const getUserConversations = () => {
  return callPrivateAPi.get("/api/getUserConversations");
};
const getUserInfo = (id) => {
  return callPrivateAPi.get(`/api/getUserInfo/${id}`);
};

const addMessages = (data) => {
  return callPrivateAPi.post("api/addMessage", data);
};
const getAllMessagesByConversationId = (id) => {
  return callPrivateAPi.get(`api/getMessagesByConversationId/${id}`);
};

const getExperts = () => {
  return callPrivateAPi.get("/api/getExperts");
};
const createNewConversation = (data) => {
  return callPrivateAPi.post("api/newConversation", data);
};
const ChatServices = {
  getUserConversations,
  getUserInfo,
  addMessages,
  getAllMessagesByConversationId,
  getExperts,
  createNewConversation,
};

export default ChatServices;

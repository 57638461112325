import "jquery-ui/ui/widgets/draggable";
import "jquery-ui/ui/widgets/droppable";
import "jquery-ui/ui/widgets/sortable";

const RenderDialog = {
  render: function (dialog, node, $, currentActivity) {
    this.dialog = dialog;
    this.data = {};
    this.$ = $;

    if (!node) {
      if (!currentActivity) {
        for (let i = 0; i < dialog.nodes.length; i++) {
          if (dialog.nodes[i].id === dialog.root) {
            node = dialog.nodes[i];
            break;
          }
        }
      } else {
        for (let i = 0; i < dialog.nodes.length; i++) {
          if (dialog.nodes[i].id === currentActivity) {
            node = dialog.nodes[i];
            break;
          }
        }
      }
    }

    if (!node) {
      throw new Error("It is not clear where to go next.");
    }

    if (!this.el) {
      this.el = this.createElement("div", "qad-dialog");
    }

    // Empty previously rendered dialog.
    this.el.textContent = "";

    switch (node.type) {
      case "qad.Question":
        this.renderQuestion(node);
        break;
      default:
        break;
    }

    this.renderDetailsBox(node);

    if (node.data?.optionalComment) {
      const commentLabelElement = document.createElement("h4");
      commentLabelElement.innerHTML = "Your comment:";
      const editorElement = this.createElement("div", "rich-text-editor");
      let contentElement = this.el.querySelector(".qad-content");
      contentElement.appendChild(commentLabelElement);
      contentElement.appendChild(editorElement);
    }

    switch (node.type) {
      case "qad.Question":
        this.renderOptions(node);
        break;
      default:
        break;
    }

    this.currentNode = node;

    return this.el;
  },

  enterComment: function (comment) {
    this.data["comment"] = comment;
  },

  renderDetailsBox: function (node) {
    const data = node.data;
    if (!data) return;

    const details = this.createElement("div", "qad-detail");
    const {
      richText,
      checkboxes,
      listItems,
      textBox,
      guidanceText,
      progressIndicator,
      timerActivity,
      twoDfeedback,
    } = data;

    if (timerActivity) {
      const container = this.createElement("div", "qad-Timer-detail");
      this.renderTimerActivity(container, timerActivity, node);

      details.appendChild(container);
    }
    if (richText) {
      let container = this.createElement("div", "qad-text-detail");
      const str = richText.replace(/&lt;/g, "<");
      container.innerHTML = str;
      details.appendChild(container);
    }

    if (listItems) {
      const container = this.createElement("div", "qad-listItems-detail");
      listItems.forEach((listItem) =>
        this.renderlistItem(container, listItem, node)
      );
      details.appendChild(container);
    }
    if (textBox) {
      const container = this.createElement("div", "qad-textBox-detail");
      textBox.forEach((itemText) =>
        this.renderitemText(container, itemText, node)
      );
      details.appendChild(container);
    }
    if (guidanceText && guidanceText !== null) {
      const container = this.createElement("div", "qad-guidanceText-detail");
      guidanceText?.forEach((guidance) =>
        guidance.guidanceText !== null
          ? this.renderguidance(container, guidance, node)
          : null
      );
      details.appendChild(container);
    }

    if (checkboxes) {
      const container = this.createElement("div", "qad-checkboxes-detail");
      checkboxes.forEach((checkbox) =>
        this.renderCheckbox(container, checkbox, node)
      );
      details.appendChild(container);
    }

    if (progressIndicator) {
      const container = this.createElement("div", "qad-twoDfeedback-detail");

      const canvas = this.createElement("canvas");
      canvas.width = 100;
      canvas.height = 100;
      canvas.style.border = "1px solid #eeeeee";
      canvas.style.borderRadius = "5px";
      canvas.style.backgroundColor = "#dddddd";

      container.appendChild(canvas);

      const textareaLabel = this.createElement("span");
      textareaLabel.style.visibility = "hidden";
      const br1 = this.createElement("br");
      const br2 = this.createElement("br");
      const feedbackTextarea = this.createElement("textarea");
      feedbackTextarea.rows = 5;
      feedbackTextarea.cols = 60;
      feedbackTextarea.style.visibility = "hidden";
      container.appendChild(br1);
      container.appendChild(textareaLabel);
      container.appendChild(br2);
      container.appendChild(feedbackTextarea);

      details.appendChild(container);

      canvas.addEventListener("click", (e) => {
        let xValue = Math.floor(e.offsetX);
        let yValue = Math.floor(100 - e.offsetY);

        this.data["twoDfeedback"] = {
          x: xValue,
          y: yValue,
        };

        let context = canvas.getContext("2d");
        context.clearRect(0, 0, canvas.width, canvas.height);

        let selectedColor = {};

        let cyan = yValue;
        let yellow = xValue;
        let magenta = 0;
        let kontrast = 0;

        selectedColor.r = Math.floor(
          255 * (1 - cyan / 100) * (1 - kontrast / 100)
        );
        selectedColor.g = Math.floor(
          255 * (1 - magenta / 100) * (1 - kontrast / 100)
        );
        selectedColor.b = Math.floor(
          255 * (1 - yellow / 100) * (1 - kontrast / 100)
        );

        context.fillStyle = `rgba(${selectedColor.r}, ${selectedColor.g}, ${
          selectedColor.b
        }, ${Math.max(xValue, yValue) / 100})`;
        context.fillRect(0, 0, canvas.width, canvas.height);

        context.beginPath();
        context.arc(e.offsetX, e.offsetY, 3, 0, 2 * Math.PI, false);
        context.fillStyle = "#5890c3";
        context.fill();

        if ((xValue < 20 && yValue < 20) || xValue > 75 || yValue > 75) {
          textareaLabel.style.visibility = "visible";
          if (xValue < 20 && yValue < 20)
            textareaLabel.innerHTML = "Little progress noted, please explain:";
          else
            textareaLabel.innerHTML = "Good progress noted, please elaborate:";
          feedbackTextarea.style.visibility = "visible";
        } else {
          textareaLabel.style.visibility = "hidden";
          feedbackTextarea.style.visibility = "hidden";
        }
      });

      feedbackTextarea.addEventListener("change", (e) => {
        this.data["twoDfeedbackexplanation"] = e.target.value;
      });
    }

    this.el.querySelector(".qad-content")?.appendChild(details);
  },

  renderTimerActivity: function (container, timerActivity, node) {
    container.classList.add("timer-activity");

    const label = document.createElement("h3");
    label.textContent = timerActivity.label;
    container.appendChild(label);

    const timerElement = document.createElement("div");
    timerElement.id = "timer";
    container.appendChild(timerElement);

    // Append the container to the document or a parent element
    document.body.appendChild(container);
    const nodeId = this.getIdFromTitle(timerActivity?.timerActivity);

    this.runTime(timerActivity, nodeId);
  },

  runTime: function (timerActivity, nodeId) {
    const { inputTime } = timerActivity;
    const totalSeconds =
      inputTime.hours * 3600 + inputTime.minutes * 60 + inputTime.seconds;

    let secondsRemaining = totalSeconds;
    const timerElement = document.getElementById("timer");

    const interval = setInterval(() => {
      const hours = Math.floor(secondsRemaining / 3600);
      const minutes = Math.floor((secondsRemaining % 3600) / 60);
      const seconds = secondsRemaining % 60;

      const formattedTime = `${this.padZero(hours)}:${this.padZero(
        minutes
      )}:${this.padZero(seconds)}`;
      timerElement.textContent = formattedTime;
      secondsRemaining--;
      if (secondsRemaining < 0) {
        clearInterval(interval);
        timerElement.textContent = "Timer finished!";
        if (nodeId) {
          this.onTimerOption(nodeId);
        }
      }
    }, 1000);
  },

  padZero: function (value) {
    return value.toString().padStart(2, "0");
  },

  showRichTextEditorValue: function (value) {
    if (value) {
      const images = [];
      const regex = /src="data:image\/png;base64,([^"]+)"/g;
      let match;

      while ((match = regex.exec(value))) {
        const base64String = match[1];

        try {
          const image = atob(base64String);
          const img = new Image();
          img.src = "data:image/png;base64," + image;
          images.push(img);
        } catch (error) {}
      }

      const div = document.createElement("div");
      div.innerHTML = value;

      for (const img of images) {
        div.appendChild(img);
      }

      return div;
    } else {
      return null;
    }
  },

  renderguidance: function (container, data, node) {
    function camelize(str) {
      return str
        .replace(/^\s+|\s+$/g, "")
        .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
          return index === 0 ? word.toLowerCase() : word.toUpperCase();
        })
        .replace(/\s+|\?/g, "");
    }
    function decodeHtmlEntities(html) {
      var txt = document.createElement("textarea");
      txt.innerHTML = html;
      return txt.value;
    }

    const { id, guidanceText } = data;

    const labelEl = document.createElement("label");
    labelEl.className = "guidanceText-label";
    labelEl.setAttribute("for", guidanceText?.id);

    const label = document.createElement("h3");
    label.textContent = guidanceText?.label;
    const value = document.createElement("h3");
    value.textContent = guidanceText?.placeholder;

    const elRichText = document.createElement("div");
    if (elRichText) {
      elRichText.innerHTML = decodeHtmlEntities(guidanceText?.richTextBody);
    }

    if (
      elRichText.innerHTML !== "false" &&
      elRichText.innerHTML !== "undefined"
    ) {
      Array?.from(elRichText.children)?.forEach((child) => {
        if (child.tagName === "IMG") {
          // If the element is an image (IMG tag), create an img element and set its src attribute
          const imgElement = document.createElement("img");
          imgElement.src = child.src;
          elRichText.appendChild(imgElement);
        }
      });
    }

    labelEl.appendChild(label);
    labelEl.appendChild(value);
    if (
      elRichText.innerHTML !== "false" &&
      elRichText.innerHTML !== "undefined"
    ) {
      labelEl.appendChild(elRichText);
    }

    container.appendChild(labelEl);
  },

  renderitemText: function (container, data, node) {
    function camelize(str) {
      return str
        .replace(/^\s+|\s+$/g, "")
        .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
          return index === 0 ? word.toLowerCase() : word.toUpperCase();
        })
        .replace(/\s+|\?/g, "");
    }

    function decodeHtmlEntities(html) {
      var txt = document.createElement("textarea");
      txt.innerHTML = html;
      return txt.value;
    }

    const { id, textBox } = data;
    const labelEl = this.$("<label></label>", {
      class: "textBox-label",
      for: `${textBox.id}`,
    });

    const label = this.$("<h3></h3>").text(textBox.label);
    var value;
    if (!textBox.richTextBody) {
      value = this.$("<h3></h3>").text(textBox?.placeholder);
    }
    label.appendTo(labelEl);
    value?.appendTo(labelEl);

    if (textBox.richTextBody) {
      const richTextEditor = this.createElement("div", "richTextEditor");
      richTextEditor.id = `richTextEditor_${id}`;
      richTextEditor.innerHTML = decodeHtmlEntities(textBox.richTextBody);
      richTextEditor.contentEditable = true;
      richTextEditor.style.border = "1px solid #ccc";
      richTextEditor.style.minHeight = "100px";
      richTextEditor.style.padding = "5px";
      richTextEditor.style.marginTop = "10px";
      richTextEditor.addEventListener("input", function () {
        // Handle input event to update the value
        textBox.richTextBody = richTextEditor.innerHTML;
      });
      labelEl.append(richTextEditor);
    }

    labelEl.appendTo(container);
  },

  renderCheckbox: function (container, data, node) {
    function camelize(str) {
      return str
        .replace(/^\s+|\s+$/g, "")
        .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
          return index === 0 ? word.toLowerCase() : word.toUpperCase();
        })
        .replace(/\s+|\?/g, "");
    }
    const { id, selectionItem } = data;
    const checkboxLabel = this.$("<label></label>", {
      class: "selection-label",
      for: `${selectionItem.id}`,
      id: `${selectionItem.id}`,
      name: camelize(selectionItem.label),
    });
    selectionItem.allSelectionItem.forEach((item) => {
      const labelEl = this.$("<label></label>", {
        class: "checkbox-label",
        for: `${item.id}`,
      });
      const checkboxEl = this.$("<input/>", {
        class: "checkbox",
        id: `${item.id}`,
        checked: item.checked,
        type: "checkbox",
        name: camelize(item.label),
      });
      const span = this.$("<span></span>").text(item.label);
      checkboxEl.appendTo(checkboxLabel);
      span.appendTo(checkboxLabel);
      labelEl.appendTo(checkboxLabel);

      checkboxEl.on("change", (evt) => {
        this.data[evt.target.name] = evt.target.checked;
      });
    });
    const selectionLabel = this.$("<span></span>").text(selectionItem.label);

    selectionLabel.appendTo(container);
    checkboxLabel.appendTo(container);
  },
  /* global $ */ // Add this directive comment

  renderlistItem: function (container, data, node) {
    function camelize(str) {
      return str
        .replace(/^\s+|\s+$/g, "")
        .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
          return index === 0 ? word.toLowerCase() : word.toUpperCase();
        })
        .replace(/\s+|\?/g, "");
    }

    const { id, listItem } = data;
    const listItemLabel = $("<label></label>", {
      class: "listItems-label",
      for: `${listItem.id}`,
      id: `${listItem.id}`,
      name: camelize(listItem.label),
    });

    function createListItemContainer(item) {
      const listItemContainer = $("<div></div>", {
        class: "listItem-container",
      });

      const labelEl = $("<label></label>", {
        class: "listItem-label",
        for: `${item.id}`,
      });

      const inputEl = $("<input></input>", {
        class: "listItem-input",
        type: "text",
        value: item.label,
      });

      const span = $("<span></span>").append(inputEl);
      span.appendTo(labelEl);
      labelEl.appendTo(listItemContainer);

      // Add delete button for each list item
      const deleteButton = $("<button></button>", {
        class: "delete-button",
        text: "Delete",
      }).appendTo(listItemContainer);

      return listItemContainer;
    }

    listItem.allListItem.forEach((item) => {
      const listItemContainer = createListItemContainer(item);
      listItemContainer.appendTo(listItemLabel);
    });

    const addListItemButton = $("<button></button>", {
      class: "add-listItem-button",
      text: "Add List Item",
    });

    addListItemButton.appendTo(listItemLabel);

    const listItemLbl = $("<span></span>").text(listItem.label);
    listItemLbl.appendTo(container);
    listItemLabel.appendTo(container);

    // Enable sortable functionality to drag and reorder list items
    $(listItemLabel).sortable({
      items: ".listItem-container",
      axis: "y",
      containment: container,
      cursor: "move",
      opacity: 0.8,
      handle: ".listItem-label",
      update: function (event, ui) {
        // Get the updated order of the list items
        const sortedListItems = [];
        $(listItemLabel)
          .find(".listItem-container")
          .each(function () {
            const id = $(this).find(".listItem-label").attr("for");
            const label = $(this).find(".listItem-input").val();
            sortedListItems.push({ id, label });
          });

        // Perform the reorder operation based on the new order of the list items
        data.listItem.allListItem = sortedListItems;
      },
    });

    // Make list items draggable
    $(listItemLabel).find(".listItem-container").draggable({
      containment: container,
      cursor: "move",
      opacity: 0.8,
      handle: ".listItem-label",
    });

    // Add event listener for delete button click
    $(container).on("click", ".delete-button", function (event) {
      const listItemContainer = $(event.target).closest(".listItem-container");
      listItemContainer.remove();
    });

    // Add event listener for add list item button click
    $(container).on("click", ".add-listItem-button", function (event) {
      const newItem = {
        id: "new-item-" + new Date().getTime(), // Generate a unique ID for the new item
        label: "New List Item",
      };

      const listItemContainer = createListItemContainer(newItem);
      listItemContainer.insertBefore($(event.target));

      // Update the order of the list items after adding a new item
      $(listItemLabel).sortable("refresh");
    });
  },

  createElement: function (tagName, className) {
    var el = document.createElement(tagName);
    el.setAttribute("class", className);
    return el;
  },

  renderOption: function (option) {
    var elOption = this.createElement("button", "qad-option qad-button");
    elOption.textContent = option.text;
    elOption.setAttribute("data-option-id", option.id);

    var self = this;
    elOption.addEventListener(
      "click",
      function (evt) {
        self.onOptionClick(evt);
      },
      false
    );

    return elOption;
  },

  renderOptions: function (node) {
    var elOptions = this.createElement("div", "qad-options");

    for (var i = 0; i < node.options.length; i++) {
      elOptions.appendChild(this.renderOption(node.options[i]));
    }

    this.el.querySelector(".qad-content")?.appendChild(elOptions);
  },

  renderQuestion: function (node) {
    var elContent = this.createElement("div", "qad-content");

    let closebtn = this.createElement("span", "qad-close");

    function decodeHtmlEntities(html) {
      var txt = document.createElement("textarea");
      txt.innerHTML = html;
      return txt.value;
    }

    closebtn.innerHTML = "X";
    elContent.appendChild(closebtn);

    closebtn.addEventListener(
      "click",
      (e) => {
        // End of dialog
        this.$("#preview").empty();
        this.$(".background").remove();
        if (this.callback_pause) this.callback_pause();
      },
      false
    );

    var elQuestion = this.createElement("h3", "qad-question-header");
    const elRichText = this.createElement("div", "qad-question-header");

    elRichText.innerHTML = decodeHtmlEntities(node.question);

    elQuestion.innerHTML = node.question;

    this.$(elRichText).appendTo(elContent); // Convert elRichText to jQuery object and use appendTo

    this.el.appendChild(elContent);
  },

  onOptionClick: function (evt) {
    var elOption = evt.target;
    var optionId = elOption.getAttribute("data-option-id");

    var outboundLink;
    for (var i = 0; i < this.dialog.links.length; i++) {
      var link = this.dialog.links[i];
      if (
        link.source.id === this.currentNode.id &&
        link.source.port === optionId
      ) {
        outboundLink = link;
        break;
      }
    }

    if (outboundLink) {
      var nextNode;
      for (var j = 0; j < this.dialog.nodes.length; j++) {
        var node = this.dialog.nodes[j];
        if (node.id === outboundLink.target.id) {
          nextNode = node;
          break;
        }
      }

      if (nextNode) {
        let prevData = { ...this.data };
        this.render(this.dialog, nextNode, this.$);
        if (this.callback_nextactivity) {
          this.callback_nextactivity(optionId, prevData, nextNode.id);
        }
      } else {
        // End of dialog
        this.$("#preview").empty();
        this.$(".background").remove();
        if (this.callback_done) this.callback_done(optionId, this.data);
      }
    } else {
      // End of dialog
      this.$("#preview").empty();
      this.$(".background").remove();
      if (this.callback_done) this.callback_done(optionId, this.data);
    }
  },

  getIdFromTitle: function (title) {
    if (title) {
      const parser = new DOMParser();
      var nodeId;
      for (var j = 0; j < this.dialog.nodes.length; j++) {
        var node = this.dialog.nodes[j];
        const decodedString = parser.parseFromString(node.question, "text/html")
          .body.textContent;
        const extractedText = decodedString.replace(/<\/?[^>]+(>|$)/g, "");

        if (extractedText === title) {
          nodeId = node.id;
          return nodeId;
        }
      }
    }
  },

  onTimerOption: function (optionId) {
    if (optionId) {
      var nextNode;
      for (var j = 0; j < this.dialog.nodes.length; j++) {
        var node = this.dialog.nodes[j];
        if (node.id === optionId && optionId !== this.currentNode.id) {
          nextNode = node;
          break;
        }
      }

      if (nextNode) {
        let prevData = { ...this.data };
        this.render(this.dialog, nextNode, this.$);
        if (this.callback_nextactivity) {
          this.callback_nextactivity(optionId, prevData, nextNode.id);
        }
      } else {
        // End of dialog
        this.$("#preview").empty();
        this.$(".background").remove();
        if (this.callback_done) this.callback_done(optionId, this.data);
      }
    } else {
      // End of dialog
      this.$("#preview").empty();
      this.$(".background").remove();
      if (this.callback_done) this.callback_done(optionId, this.data);
    }
  },

  onDone: function (cb) {
    this.callback_done = cb;
  },
  onNextActivity: function (cb) {
    this.callback_nextactivity = cb;
  },
  onPause: function (cb) {
    this.callback_pause = cb;
  },
};

export default RenderDialog;

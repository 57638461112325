import { callPrivateAPi } from "./http-common";

const getUserData = () => {
  return callPrivateAPi.get("/api/getUserData");
};

const userActivityData = () => {
  return callPrivateAPi.get("/api/userActivityData");
};

const UserServices = {
  getUserData,
  userActivityData,
};

export default UserServices;

import {
  Box,
  Button,
  Checkbox,
  Divider,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { v4 as uuidv4 } from "uuid";
import { SortableList } from "@thaddeusjiang/react-sortable-list";
import "@thaddeusjiang/react-sortable-list/dist/index.css";
import { ReactSortable } from "react-sortablejs";
import ConnectOptions from "./ConnectOptions";
import Timer from "./Timer";

const interactionsValues = {
  commenBox: false,
  textField: false,
  checkBox: false,
  listComponent: false,
};

const interactionsButton = [
  {
    id: "commenBox",
    name: "Comment Box",
  },
  {
    id: "textField",
    name: "Text Field",
  },
  {
    id: "checkBox",
    name: "Check Box",
  },
  {
    id: "listComponent",
    name: "List Component",
  },
];

const initialvalues = [
  {
    id: "",
    commentBox: "",
  },
  {
    id: "",
    textField: "",
  },
  {
    id: "",
    checkBox: false,
  },
];

const CardActivity = ({
  setActivityData,
  setAddActivity,
  graphStateClick,
  refetch,
  removegraphStateClick,
  fullActivity,
  setTargetActivity,
}) => {
  const [interactions, setInteractions] = useState(interactionsValues);
  const [anchorElOptions, setAnchorElOptions] = React.useState(null);
  const [anchorElConnectOptions, setAnchorElConnectOptions] =
    React.useState(null);

  const [selectedButton, setSelectedButton] = useState();
  const [selectedButtons, setSelectedButtons] = useState([]);
  const [count, setCount] = useState(1);
  const [activityTitle, setActivityTitle] = useState();
  const [fetch, setFetch] = useState(false);
  const [values, setValues] = useState([]);
  const [cellId, setCellId] = useState();
  const [graphState, setGraphState] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [duplicateShow, setDuplicateShow] = useState(false);
  const [totalSeconds, setTotalSeconds] = useState(0);
  const [inputTime, setInputTime] = useState({
    seconds: 0,
    minutes: 0,
    hours: 0,
  });
  const [listItems, setListItems] = useState("");
  const [allListItems, setallListItems] = useState([]);

  let cellsData = [];
  let cellsValue = {};
  let interactionsClick = {};
  const connections = fullActivity?.cells?.filter(
    (item) => item.type === "Activity"
  );
  useEffect(() => {
    setGraphState(false);
    setInteractions(interactionsValues);
    setCellId();
    setSelectedButtons([]);
    setActivityTitle("");
  }, [removegraphStateClick, graphStateClick]);
  useEffect(() => {
    if (graphStateClick) {
      setGraphState(true);
    }
  }, [graphStateClick]);
  useEffect(() => {
    if (graphStateClick && graphState) {
      const id = graphStateClick.data && graphStateClick.id;
      const richText = graphStateClick.data && graphStateClick.data.richText;
      const activityName = graphStateClick && graphStateClick.activityName;
      setCellId(id);
      setActivityTitle(activityName);
      const checkboxes =
        graphStateClick.data && graphStateClick.data.checkboxes;
      const options = graphStateClick.data && graphStateClick.options;
      const richId = uuidv4();
      if (richText !== "") {
        interactionsClick.commenBox = true;
        cellsData.push({
          btnId: "commenBox",
          id: richId,
        });
        const decodedText = richText
          ?.replace(/&lt;/g, "<")
          ?.replace(/&gt;/g, ">")
          ?.replace(/&amp;/g, "&")
          ?.replace(/<[^>]+>/g, "");

        cellsValue[richId] = decodedText;
      }
      if (checkboxes) {
        interactionsClick.checkBox = true;
      }
      checkboxes?.forEach((item) => {
        cellsData.push({
          btnId: "checkBox",
          id: item.id,
        });
        cellsValue[item.id] = item.label;
      });
      if (options) {
        interactionsClick.textField = true;
      }
      options?.forEach((item) => {
        cellsData.push({
          btnId: "textField",
          id: item.id,
        });

        cellsValue[item.id] = item.text;
      });
      setInteractions({
        ...interactions,
        ...interactionsClick,
      });
      setDuplicateShow(true);
      setSelectedButtons(cellsData);
      setValues(cellsValue);
      setFetch(true);
    }
  }, [graphStateClick, fetch]);

  const handleInputChange = (e, id) => {
    if (e.target.type === "checkbox") {
      setValues({
        ...values,
        [e.target.id]: e.target.checked,
      });
    } else {
      setValues({
        ...values,
        [e.target.id]: e.target.value,
      });
    }
  };

  const handleButtonClick = (e) => {
    const btnId = e.target.dataset.id;
    setSelectedButton(btnId);
    if (Object.keys(interactions).includes(btnId)) {
      setSelectedButtons([...selectedButtons, { id: uuidv4(), btnId: btnId }]);
      setInteractions({
        ...interactions,
        [btnId]: true,
      });
    }
  };
  let options = [];
  let richText = "";
  let checkboxes = [];
  const handleSubmit = (e) => {
    e.preventDefault();
    const res = selectedButtons.filter((item) => item.btnId === "textField");
    const commentBox = selectedButtons.find(
      (item) => item.btnId === "commenBox"
    );
    if (commentBox) {
      richText = values[commentBox.id];
    }

    const checkBox = selectedButtons.filter(
      (item) => item.btnId === "checkBox"
    );

    res.forEach((item, index) => {
      if (Object.keys(values).find((elm) => elm === item.id)) {
        options.push({
          id: item.id,
          text: values[item.id],
        });
      }
    });

    checkBox.forEach((item, index) => {
      if (Object.keys(values).find((elm) => elm === item.id)) {
        checkboxes.push({
          label: values[item.id],
          id: item.id,
          checked: false,
        });
      }
    });
    setActivityData({
      id: cellId,
      question: activityTitle,
      options: options,
      richText: richText,
      checkboxes: checkboxes,
    });
    localStorage.setItem(
      "activityData",
      JSON.stringify({
        question: activityTitle,
        options: options,
        richText: richText,
        checkboxes: checkboxes,
      })
    );
    setGraphState(false);
    setInteractions(interactionsValues);
    setCellId();
    setSelectedButtons([]);
    setActivityTitle("");
  };

  const activityTitleChange = (e) => {
    setActivityTitle(e.target.value);
  };

  const handleDuplicate = () => {
    setCellId();

    const res = selectedButtons.filter((item) => item.btnId === "textField");
    const commentBox = selectedButtons.find(
      (item) => item.btnId === "commenBox"
    );
    if (commentBox) {
      richText = values[commentBox.id];
    }

    const checkBox = selectedButtons.filter(
      (item) => item.btnId === "checkBox"
    );

    res.forEach((item, index) => {
      if (Object.keys(values).find((elm) => elm === item.id)) {
        options.push({
          id: item.id,
          text: values[item.id],
        });
      }
    });

    checkBox.forEach((item, index) => {
      if (Object.keys(values).find((elm) => elm === item.id)) {
        checkboxes.push({
          label: values[item.id],
          id: item.id,
          checked: false,
        });
      }
    });
    setActivityData({
      question: activityTitle,
      options: options,
      richText: richText,
      checkboxes: checkboxes,
    });
    localStorage.setItem(
      "activityData",
      JSON.stringify({
        question: activityTitle,
        options: options,
        richText: richText,
        checkboxes: checkboxes,
      })
    );
    setGraphState(false);
    setInteractions(interactionsValues);
    setSelectedButtons([]);
    setActivityTitle("");
  };

  const handleInteractionDelete = (id) => {
    setSelectedButtons((prevButtons) => {
      const buttonToDelete = prevButtons.find((item) => item.id === id);
      if (buttonToDelete) {
        return prevButtons.filter((item) => item.id !== id);
      }
      return prevButtons;
    });
  };
  const handlePoperClickOptions = (event) => {
    setAnchorElOptions(event.currentTarget);
  };
  const handleCloseOptions = () => {
    setAnchorElOptions(null);
  };
  const openOptions = Boolean(anchorElOptions);
  const idOptions = openOptions ? "simple-popover" : undefined;

  const handlePoperConnectOptions = (event, id) => {
    setSelectedIndex(id);
    setAnchorElConnectOptions(event.currentTarget);
  };
  const handleCloseConnectOptions = () => {
    setAnchorElConnectOptions(null);
  };
  const openConnectOptions = Boolean(anchorElConnectOptions);
  const idConnectOptions = openConnectOptions ? "simple-popover" : undefined;

  useEffect(() => {
    if (localStorage.getItem("cell")) {
    }
  }, [refetch]);

  const handleListChange = (e) => {
    setListItems(e.target.value);
  };

  const handleAddList = () => {
    if (listItems) {
      setallListItems([
        ...allListItems,
        {
          id: uuidv4(),
          value: listItems,
        },
      ]);
    }
    setListItems("");
  };
  return (
    <Box
      width={"80%"}
      height={"60vh"}
      sx={{
        backgroundColor: "grey.200",
        overflow: "auto",
      }}
      padding={2}
      display={"flex"}
      flexDirection={"column"}
      gap={2}
      component={"form"}
      onSubmit={handleSubmit}
    >
      <Box display={"flex"} justifyContent={"space-between"}>
        <TextField
          size="small"
          sx={{
            "& fieldset": { border: "none", padding: 0 },
          }}
          fullWidth
          name="lable"
          placeholder="Activity 1"
          value={activityTitle}
          onChange={activityTitleChange}
        />

        <Button
          aria-describedby={idOptions}
          variant="contained"
          onClick={handlePoperClickOptions}
        >
          <MoreVertIcon />
        </Button>

        <Popover
          elevation={1}
          id={idOptions}
          open={openOptions}
          anchorEl={anchorElOptions}
          onClose={handleCloseOptions}
          sx={{
            left: 70,
          }}
        >
          <Box
            padding={2}
            display={"flex"}
            flexDirection={"column"}
            gap={2}
            position={"relative"}
          >
            <Box display={"flex"} flexDirection={"column"} gap={2}>
              {interactionsButton.map((item) => (
                <button onClick={handleButtonClick} data-id={item.id}>
                  {item.name}
                </button>
              ))}
            </Box>
          </Box>
        </Popover>
      </Box>

      <ReactSortable list={selectedButtons} setList={setSelectedButtons}>
        {selectedButtons &&
          selectedButtons.map((item, index) => {
            const { id, btnId } = item;
            return (
              <Box key={id}>
                {interactions[btnId] ? (
                  btnId === "commenBox" ? (
                    <Box margin={2} position={"relative"}>
                      <TextField
                        id={id}
                        label="Comment Box"
                        multiline
                        name="commentBox"
                        value={values[id]}
                        fullWidth
                        rows={4}
                        variant="filled"
                        onChange={(e) => handleInputChange(e, id)}
                      />
                      <Button
                        sx={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                        }}
                        onClick={() => handleInteractionDelete(id)}
                      >
                        x
                      </Button>
                    </Box>
                  ) : btnId === "textField" ? (
                    <Box
                      gap={2}
                      display="flex"
                      alignItems="flex-end"
                      margin={2}
                      position={"relative"}
                    >
                      <TextField
                        fullWidth
                        name="textField"
                        id={id}
                        value={values[id]}
                        label="continuation option"
                        variant="standard"
                        onChange={(e) => handleInputChange(e, id)}
                      />
                      <Button
                        sx={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                        }}
                        onClick={() => handleInteractionDelete(id)}
                      >
                        x
                      </Button>
                    </Box>
                  ) : btnId === "checkBox" ? (
                    <Box margin={2} position={"relative"}>
                      <Box alignItems="center" display={"flex"}>
                        <Checkbox name="checkBox" />
                        <TextField
                          label="check list item"
                          variant="standard"
                          id={id}
                          name="lable"
                          value={values[id]}
                          onChange={(e) => handleInputChange(e, id)}
                        />
                      </Box>
                      <Button
                        sx={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                        }}
                        onClick={() => handleInteractionDelete(id)}
                      >
                        x
                      </Button>
                    </Box>
                  ) : btnId === "listComponent" ? (
                    <Box margin={2} position={"relative"}>
                      <ReactSortable
                        list={allListItems}
                        setList={setallListItems}
                      >
                        {allListItems?.length !== 0 &&
                          allListItems.map((item) => (
                            <Typography>{item.value}</Typography>
                          ))}
                      </ReactSortable>
                      <Box
                        width={"80%"}
                        alignItems="center"
                        justifyContent={"space-between"}
                        display={"flex"}
                      >
                        <TextField
                          label="Add List Items"
                          variant="standard"
                          name="lable"
                          value={listItems}
                          onChange={(e) => handleListChange(e, id)}
                        />
                        <Button onClick={handleAddList}>Add</Button>
                      </Box>
                      <Button
                        sx={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                        }}
                        onClick={() => handleInteractionDelete(id)}
                      >
                        x
                      </Button>
                    </Box>
                  ) : null
                ) : null}
              </Box>
            );
          })}
      </ReactSortable>
      <Divider />
      <Box display={"flex"}>
        <Typography component={"button"} type="submit">
          {cellId ? "Update Activity" : "Add Activity"}
        </Typography>
        {cellId && (
          <Typography component={"button"} onClick={handleDuplicate}>
            Duplicate Activity
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default CardActivity;

import { Box, Stack } from "@mui/material";
import React from "react";
import SettingsSuggestOutlinedIcon from "@mui/icons-material/SettingsSuggestOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

const SettingBox = ({ openSettings, duplicate, deleteInteraction, id }) => {
  return (
    <Stack
      sx={{
        backgroundColor: "white",
        position: "absolute",
        top: 0,
        right: -30,
        zIndex: 1,
        boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.2)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "30px",
          height: "30px",
        }}
        onClick={openSettings}
      >
        <SettingsSuggestOutlinedIcon
          sx={{ width: "13.5px", height: "13.5px" }}
        />
      </Box>
      {(id && id === "progressIndicator") ||
      id === "continuationOption" ||
      id === "timer" ? null : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "30px",
            height: "30px",
          }}
          onClick={duplicate}
        >
          <ContentCopyOutlinedIcon sx={{ width: "11.5px", height: "11.5px" }} />
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "30px",
          height: "30px",
        }}
        onClick={deleteInteraction}
      >
        <DeleteOutlineOutlinedIcon sx={{ width: "13.5px", height: "13.5px" }} />
      </Box>
    </Stack>
  );
};

export default SettingBox;

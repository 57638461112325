import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { Box, TablePagination } from "@mui/material";
import { useDispatch } from "react-redux";
import { getActivityEngagementsByCollaborator } from "../../Redux/slice/collaborators";
import { useTranslation } from "react-i18next";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const userActivity = [
  {
    id: 0,
    title: "Activity 1",
  },
  {
    id: 1,
    title: "Activity 2",
  },
];

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function CustomizedAccordions({ collaborators, state }) {
  const { t } = useTranslation();
  const [expanded, setExpanded] = React.useState(null);
  const [egngagements, setEgngagements] = React.useState();
  const [rowsPerPage, setRowsPerPage] = React.useState(3);
  const [page, setPage] = React.useState(0);
  const dispatch = useDispatch();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };
  const handleChange = (panel) => async (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
    if (newExpanded) {
      try {
        const res = await dispatch(
          getActivityEngagementsByCollaborator({
            activityId: panel,
            collaboratorId: state._id,
          })
        );
        if (res.payload) {
          setEgngagements(res.payload.data);
        }
      } catch (error) {}
    }
  };

  return (
    <Box>
      <Box display="flex" flexDirection={"column"} gap={2}>
        {collaborators
          ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((activityObj) => (
            <Accordion
              key={activityObj._id}
              expanded={expanded === activityObj?._id}
              onChange={handleChange(activityObj?._id)}
            >
              <AccordionSummary>
                <Typography>{activityObj?.name}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  sx={{
                    height: "300px",
                    overflow: "auto",
                  }}
                >
                  {egngagements ? (
                    egngagements?.map((item, index) => (
                      <Box
                        width={"100%"}
                        overflowX={"hidden"}
                        display={"flex"}
                        flexDirection={"column"}
                        key={index}
                      >
                        <Box py={2} display="flex" gap={1} alignItems="center">
                          <Typography>
                            {item?.date?.month}/{item?.date?.day}/
                            {item?.date.year}
                          </Typography>
                          <Box
                            sx={{
                              width: "100%",
                              height: "1px",
                              backgroundColor: "black",
                            }}
                          ></Box>
                        </Box>
                        <Box display="flex" flexDirection="column" gap={1}>
                          {item?.engagements?.map((item, index) => (
                            <Box
                              width={"100%"}
                              display="flex"
                              gap={4}
                              alignItems="center"
                              key={item._id}
                            >
                              <Typography>
                                {new Date(item.updatedAt).toLocaleTimeString()}
                              </Typography>
                              <Box display="flex" alignItems={"center"}>
                                <Typography>
                                  Engagements {index + 1}--
                                </Typography>
                                <Typography>{item?.message}--</Typography>
                                <Typography>
                                  {item?.selectedOptionId}--
                                </Typography>
                                <Typography>data:{item?.userData}--</Typography>
                              </Box>
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    ))
                  ) : (
                    <Typography>Loading...........</Typography>
                  )}
                </Box>
              </AccordionDetails>
            </Accordion>
          ))}
      </Box>
      <TablePagination
        rowsPerPageOptions={[3, 10, 25]}
        component="div"
        count={collaborators && collaborators.length}
        rowsPerPage={rowsPerPage}
        page={page}
        labelRowsPerPage={t("rows_per_page")}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        dir="ltr"
      />
    </Box>
  );
}

import { callPrivateAPi } from "./http-common";

const getAllCollaborators = () => {
  return callPrivateAPi.get("/api/getAllColaborators");
};

const getCollaboratorActivitiesById = (id) => {
  return callPrivateAPi.get(`/api/getCollaboratorActivity/${id}`);
};

const getCollaboratorsEngagementById = (id) => {
  return callPrivateAPi.get(`api/getCollaboratorEngagement/${id}`);
};

const getActivityEngagementsByCollaborator = (data) => {
  return callPrivateAPi.post("/api/getActivityEngagementsByCollaborator", data);
};

const CollaboratorsServices = {
  getAllCollaborators,
  getCollaboratorsEngagementById,
  getCollaboratorActivitiesById,
  getActivityEngagementsByCollaborator,
};

export default CollaboratorsServices;

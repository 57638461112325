import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUsersData } from "../Redux/slice/user";

const useAuth = () => {
  const dispatch = useDispatch();
  const user = useSelector((s) => s.user?.data?.data);
  const auth = useSelector((s) => s.auth?.data?.data);
  const token = localStorage.getItem("access_token");
  useEffect(() => {
    if (token) {
      dispatch(getUsersData());
    }
  }, [auth, dispatch, token]);

  return { auth, user };
};

export default useAuth;

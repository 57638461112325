import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, TablePagination } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

export default function CustomTable({ tableHead, tableBody }) {
  const { t } = useTranslation();
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);

  const navigate = useNavigate();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };
  return (
    <Box display={"flex"} flexDirection={"column"}>
      <TableContainer
        sx={{
          height: "56.5vh",
        }}
        component={Paper}
        dir={i18next.dir()}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {tableHead.map((item) => (
                <TableCell key={item.headTitle}>{item.headTitle}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableBody &&
              tableBody
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item) => (
                  <TableRow
                    key={item._id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {item.fullName}
                    </TableCell>
                    <TableCell>{item.email}</TableCell>
                    <TableCell>{item.lastActive}</TableCell>
                    <TableCell>{item.role}</TableCell>
                    {item.role !== "Owner" ? (
                      <TableCell
                        sx={{
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          navigate("/home/collaborator", { state: item })
                        }
                      >
                        view more
                      </TableCell>
                    ) : null}
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={tableBody && tableBody.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={t("rows_per_page")}
        dir="ltr"
      />
    </Box>
  );
}

export const statuses = [
  {
    id: 1,
    name: "Completed",
  },
  {
    id: 2,
    name: "Archived",
  },
  {
    id: 3,
    name: "Active",
  },
  {
    id: 4,
    name: "Blocked",
  },
];

export const accessed = [
  {
    id: 1,
    name: "Today",
    date: new Date(),
  },
  {
    id: 2,
    name: "Yesterday",
    date: new Date(new Date().setDate(new Date().getDate() - 1)),
  },
  {
    id: 3,
    name: "Last 7 days",
    date: new Date(new Date().setDate(new Date().getDate() - 7)),
  },
  {
    id: 4,
    name: "Last Month",
    date: new Date(new Date().setMonth(new Date().getMonth() - 1)),
  },
];

import {
  Box,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const customTextFiled = {
  "&:-webkit-autofill": {
    "-webkit-box-shadow": "0 0 0 100px #ffff inset",
    "-webkit-text-fill-color": "#000",
  },
  "& label.Mui-focused": {
    color: "black",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#0099FF",
    borderColor: "#0099FF",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      backgroundColor: "transparent",
      borderRadius: "4px",
      borderColor: "#A3A3A3",
    },
    "&:hover fieldset": {
      borderColor: "#0099FF",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#0099FF",
      backgroundColor: "transparent",
    },
  },
};

const customTextFiled2 = {
  "&:hover .MuiInputAdornment-root .MuiSvgIcon-root": {
    color: "#0099FF",
  },
  "&:hover .MuiInputAdornment-root .fieldset": {
    borderColor: "#0099FF",
  },
  "&.Mui-focused .MuiInputAdornment-root .MuiSvgIcon-root": {
    color: "#0099FF",
  },
};
const CustomInputs = ({
  size = "small",
  placeholder,
  inputLabel,
  value,
  labelColor,
  onChange,
  type = "text",
  name,
  error,
  ...other
}) => {
  return (
    <Box>
      <InputLabel
        sx={{
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "24px",
          color: labelColor,
          fontFamily: "Roboto",
        }}
        htmlFor="component-simple"
      >
        {inputLabel}
      </InputLabel>
      <TextField
        sx={customTextFiled}
        fullWidth
        variant="outlined"
        name={name}
        type={type}
        placeholder={placeholder}
        size={size}
        value={value}
        onChange={onChange}
        {...other}
        {...(error && { error: true })}
      />
      {error && (
        <Stack
          pt={1}
          display={"flex"}
          alignItems={"center"}
          direction="row"
          color={"#FC5555"}
          spacing={"4px"}
        >
          <ErrorRoundedIcon
            sx={{ width: "16px", height: "16px", lineHeight: "20px" }}
          />
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: 400,
              fontFamily: "Roboto",
            }}
          >
            {error}
          </Typography>
        </Stack>
      )}
    </Box>
  );
};

export default CustomInputs;

export const PasswordField = ({
  size = "small",
  placeholder,
  inputLabel,
  value,
  labelColor,
  onChange,
  type = "text",
  name,
  error,
  ...other
}) => {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <Box>
      <InputLabel
        sx={{
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "24px",
          color: labelColor,
          fontFamily: "Roboto",
        }}
        htmlFor="component-simple"
      >
        {inputLabel}
      </InputLabel>
      <OutlinedInput
        id="outlined-adornment-password"
        sx={customTextFiled2}
        type={showPassword ? "text" : "password"}
        fullWidth
        name={name}
        placeholder={placeholder}
        size={size}
        value={value}
        onChange={onChange}
        {...other}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />
      {error && (
        <Stack
          pt={"12px"}
          display={"flex"}
          alignItems={"center"}
          direction="row"
          color={"#FC5555"}
          spacing={"4px"}
        >
          <ErrorRoundedIcon
            sx={{ width: "16px", height: "16px", lineHeight: "20px" }}
          />
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: 400,
              fontFamily: "Roboto",
            }}
          >
            {error}
          </Typography>
        </Stack>
      )}
    </Box>
  );
};

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import PrescriptionServices from "../api/PrescriptionServices";

export const initialState = {
  data: [],
  presName: [],
  loading: "idle",
  error: null,
  message: null,
};

export const createNewPrescription = createAsyncThunk(
  "prescriptions/createNewPrescription",
  async (data, { rejectWithValue }) => {
    try {
      const res = await PrescriptionServices.addPresCription(data);
      if (res.data) {
        toast.success(res.data.message);
      }
      return res.data;
    } catch (error) {
      toast.error(error.response.message);
      return rejectWithValue(error.response.message);
    }
  }
);

export const getUserPrescriptions = createAsyncThunk(
  "prescriptions/getUserPrescriptions",
  async () => {
    try {
      const res = await PrescriptionServices.getUserPrescriptions();
      if (res.data) {
      }
      return res.data;
    } catch (error) {
      toast.error(error.response.message);
      return error.response.message;
    }
  }
);

export const renamePrescriptions = createAsyncThunk(
  "prescriptions/renamePrescriptions",
  async (arg) => {
    try {
      const { id, name } = arg;
      const res = await PrescriptionServices.renameUserPrescription(id, {
        name: name,
      });
      if (res.data) {
        toast.success(res.data.message);
      }
      return res.data;
    } catch (error) {
      toast.error(error.response.message);
      return error.response.message;
    }
  }
);

export const deletePrescriptions = createAsyncThunk(
  "prescriptions/deletePrescriptions",
  async (id) => {
    try {
      const res = await PrescriptionServices.deleteUserPrescription(id);
      if (res.data) {
        toast.success(res.data.message);
      }
      return res.data;
    } catch (error) {
      toast.error(error.response.message);
      return error.response.message;
    }
  }
);

export const getPrescriptionsById = createAsyncThunk(
  "prescriptions/getPrescriptionsById",
  async (id) => {
    try {
      const res = await PrescriptionServices.getPrescriptionById(id);
      if (res.data) {
        toast.success(res.data.message);
      }
      return res.data;
    } catch (error) {
      toast.error(error.response.message);
      return error.response.message;
    }
  }
);
export const getEngagementedActivity = createAsyncThunk(
  "prescriptions/getEngagementedActivity",
  async (id) => {
    try {
      const res = await PrescriptionServices.GetEngagementedActivityService(id);
      return res.data;
    } catch (error) {
      toast.error(error.response.message);
      return error.response.message;
    }
  }
);

export const editPrescriptions = createAsyncThunk(
  "prescriptions/editPrescriptions",
  async (data) => {
    try {
      const { id, diagramJSON, activityName } = data;
      const res = await PrescriptionServices.editUserPrescription(id, {
        diagramJSON: diagramJSON,
        activityName: activityName,
      });
      if (res.data) {
        toast.success(res.data.messages);
      }
      return res.data;
    } catch (error) {
      toast.error(error.response.message);
      return error.response.message;
    }
  }
);

export const sharePrescriptions = createAsyncThunk(
  "prescriptions/sharePrescriptions",
  async (id) => {
    try {
      const res = await PrescriptionServices.sharePrescriptions(id);
      if (res.data) {
        toast.success(res.data.message);
      }
      return res.data;
    } catch (error) {
      toast.error(error.response.message);
      return error.response.message;
    }
  }
);

export const copyPublicPrescription = createAsyncThunk(
  "prescriptions/copyPublicPrescription",
  async (id) => {
    try {
      const res = await PrescriptionServices.copyPublicPrescription(id);
      if (res.data) {
        toast.success(res.data.message);
      }
      return res.data;
    } catch (error) {
      toast.error(error.response.message);
      return error.response.message;
    }
  }
);

export const sharePrescriptionById = createAsyncThunk(
  "prescriptions/sharePrescriptionById",
  async (id) => {
    try {
      const res = await PrescriptionServices.sharePrescription(id);
      if (res.data) {
        toast.success(res.data.message);
      }
      return res.data;
    } catch (error) {
      toast.error(error.response.message);
      return error.response.message;
    }
  }
);

export const getPrescriptionHistoryById = createAsyncThunk(
  "prescriptions/getPrescriptionHistoryById",
  async (id) => {
    try {
      const res = await PrescriptionServices.getPrescriptionHistoryById(id);
      if (res.data) {
        toast.success(res.data.message);
      }
      return res.data;
    } catch (error) {
      toast.error(error.response.message);
      return error.response.message;
    }
  }
);

export const getSharePrescriptionById = createAsyncThunk(
  "prescriptions/getSharePrescriptionById",
  async (id) => {
    try {
      const res = await PrescriptionServices.getSharePrescription(id);
      if (res.data) {
        toast.success(res.data.message);
      }
      return res.data;
    } catch (error) {
      toast.error(error.response.message);
      return error.response.message;
    }
  }
);

export const archivePrescription = createAsyncThunk(
  "prescriptions/archivePrescription",
  async (id) => {
    try {
      const res = await PrescriptionServices.archivePrescription(id);
      if (res.data) {
        toast.success(res.data.message);
      }
      return res.data;
    } catch (error) {
      toast.error(error.response.message);
      return error.response.message;
    }
  }
);

export const searchUserPrescription = createAsyncThunk(
  "prescriptions/searchUserPrescription",
  async (data) => {
    try {
      const res = await PrescriptionServices.searchUserPrescription(data);
      if (res.data) {
        toast.success(res.data.message);
      }
      return res.data;
    } catch (error) {
      toast.error(error.response.message);
      return error.response.message;
    }
  }
);

export const userPrescriptionName = createAsyncThunk(
  "prescriptions/userPrescriptionName",
  async () => {
    try {
      const res = await PrescriptionServices.getUserPrescriptionsName();
      if (res.data) {
        toast.success(res.data.message);
      }
      return res.data;
    } catch (error) {
      toast.error(error.response.message);
      return error.response.message;
    }
  }
);

export const prescriptionSlice = createSlice({
  name: "prescriptions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createNewPrescription.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(createNewPrescription.fulfilled, (state, action) => {
      state.loading = "idle";
      state.message = action.payload.message;
    });
    builder.addCase(createNewPrescription.rejected, (state, action) => {
      state.loading = "rejected";
      state.message = action.payload.message;
    });

    builder.addCase(getUserPrescriptions.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(getUserPrescriptions.fulfilled, (state, action) => {
      state.loading = "idle";
      state.data = action.payload;
      state.message = action.payload.message;
    });
    builder.addCase(getUserPrescriptions.rejected, (state, action) => {
      state.loading = "rejected";
      state.message = action.payload.message;
    });

    builder.addCase(renamePrescriptions.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(renamePrescriptions.fulfilled, (state, action) => {
      state.loading = "idle";
      state.message = action.payload.message;
    });
    builder.addCase(renamePrescriptions.rejected, (state, action) => {
      state.loading = "rejected";
      state.message = action.payload.message;
    });

    builder.addCase(deletePrescriptions.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(deletePrescriptions.fulfilled, (state, action) => {
      state.loading = "idle";
      state.message = action.payload.message;
    });
    builder.addCase(deletePrescriptions.rejected, (state, action) => {
      state.loading = "rejected";
      state.message = action.payload.message;
    });

    builder.addCase(editPrescriptions.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(editPrescriptions.fulfilled, (state, action) => {
      state.loading = "idle";
      state.message = action.payload.message;
    });
    builder.addCase(editPrescriptions.rejected, (state, action) => {
      state.loading = "rejected";
      state.message = action.payload.message;
    });

    builder.addCase(getPrescriptionsById.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(getPrescriptionsById.fulfilled, (state, action) => {
      state.loading = "idle";
      state.message = action.payload.message;
    });
    builder.addCase(getPrescriptionsById.rejected, (state, action) => {
      state.loading = "rejected";
      state.message = action.payload.message;
    });
    builder.addCase(getEngagementedActivity.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(getEngagementedActivity.fulfilled, (state, action) => {
      state.loading = "idle";
      state.data = action.payload;
    });
    builder.addCase(getEngagementedActivity.rejected, (state, action) => {
      state.loading = "rejected";
      state.message = action.payload.message;
    });

    builder.addCase(sharePrescriptions.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(sharePrescriptions.fulfilled, (state, action) => {
      state.loading = "idle";
      state.message = action.payload.message;
    });
    builder.addCase(sharePrescriptions.rejected, (state, action) => {
      state.loading = "rejected";
      state.message = action.payload.message;
    });

    builder.addCase(copyPublicPrescription.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(copyPublicPrescription.fulfilled, (state, action) => {
      state.loading = "idle";
      // state.message = action.payload.message;
    });
    builder.addCase(copyPublicPrescription.rejected, (state, action) => {
      state.loading = "rejected";
      // state.message = action.payload.message;
    });

    builder.addCase(sharePrescriptionById.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(sharePrescriptionById.fulfilled, (state, action) => {
      state.loading = "idle";
      state.message = action.payload.message;
    });
    builder.addCase(sharePrescriptionById.rejected, (state, action) => {
      state.loading = "rejected";
      state.message = action.payload.message;
    });

    builder.addCase(getPrescriptionHistoryById.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(getPrescriptionHistoryById.fulfilled, (state, action) => {
      state.loading = "idle";
      state.message = action.payload.message;
    });
    builder.addCase(getPrescriptionHistoryById.rejected, (state, action) => {
      state.loading = "rejected";
      state.message = action.payload.message;
    });

    builder.addCase(getSharePrescriptionById.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(getSharePrescriptionById.fulfilled, (state, action) => {
      state.loading = "idle";
      state.message = action.payload.message;
    });
    builder.addCase(getSharePrescriptionById.rejected, (state, action) => {
      state.loading = "rejected";
      state.message = action.payload.message;
    });

    builder.addCase(archivePrescription.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(archivePrescription.fulfilled, (state, action) => {
      state.loading = "idle";
      state.message = action.payload.message;
    });
    builder.addCase(archivePrescription.rejected, (state, action) => {
      state.loading = "rejected";
      state.message = action.payload.message;
    });

    builder.addCase(searchUserPrescription.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(searchUserPrescription.fulfilled, (state, action) => {
      state.loading = "idle";
      state.message = action.payload.message;
      state.data = action.payload;
    });
    builder.addCase(searchUserPrescription.rejected, (state, action) => {
      state.loading = "rejected";
      state.message = action.payload.message;
    });
    builder.addCase(userPrescriptionName.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(userPrescriptionName.fulfilled, (state, action) => {
      state.loading = "idle";
      state.message = action.payload.message;
      state.presName = action.payload;
    });
    builder.addCase(userPrescriptionName.rejected, (state, action) => {
      state.loading = "rejected";
      state.message = action.payload.message;
    });
  },
});

export default prescriptionSlice.reducer;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import ChatServices from "../api/ChatServices";

export const initialState = {
  data: [],
  loading: "idle",
  error: null,
  message: null,
};

export const getUserConversations = createAsyncThunk(
  "chat/getUserConversations",
  async () => {
    try {
      const res = await ChatServices.getUserConversations();
      if (res.data) {
        toast.success(res.data.message);
      }
      return res.data;
    } catch (error) {
      toast.error(error.response.message);
      return error.response.message;
    }
  }
);

export const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserConversations.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(getUserConversations.fulfilled, (state, action) => {
      state.loading = "idle";
      state.data = action.payload;
      state.message = action.payload.message;
    });
    builder.addCase(getUserConversations.rejected, (state, action) => {
      state.loading = "rejected";
      state.message = action.payload.message;
    });
  },
});

export default chatSlice.reducer;

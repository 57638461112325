import { useEffectOnce } from "../../hooks/useEffectOnce";
import { useNavigate } from "react-router-dom";
import useRetryFetch from "../../hooks/useRetryFetch";
import RenderDialog from "../Dialog/render";
import $ from "jquery";
import _ from "lodash";
import "./Preview.css";

import { config } from "../../Utils/Environment";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { createNewProcess, getProcessById } from "../../Redux/slice/process";
import {
  getPrescriptionsById,
  getSharePrescriptionById,
} from "../../Redux/slice/prescriptions";
import RunProcessServices from "../../Redux/api/RunProcessServices";
import ProcessServices from "../../Redux/api/ProcessServices";
import { createLogEntry, updateProcess } from "../../Redux/slice/runProcess";

const Preview = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isWelcomeLocalStorage = () => {
    if (localStorage.getItem("isWelcome") === "true") {
      return true;
    }
    return false;
  };

  const [isWelcome] = useState(isWelcomeLocalStorage());
  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 800px)").matches
  );

  const createDialogV0 = (graphObject) => {
    var dialog = {
      root: undefined,
      nodes: [],
      links: [],
    };

    _.each(graphObject.cells, function (cell) {
      var o = {
        id: cell.id,
        type: cell.type,
        data: cell.data,
      };

      switch (cell.type) {
        case "qad.Question":
          o.question = cell?.data?.activityTitle?.richTextBody;
          o.options = cell.options;
          dialog.nodes.push(o);
          if (!dialog.root) dialog.root = cell.id;
          break;
        default: // qad.Link
          o.source = cell.source;
          o.target = cell.target;
          dialog.links.push(o);
          break;
      }
    });

    return dialog;
  };

  const createDialogV1 = (graphObject) => {
    var dialog = {
      root: undefined,
      nodes: [],
      links: [],
    };

    _.each(graphObject.cells, function (cell) {
      var o = {
        id: cell.id,
        type: cell.type === "Activity" ? "qad.Question" : "Link",
        data: cell.data,
      };

      switch (cell.type) {
        case "Activity":
          o.question = cell?.data?.activityTitle?.richTextBody;
          o.options = cell.options;
          dialog.nodes.push(o);
          if (!dialog.root) {
            // check if Activity cell has incoming link
            let found = false;
            for (let checkCell of graphObject.cells) {
              if (checkCell.type === "Link") {
                if (checkCell.target.activityId === cell.id) found = true;
              }
            }
            if (!found) dialog.root = cell.id;
          }
          break;
        default: // qad.Link
          o.source = {
            id: cell.source.activityId,
            port: cell.source.portId,
          };
          o.target = {
            id: cell.target.activityId,
            port: cell.target.portId,
          };
          dialog.links.push(o);
          break;
      }
    });

    if (!dialog.root) console.log("WARNING: No start Activity found");

    return dialog;
  };

  const getQueryParams = () => {
    let result = {};

    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("id");
    const activityId = urlParams.get("activityId");
    result.id = id;
    result.activity = activityId;

    return result;
  };

  const renderRichTextEditor = (dialog) => {
    if (document.querySelector(".rich-text-editor")) {
      // adding the rich text editor
      const rte_config = {
        skin: "rounded-corner",
        showFloatTextToolBar: false,
        showFloatImageToolBbar: false,
        showFloatParagraph: false,
        showFloatTableToolBar: false,
        maxHTMLLength: 20000000,
        toolbar: "clarynexttoolbar",
        toolbar_clarynexttoolbar:
          "{bold,italic,underline,forecolor,backcolor}|{justifyleft,justifycenter,justifyright,justifyfull}|{insertorderedlist,insertunorderedlist,indent,outdent,insertblockquote,insertemoji}" +
          " #{paragraphs:toggle,fontname:toggle,fontsize:toggle,inlinestyle,lineheight}" +
          " / {removeformat,cut,copy,paste,delete,find}|{insertlink,insertchars,inserttable,insertimagebyurl,insertdocument,inserttemplate,insertcode}|{preview,selectall}" +
          "#{toggleborder,fullscreenenter,fullscreenexit,undo,redo,togglemore}",
      };
      const editor = new window.RichTextEditor(".rich-text-editor", rte_config);
      $("rte-line-break").remove();

      editor.attachEvent(
        "change",
        function () {
          let newComment = editor.getHTMLCode();
          dialog.enterComment(newComment);
        },
        false
      );
    }
  };

  useEffectOnce(() => {
    const handler = (e) => setIsMobile(e.matches);
    window.matchMedia("(max-width: 800px)").addEventListener("change", handler);

    const showPreview = async () => {
      localStorage.removeItem("isWelcome");

      let queryParams = getQueryParams();

      let dialog;
      let processId;
      let currentActivity;
      let processID;

      try {
        const res = await dispatch(
          createNewProcess({ prescriptionId: queryParams.activity })
        );
        processID = res.payload.data.processId;
        currentActivity = res.payload.data.nextActivity;
      } catch (error) {}

      if (queryParams.id) {
        // retrieve prescription from deeplink API
        const response = await dispatch(
          getSharePrescriptionById(queryParams.id)
        );

        // create process
        const res = await dispatch(
          createNewProcess({ prescriptionId: queryParams.activity })
        );

        processId = res.payload.data.processId;
        currentActivity = res.payload.data.nextActivity;

        let diagramObject = JSON.parse(res.payload.data.diagramJSON);
        dialog = createDialogV1(diagramObject);

        localStorage.setItem("runProcessID", processId);
        localStorage.setItem("prescriptionHistoryID", response.id);
      } else if (localStorage.getItem("draftprescription")) {
        // retrieve prescription directly

        let diagramObject = JSON.parse(
          localStorage.getItem("draftprescription")
        );

        if (!diagramObject.version) {
          dialog = createDialogV0(diagramObject);
        } else {
          dialog = createDialogV1(diagramObject);
        }
      } else if (localStorage.getItem("prescriptionID")) {
        // retrieve prescription directly

        // is it a running process (runMode) or a preview?
        if (localStorage.getItem("processID")) {
          // runMode

          processId = localStorage.getItem("processID");
          currentActivity = localStorage.getItem("nextActivity");

          localStorage.removeItem("processID");
          localStorage.removeItem("nextActivity");

          const res = await dispatch(getProcessById(processId));
          let diagramObject = JSON.parse(
            res.payload.data.fiPrescriptionHistory.diagramJSON
          );
          dialog = createDialogV1(diagramObject);
        } else {
          // preview
          const prescriptionId = localStorage.getItem("prescriptionID");
          const res = await dispatch(getPrescriptionsById(prescriptionId));

          let diagramObject = JSON.parse(res?.payload.data.diagramJSON);

          if (!diagramObject.version) {
            dialog = createDialogV0(diagramObject);
          } else {
            dialog = createDialogV1(diagramObject);
          }
        }
      } else {
        alert("No dialog found");
      }

      const $background = $("<div/>");

      if (!isMobile && !isWelcome) $background.addClass("background");

      $("#preview")
        .empty()
        .append([
          $background,
          RenderDialog.render(dialog, null, $, currentActivity),
        ])
        .show();

      renderRichTextEditor(RenderDialog);

      RenderDialog.onDone(async (optionId, userData) => {
        // create log entry
        const data = {
          activity: currentActivity,
          selectedOptionId: optionId,
          userData: JSON.stringify(userData),
          message: "User action",
        };
        dispatch(
          createLogEntry({
            processId: processID,
            data: data,
          })
        );

        // update process and set to FINISHED
        if (processId) {
          const data = {
            status: "FINISHED",
            nextActivity: "",
          };
          dispatch(
            updateProcess({
              processId: processID,
              data: data,
            })
          );
        }

        if (window.opener) {
          window.close();
        } else navigate("/home/activities");
      });

      RenderDialog.onPause(() => {
        if (window.opener) {
          window.close();
        } else navigate("/home/activities");
      });

      RenderDialog.onNextActivity(async (optionId, userData, nextActivity) => {
        renderRichTextEditor();

        // create log entry
        const data = {
          activity: currentActivity,
          selectedOptionId: optionId,
          userData: JSON.stringify(userData),
          message: "User action",
        };

        dispatch(
          createLogEntry({
            processId: processID,
            data: data,
          })
        );

        currentActivity = nextActivity;

        // update process
        if (processId) {
          const updateData = {
            status: "FINISHED",
            nextActivity,
          };
          dispatch(
            updateProcess({
              processId: processID,
              data: updateData,
            })
          );
        }
      });
    };
    showPreview();
  }, []);

  return <div id="preview" className="preview"></div>;
};

export default Preview;

import { useEffect, useState, useRef, useContext } from "react";
import { useEffectOnce } from "../../hooks/useEffectOnce";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import useRetryFetch from "../../hooks/useRetryFetch";
import useOutsideClick from "../../hooks/useOutsideClick";
import { useTranslation } from "react-i18next";
import { config } from "../../Utils/Environment";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "./PrescriptionList.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MessageBox from "../MessageBox/MessageBox";
import {
  solid /* regular */,
} from "@fortawesome/fontawesome-svg-core/import.macro"; // <-- import styles to be used
import { WSContext } from "../../App";
import { distributionService } from "../../services/distributionService";
import { useDispatch, useSelector } from "react-redux";
import {
  copyPublicPrescription,
  deletePrescriptions,
  getUserPrescriptions,
  renamePrescriptions,
  sharePrescriptionById,
  sharePrescriptions,
} from "../../Redux/slice/prescriptions";
import { getPublicPrescriptions } from "../../Redux/slice/publicPrescriptions";
import {
  assignUserToProcess,
  continueProcessById,
  createNewProcess,
  getAllUsersByProcess,
  getProcessesExperts,
  getUserProcesses,
  terminateProcessById,
} from "../../Redux/slice/process";

const PrescriptionList = () => {
  const { auth, user } = useAuth();
  const prescription = useSelector((s) => s.prescription?.data?.data);
  const publicPrescription = useSelector(
    (s) => s.publicPrescription?.data?.data
  );
  const userProcess = useSelector((s) => s.process?.userProcess?.data);
  const expertProcess = useSelector((s) => s.process?.expertProcess?.data);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const dropdownRef = useRef(null);

  const { t } = useTranslation();

  const [prescriptions, setPrescriptions] = useState(prescription);
  const [publicPrescriptions, setPublicPrescriptions] = useState([]);
  const [processes, setProcesses] = useState([]);
  const [expertProcesses, setExpertProcesses] = useState([]);
  const [currentPrescription, setCurrentPrescription] = useState("");
  const [currentProcess, setCurrentProcess] = useState("");
  const [allUsers, setAllUsers] = useState([]);

  const [refetch, setRefetch] = useState(false);

  const [userlist, setUserList] = useState([]);

  const [reference, setReference] = useState(null);

  const [showMessageBox, setShowMessageBox] = useState(false);
  const [userDropdownOptions, setUserDropdownOptions] = useState({
    visible: false,
    left: "0",
    top: "0",
    processId: null,
  });
  const [msgBoxTitle, setMsgBoxTitle] = useState("");
  const [msgBoxButtons, setMsgBoxButtons] = useState({});
  const [msgBoxInputs, setMsgBoxInputs] = useState([]);
  const [msgBoxId, setMsgBoxId] = useState("");
  const [msgBoxMoreJSX, setMsgBoxMoreJSX] = useState(null);

  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 800px)").matches
  );

  const retryFetch = useRetryFetch();

  const openMessageBox = (title, id, buttons, inputs, moreJSX) => {
    setMsgBoxTitle(title);
    setMsgBoxId(id);
    setMsgBoxButtons(buttons);
    setMsgBoxInputs(inputs);
    setMsgBoxMoreJSX(moreJSX);
    setShowMessageBox(true);
  };

  const onButton = async (result) => {
    // delete or archive depending on number of versions of prescription
    if (result.btnId === "yes") {
      switch (result.msgBoxId) {
        case "delete":
        case "archive": {
          const res = await dispatch(
            deletePrescriptions(currentPrescription._id)
          );
          if (res) {
            setRefetch(!refetch);
          }

          let response = await retryFetch(
            `${config.API_BASE}/api/prescriptions/${currentPrescription}`,
            {
              method: "DELETE",
              credentials: "include",
            }
          );
          let msg = await response.json();
          openMessageBox(
            t(msg.message),
            "success",
            [{ id: "ok", text: t("ok") }],
            [],
            ""
          );
          setCurrentPrescription("");
          break;
        }
        case "terminate": {
          const res = await dispatch(terminateProcessById(currentProcess));

          openMessageBox(
            t(res.payload.message),
            "success",
            [{ id: "ok", text: t("ok") }],
            [],
            ""
          );
          setCurrentProcess("");
          if (res) {
            setRefetch(!refetch);
          }
          break;
        }
        default:
          break;
      }
    } else if (
      result.btnId === "ok" &&
      result.msgBoxId === "renameprescription"
    ) {
      const res = await dispatch(
        renamePrescriptions({
          id: reference._id,
          name: result.data.newName,
        })
      );
      if (res) {
        setRefetch(!refetch);
      }

      setReference(null);
    }
  };
  const token = localStorage.getItem("access_token");
  const getPrescriptions = async () => {
    const res = await dispatch(getPublicPrescriptions());

    if (user?.email) {
      dispatch(getUserPrescriptions());
      const res = await dispatch(getUserProcesses());

      const resp = await dispatch(getProcessesExperts());
    } else {
    }
  };
  useEffect(() => {
    getPrescriptions();
  }, [user, refetch]);

  useEffect(() => {
    if (userDropdownOptions.visible) {
      if (dropdownRef && dropdownRef.current) {
        dropdownRef.current.style.left = userDropdownOptions.left;
        dropdownRef.current.style.top = userDropdownOptions.top;
      }
    }
  }, [userDropdownOptions]);

  const updateProcesses = (procList) => {
    // update online state for experts and users in processes
    let newProcesses = [...procList];
    for (let process of newProcesses) {
      if (
        userlist.map((user) => user?.email).indexOf(process.fiExpert?.email) !==
        -1
      )
        process.onlineState = userlist.find(
          (user) => user?.email === process.fiExpert?.email
        ).state;
      else process.onlineState = "offline";
    }
    return newProcesses;
  };

  const updateExpertProcesses = (procList) => {
    // update online state for experts and users in processes
    let newProcesses = [...procList];
    for (let process of newProcesses) {
      if (
        userlist
          .map((user) => user?.email)
          .indexOf(process.fiInitiator?.email) !== -1
      )
        process.onlineState = userlist.find(
          (user) => user?.email === process.fiInitiator?.email
        ).state;
      else process.onlineState = "offline";
    }
    return newProcesses;
  };

  useEffect(() => {
    if (!processes.length) {
      getPrescriptions();
    } else {
      setProcesses(updateProcesses(processes));
      if (expertProcesses.length)
        setExpertProcesses(updateExpertProcesses(expertProcesses));
    }
  }, [userlist]);

  useEffectOnce(() => {
    const handler = (e) => setIsMobile(e.matches);
    window.matchMedia("(max-width: 800px)").addEventListener("change", handler);

    localStorage.removeItem("prescriptionID");

    // subscribe to incoming messages
    let messageUnsubscribe = distributionService
      .getMessage()
      .subscribe((msg) => {
        switch (msg.command) {
        }
      });

    let userlistUnsubscribe = distributionService
      .getUserlist()
      .subscribe((users) => {
        setUserList(users);
      });

    return () => {
      messageUnsubscribe.unsubscribe();
      userlistUnsubscribe.unsubscribe();
    };
  }, []);

  const selectPrescription = (idx, startempty) => {
    if (!startempty) {
      localStorage.setItem("prescriptionID", idx._id);
    } else {
      localStorage.removeItem("prescriptionID");
    }
    localStorage.removeItem("draftprescription");
    navigate("/design");
  };

  const sharePrescription = async (idx) => {
    try {
      const res = await dispatch(sharePrescriptionById(idx._id));
      if (res.payload.id) {
        navigator.clipboard.writeText(
          `${window.location.origin}/preview?id=${res.payload.id}`
        );
        openMessageBox(
          t("msg_link_copied_to_clipboard"),
          "linkcopied",
          [{ id: "ok", text: t("ok") }],
          [],
          ""
        );
      } else {
        openMessageBox(
          t("msg_error_clipboard_insecure"),
          "linknotcopied",
          [{ id: "ok", text: t("ok") }],
          [],
          ""
        );
      }
    } catch (error) {}
  };

  const runPrescription = async (idx) => {
    localStorage.setItem("prescriptionID", idx._id);
    const res = await dispatch(createNewProcess({ prescriptionId: idx._id }));
    if (res) {
      const { processId, nextActivity } = res.payload.data;
      localStorage.setItem("processID", res.payload.data.processId);
      localStorage.setItem("nextActivity", res.payload.data.nextActivity);
      window.open("/preview", "_blank");
    }
  };

  const runOtherPrescription = async (idx) => {
    // create a new process and start it
    localStorage.setItem("prescriptionID", idx._id);
    const res = await dispatch(createNewProcess({ prescriptionId: idx._id }));
    if (res) {
      const { processId, nextActivity } = res.payload.data;
      localStorage.setItem("processID", res.payload.data.processId);
      localStorage.setItem("nextActivity", res.payload.data.nextActivity);
      window.open("/preview", "_blank");
    }
  };

  const continueProcess = async (idx) => {
    const res = await dispatch(continueProcessById(idx._id));
    if (res.payload.data.nextActivity) {
      localStorage.setItem("prescriptionID", res.payload.data.fiPrescription);
      localStorage.setItem("processID", idx._id);
      localStorage.setItem("nextActivity", res.payload.data.nextActivity);
      window.open("/preview", "_blank");
    } else {
    }
  };

  const terminateProcess = async (idx) => {
    setCurrentProcess(idx._id);
    openMessageBox(
      t("terminate_process"),
      "terminate",
      [
        { id: "yes", text: t("yes") },
        { id: "no", text: t("no") },
      ],
      [],
      <p>{t("question_terminate")}</p>
    );
  };

  const showUserLog = (idx) => {
    let stateToPass = {
      processId: idx._id,
      presName: idx.prescriptionName,
      presHistoryId: idx.fiPrescriptionHistory,
      expertEmail: idx.fiExpert?.email,
      autochat: false,
    };
    navigate("/userlog", { state: stateToPass });
  };

  const showFullLog = (idx) => {
    let stateToPass = {
      processId: idx._id,
      presName: idx.prescriptionName,
      presHistoryId: idx.fiPrescriptionHistory,
      userEmail: idx.fiInitiator?.email,
      autochat: false,
    };
    navigate("/fulllog", { state: stateToPass });
  };

  const deletePrescription = (idx) => {
    setCurrentPrescription(idx);
    if (idx.versions > 0) {
      openMessageBox(
        t("archive_prescription"),
        "archive",
        [
          { id: "yes", text: t("yes") },
          { id: "no", text: t("no") },
        ],
        [],
        <p>{t("question_archive_prescription")}</p>
      );
    } else {
      openMessageBox(
        t("delete_prescription"),
        "delete",
        [
          { id: "yes", text: t("yes") },
          { id: "no", text: t("no") },
        ],
        [],
        <p>{t("question_delete_prescription")}</p>
      );
    }
  };

  const renamePrescription = (idx) => {
    setReference(idx);
    openMessageBox(
      t("msg_rename_prescription_to"),
      "renameprescription",
      [
        {
          id: "ok",
          text: t("ok"),
        },
        {
          id: "cancel",
          text: t("cancel"),
        },
      ],
      [
        {
          id: "newName",
          text: t("name") + ":",
          defaultValue: idx.name,
        },
      ]
    );
  };

  const showLocale = (ts) => {
    return new Date(ts).toLocaleString();
  };

  const viewPrescription = (idx, isMine) => {
    if (isMine) localStorage.setItem("prescriptionID", idx._id);
    else localStorage.setItem("prescriptionID", idx._id);

    localStorage.removeItem("draftprescription");
    navigate("/readonly");
  };

  const copyPrescription = async (idx) => {
    let presId = idx._id;
    dispatch(copyPublicPrescription(presId));

    openMessageBox(
      t("msg_prescription_copy_successful"),
      "copysuccess",
      [{ id: "ok", text: t("ok") }],
      [],
      ""
    );
  };

  const assignUser = async (e, idx) => {
    const res = await dispatch(getAllUsersByProcess());
    if (res.payload) {
      setAllUsers(res.payload.data);
      setUserDropdownOptions({
        visible: true,
        left: e.clientX + "px",
        top: e.clientY + "px",
        processId: idx._id,
      });
    }
  };

  const selectUser = async (user) => {
    const data = {
      processId: userDropdownOptions.processId,
      assignedUserId: user._id,
    };
    dispatch(assignUserToProcess(data));
    closeUserDropdown();
  };

  const closeUserDropdown = () => {
    setUserDropdownOptions({
      visible: false,
      left: "0",
      top: "0",
      processId: null,
    });
  };

  const copyPrescriptionAsExample = async (idx) => {
    try {
      const res = await dispatch(sharePrescriptions(idx._id));
      if (res.payload.success) {
        setRefetch(!refetch);
      }
      let response = await retryFetch(
        `${config.API_BASE}/api/prescriptions/copy/${prescriptions[idx]._id}`,
        {
          method: "POST",
          credentials: "include",
        }
      );

      if (!response.ok)
        throw new Error(response.status + ": " + response.statusText);

      let newid = await response.json();
      newid = newid.newid;
    } catch (e) {}
  };

  useOutsideClick(dropdownRef, closeUserDropdown);

  return (
    <div>
      {isMobile ? (
        <table className="preslist">
          <thead>
            <tr>
              <th>{t("name")}</th>
              <th>{t("actions")}</th>
            </tr>
          </thead>
          <tbody>
            {token &&
              prescription?.map((prescription, index) => {
                return (
                  <tr key={index}>
                    <td>{prescription.name}</td>
                    <td>
                      <FontAwesomeIcon
                        title={t("run")}
                        icon={solid("play")}
                        onClick={() => runPrescription(index)}
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      ) : (
        <div className="preslist centercontainer">
          <span>
            <FontAwesomeIcon
              title={t("refresh_lists")}
              icon={solid("rotate")}
            />
          </span>
          <Tabs>
            <TabList>
              <Tab disabled={!user?.email}>{t("my_prescriptions")}</Tab>
              <Tab>{t("other_prescriptions")}</Tab>
              <Tab disabled={!user?.email}>{t("my_processes")}</Tab>
              <Tab disabled={!user?.email}>
                {t("processes_for_my_prescriptions")}
              </Tab>
            </TabList>
            <TabPanel>
              <div className="prescriptionbox">
                <ul className="prescriptionlist">
                  {!!user?.email ? (
                    <li onClick={() => selectPrescription(null, true)}>
                      <FontAwesomeIcon icon={solid("plus")} />
                      &nbsp;&nbsp; {t("new_prescription")}
                    </li>
                  ) : (
                    <li className="disabledbutton">
                      <FontAwesomeIcon icon={solid("plus")} />
                      &nbsp;&nbsp; {t("new_prescription")}
                    </li>
                  )}
                </ul>
              </div>
              <table>
                <thead>
                  <tr>
                    <th>{t("name")}</th>
                    <th>{t("versions")}</th>
                    <th>{t("actions")}</th>
                  </tr>
                </thead>
                <tbody>
                  {token &&
                    prescription &&
                    prescription?.map((prescription, index) => {
                      return (
                        <tr key={index}>
                          <td>{prescription.name}</td>
                          <td>{prescription.versions}</td>
                          <td className="equallySpaced">
                            <FontAwesomeIcon
                              title={t("edit")}
                              icon={solid("pen")}
                              onClick={() =>
                                selectPrescription(prescription, false)
                              }
                            />
                            &nbsp;
                            <FontAwesomeIcon
                              title={t("view")}
                              icon={solid("eye")}
                              onClick={() =>
                                viewPrescription(prescription, true)
                              }
                            />
                            &nbsp;
                            <span
                              className={!user?.email ? "disabledicon" : ""}
                            >
                              <FontAwesomeIcon
                                title={t("copy_prescription_as_example")}
                                icon={solid("upload")}
                                onClick={
                                  !user?.email
                                    ? null
                                    : () =>
                                        copyPrescriptionAsExample(prescription)
                                }
                              />
                            </span>
                            &nbsp;
                            <span
                              className={!user?.email ? "disabledicon" : ""}
                            >
                              <FontAwesomeIcon
                                title={t("share")}
                                icon={solid("share-nodes")}
                                onClick={
                                  !user?.email
                                    ? null
                                    : () => sharePrescription(prescription)
                                }
                              />
                            </span>
                            &nbsp;
                            <FontAwesomeIcon
                              title={t("run")}
                              icon={solid("play")}
                              onClick={() => runPrescription(prescription)}
                            />
                            &nbsp;
                            <span
                              className={!user?.email ? "disabledicon" : ""}
                            >
                              <FontAwesomeIcon
                                title={t("rename")}
                                icon={solid("file-signature")}
                                onClick={
                                  !user?.email
                                    ? null
                                    : () => renamePrescription(prescription)
                                }
                              />
                            </span>
                            &nbsp;
                            <span
                              className={!user?.email ? "disabledicon" : ""}
                            >
                              <FontAwesomeIcon
                                title={t("delete_or_archive")}
                                icon={solid("trash")}
                                onClick={
                                  !user?.email
                                    ? null
                                    : () => deletePrescription(prescription)
                                }
                              />
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </TabPanel>
            <TabPanel>
              <table>
                <thead>
                  <tr>
                    <th>{t("name")}</th>
                    <th>{t("actions")}</th>
                  </tr>
                </thead>
                <tbody>
                  {publicPrescription?.map((prescription, index) => {
                    return (
                      <tr key={index}>
                        <td>{prescription.name}</td>
                        <td>
                          <FontAwesomeIcon
                            title={t("view")}
                            icon={solid("eye")}
                            onClick={() =>
                              viewPrescription(prescription, false)
                            }
                          />
                          &nbsp;
                          <FontAwesomeIcon
                            title={t("copy")}
                            icon={solid("copy")}
                            onClick={() => copyPrescription(prescription)}
                          />
                          &nbsp;
                          <FontAwesomeIcon
                            title={t("run")}
                            icon={solid("play")}
                            onClick={() => runOtherPrescription(prescription)}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </TabPanel>
            <TabPanel>
              <table>
                <thead>
                  <tr>
                    <th>{t("prescription_name")}</th>
                    <th>{t("expert")}</th>
                    <th>{t("status")}</th>
                    <th>{t("last_update")}</th>
                    <th>{t("actions")}</th>
                  </tr>
                </thead>
                <tbody>
                  {userProcess?.map((process, index) => {
                    return (
                      <tr key={index}>
                        <td>{process.prescriptionName}</td>
                        <td>
                          {process.fiExpert?.email}&nbsp;
                          {process.fiExpert &&
                          process.fiExpert.email !== user?.email ? (
                            <span
                              className={
                                "smallicon " +
                                (process.onlineState === "offline"
                                  ? "offline"
                                  : process.onlineState === "online"
                                  ? "online"
                                  : "busy")
                              }
                            >
                              <FontAwesomeIcon
                                title={t("onlinestate")}
                                icon={solid("circle")}
                              />
                            </span>
                          ) : (
                            ""
                          )}
                        </td>
                        <td>{t(process.status)}</td>
                        <td>{showLocale(process.lastUpdated)}</td>
                        <td>
                          {process.status === "RUNNING" ? (
                            <>
                              <FontAwesomeIcon
                                title={t("continue")}
                                icon={solid("forward")}
                                onClick={() => continueProcess(process)}
                              />
                              &nbsp;
                              <FontAwesomeIcon
                                title={t("terminate")}
                                icon={solid("circle-xmark")}
                                onClick={() => terminateProcess(process)}
                              />
                              &nbsp;
                            </>
                          ) : (
                            ""
                          )}
                          <FontAwesomeIcon
                            title={t("review")}
                            icon={solid("file-lines")}
                            onClick={() => showUserLog(process)}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </TabPanel>
            <TabPanel>
              <table>
                <thead>
                  <tr>
                    <th>{t("prescription_name")}</th>
                    <th>{t("user")}</th>
                    <th>{t("status")}</th>
                    <th>{t("last_update")}</th>
                    <th>{t("actions")}</th>
                  </tr>
                </thead>
                <tbody>
                  {expertProcess?.map((process, index) => {
                    return (
                      <tr key={index}>
                        <td>{process.prescriptionName}</td>
                        <td>
                          {process.fiInitiator?.email}&nbsp;
                          {process.fiInitiator &&
                          process.fiInitiator.email !== user?.email ? (
                            <span
                              className={
                                "smallicon " +
                                (process.onlineState === "offline"
                                  ? "offline"
                                  : process.onlineState === "online"
                                  ? "online"
                                  : "busy")
                              }
                            >
                              <FontAwesomeIcon
                                title={t("onlinestate")}
                                icon={solid("circle")}
                              />
                            </span>
                          ) : (
                            ""
                          )}
                        </td>
                        <td>{t(process.status)}</td>
                        <td>{showLocale(process.lastUpdated)}</td>
                        <td>
                          <FontAwesomeIcon
                            title={t("review")}
                            icon={solid("file-lines")}
                            onClick={() => showFullLog(process)}
                          />
                          &nbsp;
                          {!process.fiInitiator ? (
                            <FontAwesomeIcon
                              title={t("assign_user")}
                              icon={solid("user")}
                              onClick={(e) => assignUser(e, process)}
                            />
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </TabPanel>
          </Tabs>
        </div>
      )}
      {showMessageBox ? (
        <MessageBox
          onButton={onButton}
          buttons={msgBoxButtons}
          text={msgBoxTitle}
          inputs={msgBoxInputs}
          id={msgBoxId}
          moreJSX={msgBoxMoreJSX}
          onClose={() => setShowMessageBox(false)}
        />
      ) : (
        ""
      )}
      {userDropdownOptions.visible ? (
        <div className="user_dropdown" ref={dropdownRef}>
          {allUsers.map((user, idx) => (
            <p key={idx} onClick={() => selectUser(user)}>
              {user?.fullName}
            </p>
          ))}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default PrescriptionList;

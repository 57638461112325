import { Box, Container, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const HelpContact = () => {
  const { t } = useTranslation();
  const recipient = "support@clarynext.com";
  const subject = "";
  const body = "";

  const mailtoLink = `mailto:${recipient}?subject=${subject}&body=${body}`;
  return (
    <Container sx={{ paddingY: 6 }} maxWidth="md">
      <Typography variant="h2">{t("help_and_contact")}</Typography>
      <Box>
        <Typography fontWeight={"bold"} paddingTop={2} variant="h6">
          {t("any_questions")}
        </Typography>
        <Typography fontSize={"14px"}>
          <a
            style={{
              color: "#175384",
              fontWeight: "BOLD",
            }}
            target="_blank"
            href={mailtoLink}
            rel="noreferrer"
          >
            support@clarynext.com
          </a>
        </Typography>
      </Box>
      <Box>
        <Typography fontWeight={"bold"} paddingTop={2} variant="h6">
          {t("meet")}
        </Typography>
        <Typography fontSize={"14px"}>
          <a
            target="_blank"
            rel="noreferrer"
            style={{
              color: "#175384",
              fontWeight: "BOLD",
            }}
            href="https://calendly.com/clarynext/30min?month=2023-05"
          >
            {t("schedule_meeting")}
          </a>
        </Typography>
      </Box>
    </Container>
  );
};

export default HelpContact;

import { v4 as uuidv4 } from "uuid";
const joint = require("jointjs");

const JointHelper = {
  measureText: (text, attrs, V, _) => {
    var fontSize = parseInt(attrs.fontSize, 10) || 10;

    var svgDocument = V("svg").node;
    var textElement = V("<text><tspan></tspan></text>").node;
    var textSpan = textElement.firstChild;
    var textNode = document.createTextNode("");

    textSpan.appendChild(textNode);
    svgDocument.appendChild(textElement);
    document.body.appendChild(svgDocument);

    var lines = text?.split("\n");
    var width = 0;

    // Find the longest line width.
    _.each(lines, function (line) {
      textNode.data = line;
      var lineWidth = textSpan.getComputedTextLength();

      width = Math.max(width, lineWidth);
    });

    var height = lines?.length * (fontSize * 1.2);

    V(svgDocument).remove();

    return { width: width, height: height };
  },
  createQuestionModel: (dia, V, _, isRTL) =>
    dia.Element.define(
      "qad.Question",
      {
        optionHeight: 30,
        questionHeight: 45,
        paddingBottom: 30,
        minWidth: 250,
        ports: {
          groups: {
            in: {
              position: {
                name: isRTL ? "right" : "left",
                args: isRTL
                  ? { dx: 10, x: undefined, dy: undefined, y: 20 }
                  : { dx: -10, x: undefined, dy: undefined, y: 20 },
              },
              attrs: {
                circle: {
                  magnet: "passive",
                  stroke: "white",
                  fill: "#175384",
                  r: 14,
                },
              },
            },
            out: {
              position: {
                name: isRTL ? "left" : "right",
                args: {
                  dx: 0,
                  y: 0,
                },
              },
              markup: [
                {
                  tagName: "path",
                  selector: "portPath",
                },
              ],
              attrs: {
                portPath: {
                  magnet: true,
                  d: isRTL
                    ? "M 0 6 L -12 6 L -12 0 L -24 12 L -12 24 L -12 18 L 0 18"
                    : "M 0 6 L 12 6 L 12 0 L 24 12 L 12 24 L 12 18 L 0 18",
                  fill: "#175384",
                  stroke: "black",
                },
              },
            },
          },
          items: [
            {
              group: "in",
              attrs: {},
            },
          ],
        },
        attrs: {
          ".": {
            magnet: false,
          },
          ".body": {
            refWidth: "100%",
            refHeight: "100%",
            rx: 5,
            ry: 8,
            stroke: "none",
            fill: {
              type: "linearGradient",
              stops: [{ offset: "100%", color: "#175384" }],
              // Top-to-bottom gradient.
              attrs: { x1: "0%", y1: "0%", x2: "0%", y2: "20%" },
            },
          },
          ".btn-add-option": {
            refX: isRTL ? undefined : 10,
            refDx: isRTL ? -22 : undefined,
            refDy: -22,
            cursor: "pointer",
            fill: "white",
          },
          ".btn-remove-option": {
            refX: isRTL ? undefined : 10,
            refDx: isRTL ? -22 : undefined,
            refY: 13,
            cursor: "pointer",
            fill: "white",
          },
          ".options": {
            refX: 0,
          },

          // Text styling.
          text: {
            fontFamily: "Arial",
          },
          ".option-text": {
            fontSize: 11,
            fill: "#175384",
            refX: isRTL ? undefined : 30,
            refDx: isRTL ? -30 : undefined,
            yAlignment: "middle",
          },
          ".question-text": {
            fill: "white",
            refX: "50%",
            refY: 15,
            fontSize: 15,
            textAnchor: "middle",
            style: {
              textShadow: "1px 1px 0px gray",
            },
          },

          // Options styling.
          ".option-rect": {
            rx: 3,
            ry: 3,
            stroke: "white",
            strokeWidth: 1,
            strokeOpacity: 0.5,
            fillOpacity: 0.7,
            fill: "white",
            refWidth: "100%",
          },
        },
      },
      {
        markup:
          '<rect class="body"/><text class="question-text"/><g class="options"></g><path class="btn-add-option" d="M5,0 10,0 10,5 15,5 15,10 10,10 10,15 5,15 5,10 0,10 0,5 5,5z"/>',
        optionMarkup:
          '<g class="option"><rect class="option-rect"/><path class="btn-remove-option" d="M0,0 15,0 15,5 0,5z"/><text class="option-text"/></g>',

        initialize: function () {
          dia.Element.prototype.initialize.apply(this, arguments);
          this.on("change:options", this.onChangeOptions, this);
          this.on(
            "change:question",
            function () {
              this.attr(".question-text/text", this.get("question") || "");
              this.autoresize();
            },
            this
          );

          this.on(
            "change:questionHeight",
            function () {
              this.attr(".options/refY", this.get("questionHeight"), {
                silent: true,
              });
              this.autoresize();
            },
            this
          );

          this.on("change:optionHeight", this.autoresize, this);

          this.attr(".options/refY", this.get("questionHeight"), {
            silent: true,
          });
          this.attr(".question-text/text", this.get("question"), {
            silent: true,
          });

          this.onChangeOptions();
        },

        onChangeOptions: function () {
          var options = this.get("options");
          var optionHeight = this.get("optionHeight");

          // First clean up the previously set attrs for the old options object.
          // We mark every new attribute object with the `dynamic` flag set to `true`.
          // This is how we recognize previously set attributes.
          var attrs = this.get("attrs");
          _.each(
            attrs,
            function (attrs, selector) {
              if (attrs.dynamic) {
                // Remove silently because we're going to update `attrs`
                // later in this method anyway.
                this.removeAttr(selector, { silent: true });
              }
            }.bind(this)
          );

          // Collect new attrs for the new options.
          var offsetY = 0;
          var attrsUpdate = {};
          var questionHeight = this.get("questionHeight");

          _.each(
            options,
            function (option) {
              var selector = ".option-" + option.id;

              attrsUpdate[selector] = {
                transform: "translate(0, " + offsetY + ")",
                dynamic: true,
              };
              attrsUpdate[selector + " .option-rect"] = {
                height: optionHeight,
                dynamic: true,
              };
              attrsUpdate[selector + " .option-text"] = {
                text: option.text,
                dynamic: true,
                refY: optionHeight / 2,
              };

              offsetY += optionHeight;

              var portY = offsetY - optionHeight / 2 + (questionHeight * 3) / 4;
              if (!this.getPort(option.id)) {
                this.addPort({
                  group: "out",
                  id: option.id,
                  args: { y: portY },
                });
              } else {
                this.portProp(option.id, "args/y", portY);
              }
            }.bind(this)
          );

          this.attr(attrsUpdate);
          this.autoresize();
        },

        autoresize: function () {
          var options = this.get("options") || [];
          var gap = this.get("paddingBottom") || 20;
          var height =
            options.length * this.get("optionHeight") +
            this.get("questionHeight") +
            gap;
          var questionWidth = JointHelper.measureText(
            this.get("question"),
            {
              fontSize: this.attr(".question-text/fontSize"),
            },
            V,
            _
          ).width;
          const optionsWidth = options.map(
            (option) =>
              JointHelper.measureText(
                option.text,
                {
                  fontSize: this.attr(".option-text/fontSize"),
                },
                V,
                _
              ).width
          );

          this.resize(
            Math.max(
              this.get("minWidth") || 150,
              questionWidth,
              ...optionsWidth
            ),
            height
          );
        },

        addOption: function (option) {
          var options = JSON.parse(JSON.stringify(this.get("options")));
          options.push(option);
          this.set("options", options);
        },

        removeOption: function (id) {
          var options = JSON.parse(JSON.stringify(this.get("options")));
          this.removePort(id);
          this.set("options", _.without(options, _.find(options, { id: id })));
        },

        changeOption: function (id, option) {
          if (!option.id) {
            option.id = id;
          }

          var options = JSON.parse(JSON.stringify(this.get("options")));
          options[_.findIndex(options, { id: id })] = option;
          this.set("options", options);
        },
      }
    ),
  createQuestionView: (dia, V, _) =>
    dia.ElementView.extend({
      events: {
        "click .btn-add-option": "onAddOption",
        "click .btn-remove-option": "onRemoveOption",
      },

      presentationAttributes: dia.ElementView.addPresentationAttributes({
        options: ["OPTIONS"],
      }),

      confirmUpdate: function (flags) {
        dia.ElementView.prototype.confirmUpdate.apply(this, arguments);
        if (this.hasFlag(flags, "OPTIONS")) this.renderOptions();
      },

      renderMarkup: function () {
        dia.ElementView.prototype.renderMarkup.apply(this, arguments);

        // A holder for all the options.
        this.$options = this.$(".options");
        // Create an SVG element representing one option. This element will
        // be cloned in order to create more options.
        this.elOption = V(this.model.optionMarkup);

        this.renderOptions();
      },

      renderOptions: function () {
        this.$options.empty();

        _.each(
          this.model.get("options"),
          function (option) {
            var className = "option-" + option.id;
            var elOption = this.elOption.clone().addClass(className);
            elOption.attr("option-id", option.id);
            this.$options.append(elOption.node);
          }.bind(this)
        );

        // Apply `attrs` to the newly created SVG elements.
        this.update();
      },

      onAddOption: function () {
        this.model.addOption({
          id: _.uniqueId("option-"),
          text: "Option " + this.model.get("options").length,
        });
      },

      onRemoveOption: function (evt) {
        this.model.removeOption(V(evt.target.parentNode).attr("option-id"));
      },
    }),
  createGraphPaperScroller: (
    canvas,
    dia,
    shapes,
    ui,
    Backbone,
    mvc,
    V,
    _,
    $,
    RichTextEditor,
    activityData,
    editable,
    t,
    isRTL
  ) => {
    // initializePaper
    const graph = new dia.Graph({}, { cellNamespace: shapes });
    const paper = new dia.Paper({
      interactive: editable,
      model: graph,
      width: window.visualViewport.width - 200,
      height: window.visualViewport.height - 60,
      gridSize: 10,
      snapLinks: {
        radius: 75,
      },
      linkPinning: false,
      multiLinks: false,
      defaultLink: new dia.Link({
        attrs: {
          ".marker-target": {
            d: "M 10 0 L 0 5 L 10 10 z",
            fill: "#6a6c8a",
            stroke: "#6a6c8a",
          },
          ".connection": {
            stroke: "#6a6c8a",
            strokeWidth: 2,
          },
        },
      }),
      defaultRouter: { name: "manhattan", args: { padding: 20 } },
      defaultConnector: { name: "rounded" },
      cellViewNamespace: shapes,
      validateConnection: function (cellViewS, magnetS, cellViewT, magnetT) {
        // Prevent linking from input ports.
        if (magnetS && magnetS.getAttribute("port-group") === "in")
          return false;
        // Prevent linking from output ports to input ports within one element.
        if (cellViewS === cellViewT) return false;
        // Prevent linking to input ports.
        return magnetT && magnetT.getAttribute("port-group") === "in";
      },
      validateMagnet: function (cellView, magnet) {
        // Prevent links from ports that already have a link
        let port = magnet.getAttribute("port");
        let links = graph.getConnectedLinks(cellView.model, { outbound: true });
        let portLinks = _.filter(links, function (o) {
          return o.get("source").port === port;
        });

        if (portLinks.length > 0) return false;
        return magnet.getAttribute("magnet") !== "passive";
      },
    });

    if (!editable) paper.$el.css("pointer-events", "none");

    const paperScroller = new ui.PaperScroller({
      paper: paper,
      autoResizePaper: true,
      cursor: "grab",
      contentOptions: function (paperScroller) {
        var visibleArea = paperScroller.getVisibleArea();
        return {
          padding: {
            bottom: visibleArea.height / 2,
            top: visibleArea.height / 2,
            left: visibleArea.width / 2,
            right: visibleArea.width / 2,
          },
          allowNewOrigin: "any",
        };
      },
    });

    canvas?.current?.appendChild(paperScroller.render().el);
    paperScroller.center();
    paper.on("blank:pointerdown", paperScroller.startPanning);

    // initializeSelection
    const Selection = Backbone.Collection.extend();
    let SelectionView = mvc.View.extend({
      PADDING: 3,

      BOX_TEMPLATE: V("rect", {
        fill: "none",
        stroke: "#C6C7E2",
        "stroke-width": 1,
        "pointer-events": "none",
      }),

      init: function () {
        this.listenTo(this.model, "add reset change", this.render);
      },

      render: function () {
        _.invokeMap(this.boxes, "remove");

        this.boxes = this.model.map(
          function (element) {
            return this.BOX_TEMPLATE.clone()
              .attr(element.getBBox().inflate(this.PADDING))
              .appendTo(this.options.paper.cells);
          }.bind(this)
        );

        return this;
      },

      onRemove: function () {
        _.invokeMap(this.boxes, "remove");
        delete this.boxes;
      },
    });

    const selection = new Selection();
    selection.on(
      "add reset",
      function () {
        var cell = selection.first();
        if (cell) {
        } else {
        }
      },
      this
    );

    graph.on({
      "cell:pointerclick": function (elementView) {
        return elementView;
      },
    });

    paper.on(
      {
        "element:pointerup": function (elementView) {
          selection.reset([elementView.model]);
        },
        "blank:pointerdown": function () {
          selection.reset([]);
        },
        "cell:pointerclick": function (elementView) {
          return elementView;
        },
      },
      this
    );
    graph.on(
      "remove",
      function () {
        selection.reset([]);
      },
      this
    );

    new SelectionView({
      model: selection,
      paper: paper,
    });

    document.body.addEventListener(
      "keydown",
      _.bind(function (evt) {
        var code = evt.which || evt.keyCode;
        // Do not remove the element with backspace if we're in inline text editing.
        if (
          (code === 8 || code === 46) &&
          !textEditor &&
          !selection.isEmpty()
        ) {
          selection.reset([]);
          return false;
        }

        return true;
      }, this),
      false
    );

    // initializeHalo
    const getCheckboxesHTML = (element) => {
      const checkBoxes = element.prop("data/checkboxes");

      return checkBoxes
        ? checkBoxes
            .map((checkBox, idx) =>
              getCheckboxElementString(checkBox, idx, checkBoxes.length)
            )
            .join(" ")
        : "";
    };

    const redrawCheckboxes = () => {
      // redraw items so that buttons to rearrange are correct!
      let checkboxes = [];
      $(".infos")
        .children("label")
        .each(function () {
          checkboxes.push({
            id: $(this).find("input")[0].id,
            label: $(this)[0].innerText,
          });
        });

      let newHTML = checkboxes
        .map((checkBox, idx) =>
          getCheckboxElementString(checkBox, idx, checkboxes.length)
        )
        .join(" ");

      $(".infos").html(newHTML);

      attachEventListeners();
    };

    const attachEventListeners = () => {
      $(".deleteCheckbox").on("click", function () {
        let idx = $(this).data("idx");
        $(".infos label").eq(idx).remove();
        redrawCheckboxes();
      });

      $(".moveCheckboxUp").on("click", function () {
        let idx = $(this).data("idx");
        // swap
        let $before = $(".infos label").eq(idx - 1);
        $(".infos label").eq(idx).insertBefore($before);
        redrawCheckboxes();
      });

      $(".moveCheckboxDown").on("click", function () {
        let idx = $(this).data("idx");
        // swap
        let $after = $(".infos label").eq(idx + 1);
        $(".infos label").eq(idx).insertAfter($after);
        redrawCheckboxes();
      });
    };

    const renderTextEditor = (element) => {
      const config = {
        skin: "rounded-corner",
        showFloatTextToolBar: false,
        showFloatImageToolBbar: false,
        showFloatParagraph: false,
        showFloatTableToolBar: false,
        maxHTMLLength: 20000000,
        toolbar: "clarynexttoolbar",
        toolbar_clarynexttoolbar:
          "{bold,italic,underline,forecolor,backcolor}|{justifyleft,justifycenter,justifyright,justifyfull}|{insertorderedlist,insertunorderedlist,indent,outdent,insertblockquote,insertemoji}" +
          " #{paragraphs:toggle,fontname:toggle,fontsize:toggle,inlinestyle,lineheight}" +
          " / {removeformat,cut,copy,paste,delete,find}|{insertlink,insertchars,inserttable,insertimagebyurl,insertdocument,inserttemplate,insertcode}|{preview,selectall}" +
          "#{toggleborder,fullscreenenter,fullscreenexit,undo,redo,togglemore}",
      };
      const editor = new RichTextEditor("#rich-text-editor", config);
      $("rte-line-break").remove();
      editor.setHTMLCode(element.prop("data/richText") ?? "");
      editor.document.body.dir = isRTL ? "rtl" : "ltr";
      return editor;
    };

    const getCheckboxElementString = (checkbox, idx, totalNum) => {
      let buttonCode = `<button class="deleteCheckbox" data-idx=${idx}>X</button>`;

      if (idx > 0)
        buttonCode += `<button class="moveCheckboxUp" data-idx=${idx}>^</button>`;
      else buttonCode += `<button style="visibility: hidden">^</button>`;
      if (idx < totalNum - 1)
        buttonCode += `<button class="moveCheckboxDown" data-idx=${idx}>v</button>`;
      else buttonCode += `<button style="visibility: hidden">v</button>`;
      return `${buttonCode}<label class="disabled" for="${checkbox.id}">
                      <input id="${checkbox.id}" type="checkbox" disabled />
                      ${checkbox.label}
                  </label><br/><br/>`;
    };

    const htmlToElements = (html) => {
      const template = document.createElement("template");
      template.innerHTML = html;
      return template.content.childNodes;
    };

    let halo;
    paper.on(
      "element:pointerup",
      function (elementView) {
        halo = new ui.Halo({
          cellView: elementView,
          useModelGeometry: true,
          boxContent: "",
        });

        halo.on("action:myaction:pointerdown", () => {
          const list = getCheckboxesHTML(elementView.model);
          const twoD = elementView.model.prop("data/twoDfeedback");
          const optionalComment = elementView.model.prop(
            "data/optionalComment"
          );
          const popup = new ui.Popup({
            content: ` <div class="extra-info-popup">
                          <p class="add-extra-info">${t("add_extra_info")}: </p>
                          <div id="rich-text-editor"></div> 
                          <p class="add-extra-info">${t(
                            "add_checkbox"
                          )}: </p>    
                          <div class="infos">${list}</div>
                          <div class="info-field">
                            <input class="input-info" type="text" placeholder="${t(
                              "checkbox_label"
                            )}">
                            <button class="add-info">${t("add")}</button>
                          </div>                       
                          <p><label for="twoDfeedback"><input id="twoDfeedback" type="checkbox" ${
                            !!twoD ? "checked" : ""
                          }/>${t("msg_show_2d_feedback")}</label></p>
                          <p><label for="optionalComment"><input id="optionalComment" type="checkbox" ${
                            !!optionalComment ? "checked" : ""
                          }/>${t("msg_show_optional_comment")}</label></p>
                          <button class="btn-save">${t("save")}</button>
                        </div>`,
            target: elementView.el,
            autoClose: true,
          });

          popup.render();
          const editor = renderTextEditor(elementView.model);

          // workaround repositioning popup after adding richtexteditor
          let oldleft = parseInt(getComputedStyle(popup.el, null)["left"]);
          popup.el.dir = isRTL ? "rtl" : "ltr";
          popup.el.style.left = oldleft - 150 + "px";

          $(".add-info").on("click", () => {
            const text = $(".input-info").val().trim();
            if (!text) return;
            const guid = $(".infos").children("label").length;
            const field = getCheckboxElementString(
              {
                id: `checkbox-${guid}`,
                label: text,
              },
              guid,
              guid + 1
            );
            const element = htmlToElements(field);
            $(".infos").append(element);

            // redraw items so that buttons to rearrange are correct!
            redrawCheckboxes();

            $(".input-info").val("");
          });

          $(".btn-save").on("click", function () {
            const twoD = document.getElementById("twoDfeedback").checked;

            const checkBoxes = [];
            $(".infos")
              .children("label")
              .each(function () {
                checkBoxes.push({
                  label: this.innerText,
                  id: this.children[0].id,
                  checked: this.children[0].checked,
                });
              });
            elementView.model.prop(
              "data/twoDfeedback",
              document.getElementById("twoDfeedback").checked
            );
            elementView.model.prop(
              "data/optionalComment",
              document.getElementById("optionalComment").checked
            );
            elementView.model.prop("data/richText", editor.getHTMLCode());
            elementView.model.prop("data/checkboxes", checkBoxes, {
              rewrite: true,
            });
            popup.remove();
          });

          attachEventListeners();
        });

        halo
          .removeHandle("resize")
          .removeHandle("rotate")
          .removeHandle("fork")
          .removeHandle("link")
          .removeHandle("clone")
          .removeHandle("unlink")
          .render();
      },
      this
    );

    // initializeInlineTextEditor
    let cellViewUnderEdit;
    let textEditor;

    let closeEditor = _.bind(function () {
      if (textEditor) {
        textEditor.remove();
        // Re-enable dragging after inline editing.
        cellViewUnderEdit.setInteractivity(true);
        textEditor = cellViewUnderEdit = undefined;
      }
    }, this);

    paper.on(
      "cell:pointerdblclick",
      function (cellView, evt) {
        // Clean up the old text editor if there was one.
        closeEditor();

        let vTarget = V(evt.target);
        let text;
        let cell = cellView.model;

        switch (cell.get("type")) {
          case "qad.Question":
            text = ui.TextEditor.getTextElement(evt.target);
            if (!text) {
              break;
            }
            if (vTarget.hasClass("body") || V(text).hasClass("question-text")) {
              text = cellView.$(".question-text")[0];
              cellView.textEditPath = "question";
              cellView.optionId = null;
            } else if (V(text).hasClass("option-text")) {
              cellView.textEditPath =
                "options/" +
                _.findIndex(cell.get("options"), {
                  id: V(text.parentNode).attr("option-id"),
                }) +
                "/text";
              cellView.optionId = V(text.parentNode).attr("option-id");
            } else if (vTarget.hasClass("option-rect")) {
              text = V(vTarget.node.parentNode).find(".option-text");
              cellView.textEditPath =
                "options/" +
                _.findIndex(cell.get("options"), {
                  id: V(vTarget.node.parentNode).attr("option-id"),
                }) +
                "/text";
            }
            break;
          default:
            break;
        }

        if (text) {
          textEditor = new ui.TextEditor({ text: text });
          textEditor.render(paper.el);

          textEditor.on(
            "text:change",
            function (newText) {
              let cell = cellViewUnderEdit.model;

              cell.prop(cellViewUnderEdit.textEditPath, newText);

              if (cellViewUnderEdit.optionId) {
                textEditor.options.text = cellViewUnderEdit.$(
                  ".option.option-" +
                    cellViewUnderEdit.optionId +
                    " .option-text"
                )[0];
              }
            },
            this
          );

          cellViewUnderEdit = cellView;
          // Prevent dragging during inline editing.
          cellViewUnderEdit.setInteractivity(false);
        }
      },
      this
    );

    $(document.body).on(
      "click",
      _.bind(function (evt) {
        let text = ui.TextEditor.getTextElement(evt.target);
        if (textEditor && !text) {
          closeEditor();
        }
      }, this)
    );

    // initializeTooltips
    new ui.Tooltip({
      rootTarget: ".canvas",
      target: ".joint-element",
      content: function (target) {
        var text = "- " + t("double_click_to_edit");
        text += "<br/><br/>- " + t("connect_port");

        return text;
      }.bind(this),
      direction: "right",
      right: "#paper",
      padding: 20,
    });

    return { graph, paper, paperScroller };
  },

  addQuestion: (graph, shapes, paperScroller, activityData) => {
    if (paperScroller !== null) {
      let visibleArea = paperScroller.getVisibleArea();
      let newX = Math.floor(
        Math.random() * (visibleArea.width - 100) + visibleArea.x
      );
      let newY = Math.random() * (visibleArea.height - 70) + visibleArea.y;

      let newQuestion = new shapes.qad.Question({
        position: {
          x: newX,
          y: newY,
        },
        size: { width: 100, height: 70 },
        question: activityData?.question?.replace(/<[^>]*>/g, ""),
        questionRichText: activityData?.question,
        options: activityData.options,
        data: {
          richText: activityData.richText,
          checkboxes: activityData.checkboxes,
          listItems: activityData.listItems,
          activityTitle: activityData.activityTitle,
          progressIndicator: activityData.progressIndicator,
          guidanceText: activityData.guidanceText,
          textBox: activityData.textBox,
          continuation: activityData.continuation,
          timerActivity: activityData.timerActivity,
          updateKey: activityData.updateKey,
        },
      });

      graph.addCell(newQuestion);
    }
  },
};

export default JointHelper;

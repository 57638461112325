import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Box, Stack, Typography, useMediaQuery } from "@mui/material";
import CustomInputs, {
  PasswordField,
} from "../../Ui/Shared/Inputs/CustomInputs";
import CustomButton from "../../Ui/Shared/Buttons/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import {
  forgotPassword,
  resetPassword,
} from "../../Redux/slice/authentication";

const initialValues = {
  fullName: "",
  email: "",
  password: "",
};

function ForgotPassword() {
  const auth = useSelector((s) => s.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [values, setValues] = useState(initialValues);
  const [otpOpen, setOtpOpen] = useState(false);
  const [otp, setOtp] = useState();
  const [errors, setErrors] = useState({});
  const [toastMessage, setToastMessage] = useState();
  const [optMessage, setOtpMessage] = useState();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    validations({ [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validations()) {
      if (!otpOpen) {
        const res = await dispatch(
          forgotPassword({
            email: values.email,
          })
        );
        if (res.payload.success) {
          setToastMessage();
          setOtpOpen(true);
        } else if (!res.payload.success) {
          setToastMessage(res.payload.message);
        }
      } else if (otpOpen) {
        const res = await dispatch(
          resetPassword({
            email: auth?.data.data.email,
            userId: auth?.data.data.userId,
            code: otp,
            newPassword: values.password,
          })
        );
        if (res.payload.success) {
          setValues(initialValues);
          setOtpOpen(false);
          navigate("/login");
        } else if (!res.payload.success) {
          setOtpMessage(res.payload.message);
        }
        setOtp("");
      }
    }
  };
  const validations = (fieldValue = values) => {
    let temp = { ...errors };
    if ("email" in fieldValue)
      temp.email = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
        fieldValue.email
      )
        ? ""
        : "Email is not valid";
    setErrors({
      ...temp,
    });
    return Object.values(temp).every((x) => x === "");
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      height={"calc(100vh - 65px)"}
      sx={{ backgroundColor: "#E5E5E5" }}
      width="100%"
    >
      <Box
        gap={4}
        my={"100px"}
        display={"flex"}
        width="100%"
        flexDirection="column"
      >
        <Box
          mx={"auto"}
          width="372px"
          padding={"32px"}
          boxShadow={"0px 1px 5px rgba(0, 0, 0, 0.2)"}
          borderRadius={1}
          sx={{ backgroundColor: "white" }}
          component="form"
          onSubmit={handleSubmit}
        >
          <Box display={"flex"} gap={"16px"} flexDirection="column">
            <Typography
              textAlign={"center"}
              fontSize={"16px"}
              fontWeight={500}
              fontFamily={"Roboto"}
              color={"gray.600"}
              lineHeight={"19px"}
            >
              Can’t log in?
            </Typography>
            {toastMessage && (
              <Stack
                sx={{ backgroundColor: "rgba(252, 236, 189, 0.5)" }}
                direction="row"
                gap={2}
                padding={2}
              >
                <InfoOutlinedIcon sx={{ color: "#F2C94C" }} />
                <Typography color="gray.400">{toastMessage}</Typography>
              </Stack>
            )}

            <Box display={"flex"} flexDirection="column" gap={"16px"}>
              <CustomInputs
                inputLabel={"Enter your email to reset your password "}
                labelColor="gray.300"
                name="email"
                value={values.email}
                onChange={handleChange}
                error={errors.email}
              />
              {otpOpen && (
                <PasswordField
                  inputLabel={"Enter your new password"}
                  labelColor="gray.200"
                  type="password"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  error={errors.password}
                />
              )}

              {otpOpen && (
                <Box
                  display={"flex"}
                  flexDirection="column"
                  pt={"8px"}
                  gap={"16px"}
                >
                  <Typography
                    fontWeight={400}
                    fontFamily={"Roboto"}
                    lineHeight={"19px"}
                    color={"gray.400"}
                    fontSize="12px"
                  >
                    We have just send you a temporary sign up code. Please open
                    your inbox and paste the code below.{" "}
                  </Typography>
                  <CustomInputs
                    error={optMessage}
                    type="text"
                    name="otp"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    placeholder="Paste OTP code"
                  />
                </Box>
              )}
              <CustomButton
                color="white"
                bgColor={"primary.600"}
                fontSize="16px"
                variant={"gray"}
                fontFamily="Roboto"
                fontWeight={400}
                lineHeight="22px"
              >
                {otpOpen ? "Reset password" : "Continue"}
              </CustomButton>
              <Box
                sx={{
                  height: "1px",
                  backgroundColor: "#F0F0F0",
                }}
              ></Box>
              <Link
                to="/login"
                style={{
                  textAlign: "center",
                  color: "#1F6FB0",
                  paddingTop: "24px",
                  fontSize: "14px",
                  fontWeight: 400,
                  fontFamily: "Roboto",
                }}
              >
                Return to log in
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default ForgotPassword;

import { Box, Container, Typography } from "@mui/material";
import React from "react";
const terms = [
  {
    id: 0,
    title: "Purpose",
    desc: "ClaryNext is a platform designed to help individuals define, communicate, learn, apply and improve soft knowledge and skills of a professional and/or personal nature.",
  },
  {
    id: 1,
    title: "Eligibility",
    desc: "ClaryNext is intended for use by individuals who are at least 18 years of age, Younger people may use the platform with the approval and supervision of their parents or legal guardians.",
  },
  {
    id: 2,
    title: "Fees",
    desc: "There may be fees associated with certain features and services provided through the ClaryNext platform. These fees will be clearly stated and are subject to change.",
  },
  {
    id: 3,
    title: "Data Collection",
    desc: "ClaryNext may collect information from users, including but not limited to personal information, learning progress, and usage data. This information will be used to improve the platform and provide personalized services.",
  },
  {
    id: 4,
    title: "Data Use and Protection",
    desc: "ClaryNext takes reasonable measures to protect user data and will not share or sell it to third parties without user consent. The platform enables users to selectively share their data with people whom they choose to trust and at their own risk.",
  },
  {
    id: 5,
    title: "Limitations on Use",
    desc: "Users may not use the platform to engage in any illegal or unauthorized activities",
  },
  {
    id: 6,
    title: "Violations of Terms of Use",
    desc: "If a user violates these terms of use, ClaryNext reserves the right to terminate the user's account and access to the platform.",
  },
  {
    id: 7,
    title: "Disclaimers",
    desc: " ClaryNext is provided 'as is' without any warranties of any kind, either express or implied. ClaryNext does not guarantee the relevancy, accuracy, or completeness of the content provided through the platform.",
  },
  {
    id: 8,
    title: "Changes to Terms of Use",
    desc: "ClaryNext reserves the right to modify these terms of use at any time without prior notice. By using the ClaryNext platform, you agree to be bound by the current terms of use.",
  },
];
const TermsCondition = () => {
  const recipient = "support@clarynext.com";
  const subject = "Example Subject";
  const body = "Example Body";

  const mailtoLink = `mailto:${recipient}?subject=${subject}&body=${body}`;

  return (
    <Container sx={{ paddingY: 6 }} maxWidth="md">
      <Typography variant="h2">Terms and Conditions</Typography>

      {terms.map((item) => (
        <Box key={item.id}>
          <Typography fontWeight={"bold"} paddingTop={2} variant="h6">
            {item.title}
          </Typography>
          <Typography fontSize={"14px"}>{item.desc}</Typography>
        </Box>
      ))}
      <Box>
        <Typography fontWeight={"bold"} paddingTop={2} variant="h6">
          Contact
        </Typography>
        <Typography fontSize={"14px"}>
          If you have questions or need support, you can contact ClaryNext by
          email at{" "}
          <a
            style={{
              color: "#175384",
              fontWeight: "BOLD",
            }}
            target="_blank"
            href={mailtoLink}
            rel="noreferrer"
          >
            support@clarynext.com
          </a>
        </Typography>
      </Box>
      <Typography py={10} fontSize={"14px"}>
        By using the ClaryNext platform, you agree to be bound by these terms of
        use. If you do not agree to these terms of use, you should not use the
        ClaryNext platform.
      </Typography>
    </Container>
  );
};

export default TermsCondition;

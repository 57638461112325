import axios from "axios";
import { config } from "../../Utils/Environment";

const API_URL = config.API_BASE;

export const callAPi = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-type": "application/json",
  },
});

callAPi.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("access_token");
    if (token && token !== undefined) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export const callPrivateAPi = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-type": "application/json",
  },
});

callPrivateAPi.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("access_token");
    if (token && token !== undefined) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

import { Box, Button, Popover, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllCollaborators } from "../../../Redux/slice/collaborators";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Timer from "../Card/Timer";
import { useTranslation } from "react-i18next";

const Settings = () => {
  const { t } = useTranslation();
  const [state, setState] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const collaborators = useSelector((s) => s?.collaborators);
  const dispatch = useDispatch();
  const handlePoperClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const getCollaborators = async () => {
    try {
      const res = await dispatch(getAllCollaborators());
    } catch (error) {}
  };

  useEffect(() => {
    getCollaborators();
  }, [dispatch]);

  return (
    <>
      {localStorage.getItem("access_token") ? (
        <Box
          paddingY={4}
          width="80%"
          mx={"auto"}
          display={"flex"}
          flexDirection={"column"}
          gap={2}
        >
          <Box display={"flex"} flexDirection={"column"} gap={1}>
            <Box display={"flex"} gap={6}>
              <Box
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "5px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  aria-describedby={id}
                  variant="contained"
                  onClick={handlePoperClick}
                >
                  {t("collaborate_mode")}
                </Button>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  sx={{
                    left: 70,
                  }}
                >
                  <Box
                    padding={2}
                    display={"flex"}
                    flexDirection={"column"}
                    gap={2}
                    position={"relative"}
                    zIndex={0}
                  >
                    <Box
                      onClick={handleClose}
                      sx={{
                        position: "absolute",
                        zIndex: 10000,
                        top: 0,
                        right: 0,
                        borderRadius: "100%",
                        backgroundColor: "grey",
                        width: 30,
                        height: 30,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      X
                    </Box>
                    <Box
                      padding={2}
                      display={"flex"}
                      flexDirection={"column"}
                      gap={2}
                    >
                      <Typography>
                        Select Collaborator for private mode
                      </Typography>
                      <Box display={"flex"} flexDirection={"column"} gap={1}>
                        {collaborators?.data?.data?.length !== 0 &&
                          collaborators?.data?.data?.map((item) => (
                            <Box
                              key={item._id}
                              fontSize={"12px"}
                              position={"relative"}
                              px={2}
                              component={"button"}
                              onClick={() => {
                                localStorage.setItem(
                                  "collaboratorPrivate",
                                  item._id
                                );
                                toast.success(
                                  `Private Mode is Enable for ${item.fullName}`
                                );
                                handleClose();
                              }}
                            >
                              {item.fullName}
                            </Box>
                          ))}
                      </Box>
                      {/* <Timer /> */}
                    </Box>
                  </Box>
                </Popover>
              </Box>

              <Button
                aria-describedby={id}
                variant="contained"
                onClick={() => {
                  localStorage.removeItem("collaboratorPrivate");
                  toast.success("Normal Mode is Enable ");
                }}
              >
                {t("open_mode")}
              </Button>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          height={"calc(100vh - 65px)"}
        >
          <Typography>
            Please{" "}
            <Link to={"/login"}>
              <span
                style={{
                  color: "#175384",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
              >
                Sign In
              </span>
            </Link>{" "}
            for activities
          </Typography>
        </Box>
      )}
    </>
  );
};

export default Settings;

import { useEffect, useState } from "react";
import "./conversation.css";
import ProfileIcon from "../../../Assets/profile.png";
import ChatServices from "../../../Redux/api/ChatServices";

export default function Conversation({ conversation, currentUser }) {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const friendId = conversation.members.find((m) => m !== currentUser?._id);
    const getUser = async () => {
      try {
        const res = await ChatServices.getUserInfo(friendId);
        setUser(res.data.data);
      } catch (err) {}
    };
    getUser();
  }, [currentUser, conversation]);

  return (
    <div className="conversation">
      <img
        className="conversationImg"
        src={user?.profilePicture ? user?.profilePicture : ProfileIcon}
        alt=""
      />
      <span className="conversationName">{user?.fullName}</span>
    </div>
  );
}

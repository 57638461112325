import useAuth from "./useAuth";
import useRefreshToken from "./useRefreshToken";

const useRetryFetch = () => {
  const { auth } = useAuth();
  const refresh = useRefreshToken();

  return async (url, options) => {
    if (!options) options = {};
    if (!options.method) options.method = "GET";
    if (!options.credentials) options.credentials = "include";
    if (!options.headers) options.headers = {};
    if (!options.headers["Authorization"])
      options.headers["Authorization"] = `Bearer ${auth.accessToken}`;
    if (!options.headers["Content-Type"])
      options.headers["Content-Type"] = "application/json";

    let response = await fetch(url, options);

    if (response.status === 403) {
      console.log(
        "Detected FORBIDDEN. Get new access token and then retry ...."
      );
      let newAccessToken = await refresh();
      options.headers["Authorization"] = `Bearer ${newAccessToken}`;
      response = await fetch(url, options);
    }

    return response;
  };
};

export default useRetryFetch;

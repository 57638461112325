import { BehaviorSubject, Subject } from "rxjs";

const userlistSubject = new BehaviorSubject();
const messageSubject = new Subject();

export const distributionService = {
  sendUserlist: (userlist) => userlistSubject.next(userlist),
  getUserlist: () => userlistSubject.asObservable(),
  sendMessage: (message) => messageSubject.next(message),
  getMessage: () => messageSubject.asObservable(),
};

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  FacebookAuthProvider,
  getAuth,
  signInWithPopup,
} from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDTQ5Xhazx3OARM822ajpkW3uyuXl6VoAw",
  authDomain: "clarynext-cc565.firebaseapp.com",
  projectId: "clarynext-cc565",
  storageBucket: "clarynext-cc565.appspot.com",
  messagingSenderId: "289732789834",
  appId: "1:289732789834:web:58c92338e2ed0be02cc8b3",
  measurementId: "G-ZCZQ434DEE",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();

// Initialize Firebase
const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    return user;
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const signInWithFacebook = async () => {
  try {
    const res = await signInWithPopup(auth, facebookProvider);
    const user = res.user;
  } catch (err) {
    alert(err.message);
  }
};

export { auth, signInWithGoogle, signInWithFacebook };

import { CheckBox, Label } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { updateLogProcess } from "../../../Redux/slice/process";
import { useNavigate } from "react-router-dom";

const EngagementActivity = ({
  engagementData,
  engagmentCardValue,
  setEngagementCardValue,
  engagement,
}) => {
  const [activityTitle, setActivityTitle] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const regex = /[^a-zA-Z]/g;
  const htmlString = engagementData && engagementData?.data?.richText;
  const tempElement = document.createElement("div");
  tempElement.innerHTML = htmlString;
  const plainText = tempElement.textContent || tempElement.innerText || "";

  const activityTitleChange = (e) => {
    setActivityTitle(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await dispatch(
        updateLogProcess({
          id: engagement._id,
          data: {
            activity: engagement.activity,
            message: engagement.message,
            selectedOptionId: engagement.selectedOptionId,
            userData: JSON.stringify(engagmentCardValue),
          },
        })
      );
      if (res.payload) {
        navigate("/home/activities");
      }
    } catch (error) {}
  };

  return (
    <Box
      width={"80%"}
      height={"60vh"}
      sx={{
        backgroundColor: "grey.200",
        overflow: "auto",
      }}
      padding={2}
      display={"flex"}
      flexDirection={"column"}
      gap={2}
      component={"form"}
      onSubmit={handleSubmit}
    >
      <Box display={"flex"} justifyContent={"space-between"}>
        <TextField
          size="small"
          sx={{
            "& fieldset": { border: "none", padding: 0 },
          }}
          name="lable"
          placeholder="Activity 1"
          value={activityTitle}
          onChange={activityTitleChange}
        />
      </Box>

      <Divider />
      {plainText !== "undefined" && (
        <div dangerouslySetInnerHTML={{ __html: plainText }} />
      )}

      {engagementData &&
        engagementData?.data?.checkboxes?.map((item, index) => {
          const cardValue = engagmentCardValue || {}; // handle undefined or null case
          const keys = Object.keys(cardValue);
          const matchingKey = keys.find(
            (key) =>
              key.replace(regex, "").toLowerCase() ===
              item.label.replace(regex, "").toLowerCase()
          );
          const isChecked = matchingKey !== undefined;

          const handleChange = (event) => {
            const label = event.target.value;
            setEngagementCardValue((prevState) => {
              const newState = { ...prevState };
              const matchingKey = Object.keys(newState).find(
                (key) =>
                  key.replace(regex, "").toLowerCase() ===
                  item.label.replace(regex, "").toLowerCase()
              );
              if (matchingKey) {
                delete newState[matchingKey]; // remove the key-value pair
              } else {
                newState[label] = true;
              }
              return newState;
            });

            // Uncheck the checkbox
            event.target.checked = false;
          };

          return (
            <FormControlLabel
              key={index}
              control={<Checkbox value={item.label} />}
              label={item.label}
              onChange={handleChange}
              checked={isChecked}
            />
          );
        })}

      <Box display={"flex"}>
        <Typography component={"button"} type="submit">
          Update Engagement
        </Typography>
      </Box>
    </Box>
  );
};

export default EngagementActivity;

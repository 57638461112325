import { callPrivateAPi } from "./http-common";

const createLogEntry = (id, data) => {
  return callPrivateAPi.post(`/api/createLogEntry/${id}`, data);
};

const updateProcess = (id, data) => {
  return callPrivateAPi.put(`/api/updateProcess/${id}`, data);
};

const RunProcessServices = {
  createLogEntry,
  updateProcess,
};

export default RunProcessServices;

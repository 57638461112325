import { Button } from "@mui/material";
import React from "react";

const CustomButton = ({
  color = "#858585",
  bgColor = "#E6E6E6",
  fontFamily,
  onClick,
  variant,
  borderColor,
  children,
  fontWeight,
  width = "100%",
  height = "35px",
  fontSize = "1.125rem",
  type = "",
}) => {
  const variants = {
    primaryOutlined: {
      borderRadius: 28,
      height: height,
      width: width,
      color: "#FFB6C1",
      variant: "outlined",
      borderColor: "#FFB6C1",
      textTransform: "none",
      fontFamily: fontFamily,
      fontStyle: "normal",
      fontWeight: 500,
      width: width,
      fontSize: fontSize,
      lineHeight: "1.063rem",
      "&:hover": {
        backgroundColor: "#FFB6C1",
        color: "#000000",
        borderColor: "#FFB6C1",
      },
    },
    secondaryOutlined: {
      borderRadius: 28,
      height: height,
      width: width,
      color: "#000000",
      variant: "outlined",
      borderColor: "#000000",
      fontFamily: fontFamily,
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: fontSize,
      lineHeight: "1.063rem",
      "&:hover": {
        backgroundColor: "#000000",
        color: "#ffffff",
        borderColor: "#000000",
      },
    },
    socialButton: {
      textTransform: "none",
      boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.2)",
      borderRadius: "2px",
      color: "gray.500",
      height: height,
      width: width,
      variant: "outlined",
      backgroundColor: "#ffffff",
      borderColor: "#ffffff",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: fontSize,
      "&:hover": {
        backgroundColor: "#ffffff",
        borderColor: "#ffffff",
      },
    },
    primaryContained: {
      textTransform: "none",
      boxShadow: 1,
      borderRadius: "2px",
      color: "rgba(0, 0, 0, 0.54)",
      height: height,
      width: width,
      variant: "outlined",
      backgroundColor: "#ffffff",
      borderColor: "#ffffff",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: fontSize,
      "&:hover": {
        color: "rgba(0, 0, 0, 0.54)",
        borderColor: "rgba(0, 0, 0, 0.54)",
      },
    },
    secondaryContained: {
      textTransform: "none",
      boxShadow: 1,
      borderRadius: "2px",
      color: "#ffffff",
      height: height,
      width: width,
      variant: "outlined",
      backgroundColor: "#1877F2",
      borderColor: "#1877F2",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: fontSize,
      "&:hover": {
        color: "#ffffff",
        borderColor: "#1877F2",
        backgroundColor: "#1877F2",
      },
    },
    gray: {
      textTransform: "none",
      borderRadius: "4px",
      color: color,
      height: height,
      width: width,
      variant: "outlined",
      backgroundColor: bgColor,
      borderColor: borderColor,
      fontStyle: "normal",
      fontWeight: fontWeight,
      fontSize: fontSize,
      "&:hover": {
        color: "white",
        borderColor: "#1F6FB0",
        backgroundColor: "#1F6FB0",
      },
    },
  };

  return (
    <Button
      sx={variants[variant]}
      width
      type={type}
      variant={variants[variant].variant}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export default CustomButton;

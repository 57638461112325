import useAuth from "./useAuth";
import { config } from "../Utils/Environment";

const useRefreshToken = () => {
  const { setAuth } = useAuth();

  const refresh = async () => {
    let response = await fetch(config.API_BASE + "/refresh", {
      method: "GET",
      credentials: "include",
    });
    if (!response.ok) Promise.reject(response.status);
    const data = await response.json();
    setAuth((prevAuth) => {
      return { ...prevAuth, accessToken: data.accessToken };
    });
    return data.accessToken;
  };

  return refresh;
};

export default useRefreshToken;

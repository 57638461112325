import { useState, useEffect, useRef } from "react";
import { useEffectOnce } from "../../hooks/useEffectOnce";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { distributionService } from "../../services/distributionService";
import useRetryFetch from "../../hooks/useRetryFetch";
import useOutsideClick from "../../hooks/useOutsideClick";
import { config } from "../../Utils/Environment";
import "./ShowUserLog.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  solid /* regular */,
} from "@fortawesome/fontawesome-svg-core/import.macro"; // <-- import styles to be used
import ReadOnlyView from "../ReadOnlyView/ReadOnlyView";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import { showUserLogProcess } from "../../Redux/slice/process";

const ShowUserLog = () => {
  // passed state
  const dispatch = useDispatch();
  const location = useLocation();
  const { processId, presName, presHistoryId, expertEmail, autochat } =
    location.state;

  const [logEntries, setLogEntries] = useState([]);
  const [highlightId, setHighlightId] = useState("");
  const [rowIndex, setRowIndex] = useState(-1);
  const [chatState, setChatState] = useState("nochat");

  const tableRef = useRef(null);

  const retryFetch = useRetryFetch();

  const navigate = useNavigate();

  const { t } = useTranslation();

  useEffect(() => {}, [presHistoryId]);

  useEffectOnce(() => {
    const getLogEntries = async () => {
      const res = await dispatch(showUserLogProcess(processId));
      if (res.payload) {
        setLogEntries(res.payload.data);
      }
    };

    getLogEntries();

    let messageUnsubscribe = distributionService
      .getMessage()
      .subscribe((msg) => {
        switch (msg.command) {
          case "highlight":
            setHighlightId(msg.payload);
            setRowIndex(-1);
            break;
          default:
            break;
        }
      });

    return () => {
      messageUnsubscribe.unsubscribe();
    };
  }, []);

  const back = () => {
    navigate("/prescriptionlist");
  };

  const showLocale = (ts) => {
    return new Date(ts).toLocaleString();
  };

  const logEntryClicked = (e, idx) => {
    let entry = logEntries[idx];

    let highlight = entry.activity;
    if (entry.selectedOptionId) highlight += "|" + entry.selectedOptionId;

    setHighlightId(highlight);
    setRowIndex(idx);
  };

  const resetHighlights = () => {
    setHighlightId("");
    setRowIndex(-1);
  };

  const onstatechange = (data) => {
    setChatState(data);
  };

  useOutsideClick(tableRef, resetHighlights);

  return (
    <div className="twocolumnflex">
      <div className="mycentercontainer tworowflex">
        <div className="limitheight">
          <h3>{presName}</h3>
          <table className="mytable" ref={tableRef}>
            <thead>
              <tr>
                <th>{t("time")}</th>
                <th>{t("activity")}</th>
                <th>{t("message")}</th>
                <th>{t("option")}</th>
                <th>{t("data")}</th>
              </tr>
            </thead>
            <tbody>
              {logEntries.map((entry, idx) => {
                return (
                  <tr
                    key={idx}
                    className={rowIndex === idx ? "highlighted" : ""}
                    onClick={(e) => logEntryClicked(e, idx)}
                  >
                    <td>{showLocale(entry.timestamp)}</td>
                    <td>{entry.activity}</td>
                    <td>{entry.message}</td>
                    <td>{entry.selectedOptionId}</td>
                    <td>{entry.userData}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="prescriptionbox">
            <ul className="prescriptionlist">
              <li onClick={() => back()}>
                <FontAwesomeIcon icon={solid("arrow-left")} />
                &nbsp;&nbsp; {t("back_to_overview")}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <ReadOnlyView presHistoryId={presHistoryId} highlightId={highlightId} />
    </div>
  );
};

export default ShowUserLog;

import React from "react";
import { toast } from "react-toastify";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

const RichTextEditor = ({ setBody, body }) => {
  const handleChange = (content) => {
    const imageTags = extractImageTags(content);
    let senatizeContent = content;

    for (let i = 0; i < imageTags.length; i++) {
      const imageInfo = extractImageInfo(imageTags[i]);
      if (imageInfo.size > 25 * 1024) {
        toast.error("Image should be less than 25KB.");
        senatizeContent = senatizeContent.replace(imageTags[i], ""); // Remove the image from the content
      }
    }

    setBody({
      ...body,
      richTextBody: senatizeContent,
    });
  };

  const extractImageTags = (content) => {
    const regex = /<img[^>]+>/g;
    return content.match(regex) || [];
  };

  const extractImageInfo = (imageTag) => {
    const regex = /src="([^"]+)"/;
    const match = imageTag.match(regex);
    if (match && match[1]) {
      const url = match[1];
      const size = url.length * 0.75; // Approximate size in bytes (assuming base64 encoded)
      return { url, size };
    }
    return { url: "", size: 0 };
  };

  return (
    <div>
      <SunEditor
        height="400px"
        autoFocus={true}
        onChange={handleChange}
        defaultValue={body.richTextBody}
        placeholder="Please Enter Body"
        setOptions={{
          buttonList: [
            ["undo", "redo"],
            ["font", "fontSize", "formatBlock"],
            ["paragraphStyle", "blockquote"],
            [
              "bold",
              "underline",
              "italic",
              "strike",
              "subscript",
              "superscript",
            ],
            ["fontColor", "hiliteColor", "textStyle"],
            ["removeFormat"],
            "/",
            ["outdent", "indent"],
            ["align", "horizontalRule", "list", "lineHeight"],
            ["table", "link"],
            ["horizontalRule", "image", "template"],
          ],
        }}
      />
    </div>
  );
};

export default RichTextEditor;

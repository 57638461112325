import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Box, Stack, Typography, useMediaQuery } from "@mui/material";
import CustomButton from "../../Ui/Shared/Buttons/CustomButton";
import GoogleLogo from "../../Assets/Google.svg";
import FacebookLogo from "../../Assets/facebook.svg";
import CustomInputs, {
  PasswordField,
} from "../../Ui/Shared/Inputs/CustomInputs";
import { useDispatch, useSelector } from "react-redux";
import { loginUser, socialLogin } from "../../Redux/slice/authentication";
import { signInWithFacebook, signInWithGoogle } from "../../firebase/firebase";

const initialValues = {
  email: "",
  password: "",
};

function Login() {
  const dispatch = useDispatch();
  const auth = useSelector((s) => s.auth);
  const navigate = useNavigate();
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [otpMessage, setOtpMessage] = useState();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    validations({ [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validations()) {
      const res = await dispatch(loginUser(values));
      if (res?.payload?.access_token) {
        navigate("/home/activities");
      } else {
        setOtpMessage(res.payload.message);
      }
    }
  };
  const validations = (fieldValue = values) => {
    let temp = { ...errors };
    if ("email" in fieldValue)
      temp.email = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
        fieldValue.email
      )
        ? ""
        : "Email is not valid";
    setErrors({
      ...temp,
    });
    return Object.values(temp).every((x) => x === "");
  };

  return (
    <Box
      sx={{ backgroundColor: "#E5E5E5" }}
      width="100%"
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Box
        gap={4}
        my={"100px"}
        display={"flex"}
        width="100%"
        flexDirection="column"
      >
        <Box
          mx={"auto"}
          width="372px"
          boxShadow={"0px 1px 5px rgba(0, 0, 0, 0.2)"}
          borderRadius={1}
          height="100%"
          sx={{ backgroundColor: "white" }}
          padding={"32px"}
        >
          <Box
            width={"100%"}
            display={"flex"}
            flexDirection="column"
            gap={"16px"}
            component="form"
            onSubmit={handleSubmit}
          >
            <Typography
              textAlign={"center"}
              fontSize={"16px"}
              fontWeight={500}
              fontFamily={"Roboto"}
              color={"gray.600"}
              lineHeight={"19px"}
            >
              Log in to continue{" "}
            </Typography>
            <Stack spacing={"16px"}>
              <CustomInputs
                error={errors.email}
                type="email"
                name="email"
                value={values.email}
                onChange={handleChange}
                placeholder="Enter Email"
              />
              <PasswordField
                error={otpMessage}
                type="password"
                name="password"
                value={values.password}
                onChange={handleChange}
                placeholder="Enter Password"
              />
              <CustomButton
                type="submit"
                bgColor="#F5F5F5"
                color="#ADADAD"
                borderColor="#E5E5E5"
                fontSize="16px"
                variant={"gray"}
                fontFamily="Roboto"
                fontWeight={400}
                lineHeight="22px"
              >
                Continue
              </CustomButton>
            </Stack>
            <Typography
              fontSize={"15px"}
              fontWeight={600}
              lineHeight={"24px"}
              fontFamily={"Manrope"}
              color={"gray.400"}
              textAlign={"center"}
            >
              Or
            </Typography>
            <Stack spacing={"10px"}>
              <CustomButton
                onClick={async () => {
                  const user = await signInWithGoogle();
                  if (user) {
                    const res = await dispatch(
                      socialLogin({
                        userData: user,
                        provider: "Google",
                      })
                    );
                    if (res.payload.access_token) {
                      localStorage.setItem(
                        "access_token",
                        res.payload.access_token
                      );
                      navigate("/home/activities");
                    }
                  }
                }}
                fontSize="14px"
                variant={"socialButton"}
                color="gray.500"
                fontWeight={600}
                type="button"
              >
                <Box
                  width={"100%"}
                  display={"flex"}
                  gap={"8px"}
                  justifyContent="center"
                  alignItems={"center"}
                >
                  <img width={"18px"} height={"18px"} src={GoogleLogo} alt="" />
                  <Typography fontFamily={"Roboto"} textAlign={"center"}>
                    Continue with Google
                  </Typography>
                </Box>
              </CustomButton>
              <CustomButton
                fontSize="14px"
                variant={"socialButton"}
                color="gray.500"
                fontWeight={600}
                type="button"
                onClick={signInWithFacebook}
              >
                <Box
                  width={"100%"}
                  display={"flex"}
                  gap={"8px"}
                  justifyContent="center"
                  alignItems={"center"}
                >
                  <img
                    width={"18px"}
                    height={"18px"}
                    src={FacebookLogo}
                    alt=""
                  />
                  <Typography fontFamily={"Roboto"} textAlign={"center"}>
                    Continue with Facebook
                  </Typography>
                </Box>
              </CustomButton>
            </Stack>

            <Stack
              paddingX={"21px"}
              justifyContent={"space-between"}
              direction={"row"}
            >
              <Link to={"forgotPassword"}>
                <Typography
                  lineHeight={"24px"}
                  fontWeight={400}
                  fontFamily={"Roboto"}
                  fontSize={"14px"}
                  color={"primary.500"}
                  sx={{ textDecoration: "underline" }}
                >
                  Forgot Password
                </Typography>
              </Link>
              <Link to={"/register"}>
                <Typography
                  ineHeight={"24px"}
                  fontWeight={400}
                  fontFamily={"Roboto"}
                  fontSize={"14px"}
                  color={"primary.500"}
                  sx={{ textDecoration: "underline" }}
                >
                  Create Account
                </Typography>
              </Link>
            </Stack>
            <Box
              sx={{
                height: "1px",
                backgroundColor: "#F0F0F0",
              }}
            ></Box>

            <Typography
              fontFamily={"Roboto"}
              paddingX={"10px"}
              color={"gray.400"}
              fontSize={"12px"}
              lineHeight={"19px"}
            >
              By clicking “continue” , you agree to the Clarynext&nbsp;
              <Typography
                fontFamily={"Roboto"}
                fontSize={"12px"}
                color={"primary.400"}
                sx={{ cursor: "pointer", textDecoration: "underline" }}
                component={"span"}
                onClick={() => navigate("/terms")}
              >
                Terms of service
              </Typography>
              &nbsp;and acknowledge the&nbsp;
              <Typography
                fontFamily={"Roboto"}
                fontSize={"12px"}
                color={"primary.400"}
                sx={{ cursor: "pointer", textDecoration: "underline" }}
                onClick={() => navigate("/terms")}
                component={"span"}
              >
                Privacy Policy
              </Typography>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Login;

import { Box, Button, Popover, Typography } from "@mui/material";
import React from "react";
import AppsOutlinedIcon from "@mui/icons-material/AppsOutlined";
import { useTranslation } from "react-i18next";

const OptionsPopUpPublic = ({
  idOptions,
  openOptions,
  anchorElOptions,
  handleCloseOptions,
  item,
  handlePoperClickOptions,
  shareLink,
  duplicateActivity,
  selectedIndex,
}) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        borderRadius: "5px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Button
        aria-describedby={idOptions}
        variant="contained"
        onClick={handlePoperClickOptions}
      >
        <AppsOutlinedIcon />
      </Button>
      <Popover
        elevation={1}
        id={idOptions}
        open={openOptions}
        anchorEl={anchorElOptions}
        onClose={handleCloseOptions}
        sx={{
          left: 70,
        }}
      >
        <Box
          padding={2}
          display={"flex"}
          flexDirection={"column"}
          gap={2}
          position={"relative"}
        >
          <Box display={"flex"} flexDirection={"column"} gap={2}>
            <Typography
              component="button"
              onClick={() => {
                shareLink(selectedIndex);
                handleCloseOptions();
              }}
            >
              {t("share")}
            </Typography>
            <Typography
              component="button"
              onClick={() => {
                duplicateActivity(selectedIndex);
                handleCloseOptions();
              }}
            >
              {t("duplicate")}
            </Typography>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};

export default OptionsPopUpPublic;

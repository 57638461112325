import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import PublicPrescriptionsServices from "../api/PublicPrescriptionsServices";

export const initialState = {
  data: [],
  loading: "idle",
  error: null,
  message: null,
};

export const getPublicPrescriptions = createAsyncThunk(
  "publicPrescriptions/getPublicPrescriptions",
  async () => {
    try {
      const res = await PublicPrescriptionsServices.publicPrescriptions();
      if (res.data) {
        toast.success(res.data.message);
      }
      return res.data;
    } catch (error) {
      toast.error(error.response.message);
      return error.response.message;
    }
  }
);

export const searchPublicPrescription = createAsyncThunk(
  "publicPrescriptions/searchPublicPrescription",
  async (data) => {
    try {
      const res = await PublicPrescriptionsServices.searchPublicPrescription(
        data
      );
      if (res.data) {
        toast.success(res.data.message);
      }
      return res.data;
    } catch (error) {
      toast.error(error.response.message);
      return error.response.message;
    }
  }
);

export const publicPrescriptionSlice = createSlice({
  name: "publicPrescriptions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPublicPrescriptions.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(getPublicPrescriptions.fulfilled, (state, action) => {
      state.loading = "idle";
      state.data = action.payload;
      state.message = action.payload.message;
    });
    builder.addCase(getPublicPrescriptions.rejected, (state, action) => {
      state.loading = "rejected";
      state.message = action.payload.message;
    });

    builder.addCase(searchPublicPrescription.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(searchPublicPrescription.fulfilled, (state, action) => {
      state.loading = "idle";
      state.data = action.payload;
      state.message = action.payload.message;
    });
    builder.addCase(searchPublicPrescription.rejected, (state, action) => {
      state.loading = "rejected";
      state.message = action.payload.message;
    });
  },
});

export default publicPrescriptionSlice.reducer;

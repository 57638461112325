import {
  Box,
  Button,
  Divider,
  TablePagination,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import ActivityTable from "../../Ui/Shared/Table/ActivityTable";
import { getEngagementedActivity } from "../../Redux/slice/prescriptions";

const ViewActivity = () => {
  const prescriptions = useSelector((s) => s.prescription.data.data);
  const [refetch, setRefetch] = useState();
  const { state } = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredprescriptions, setFilteredprescriptions] = useState([]);

  const tableHead = [
    {
      id: 0,
      headTitle: "Name",
    },
    {
      id: 1,
      headTitle: "Email",
    },
    {
      id: 2,
      headTitle: "Status",
    },
    {
      id: 3,
      headTitle: "Last Update",
    },
    {
      id: 4,
      headTitle: "Action",
    },
  ];

  const getAllgetEngagemented = async (id) => {
    try {
      const res = await dispatch(getEngagementedActivity(id));
    } catch (error) {}
  };

  useEffect(() => {
    getAllgetEngagemented(state._id);
  }, [dispatch, state._id]);

  useEffect(() => {
    if (searchQuery.trim()) {
      if (prescriptions) {
        const filteredData = prescriptions.filter(
          (collaborator) =>
            collaborator.fullName
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            collaborator.email
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            collaborator.status
              .toLowerCase()
              .includes(searchQuery.toLowerCase())
        );
        setFilteredprescriptions(filteredData);
      }
    } else {
      setFilteredprescriptions(prescriptions);
    }
  }, [prescriptions, searchQuery]);

  useEffect(() => {
    if (localStorage.getItem("collaboratorPrivate")) {
      const privateModeData = prescriptions?.filter((item) => {
        return (
          item?.collaboratorId === localStorage.getItem("collaboratorPrivate")
        );
      });
      setFilteredprescriptions(privateModeData);
      setRefetch(true);
    }
  }, [prescriptions, refetch]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <Box>
      <Box>
        <Typography fontSize={"22px"} fontWeight={500} padding={2}>
          {state.name}
        </Typography>
        <Divider />
      </Box>
      <Box paddingX={4} paddingY={1} className="view_activity-row">
        <Button variant="outlined" className="button">
          {t("engagements")}
        </Button>
        {localStorage.getItem("collaboratorPrivate") ? null : (
          <TextField
            name="search"
            label="search"
            value={searchQuery}
            onChange={handleSearchChange}
          />
        )}
      </Box>
      <Box paddingX={4} paddingY={1}>
        <ActivityTable
          state={state}
          tableHead={tableHead}
          tableBody={filteredprescriptions}
        />
      </Box>
    </Box>
  );
};

export default ViewActivity;

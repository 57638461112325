import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AuthenticationServices from "../api/AuthenticationServices";
import { toast } from "react-toastify";

export const initialState = {
  data: [],
  loading: "idle",
  error: null,
  message: null,
};

export const createNewUser = createAsyncThunk(
  "authentication/createNewUser",
  async (user, { rejectWithValue }) => {
    try {
      const res = await AuthenticationServices.register(user);
      if (res.data) {
        toast.success(res.data.message);
      }
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const createUser = createAsyncThunk(
  "authentication/createUser",
  async (user, { rejectWithValue }) => {
    try {
      const res = await AuthenticationServices.createUser(user);
      if (res.data) {
        toast.success(res.data.message);
      }
      return res.data;
    } catch (error) {
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);
export const verifyEmail = createAsyncThunk(
  "authentication/createNewUser",
  async (data, { rejectWithValue }) => {
    try {
      const res = await AuthenticationServices.verifyEmail(data);
      if (res.data) {
        toast.success(res.data.message);
      }
      return res.data;
    } catch (error) {
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);
export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async (user, { rejectWithValue }) => {
    try {
      const res = await AuthenticationServices.login(user);
      if (res.data) {
        toast.success(res.data.message);
        localStorage.setItem("access_token", res.data.access_token);
      }
      return res.data;
    } catch (error) {
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "auth/forgotPasswprd",
  async (user, { rejectWithValue }) => {
    try {
      const res = await AuthenticationServices.forgotPassword(user);
      if (res.data) {
        toast.success(res.data.message);
      }
      return res.data;
    } catch (error) {
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);
export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (user, { rejectWithValue }) => {
    try {
      const res = await AuthenticationServices.resetPassword(user);
      if (res.data) {
        toast.success(res.data.message);
      }
      return res.data;
    } catch (error) {
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

export const socialLogin = createAsyncThunk(
  "auth/socialLogin",
  async (user, { rejectWithValue }) => {
    try {
      const res = await AuthenticationServices.socialLogin(user);
      if (res.data) {
        toast.success(res.data.message);
      }
      return res.data;
    } catch (error) {
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

export const authSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    handleLogout: (state) => {
      state.userData = {};
      localStorage.clear();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createNewUser.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(createNewUser.fulfilled, (state, action) => {
      state.loading = "idle";
      state.data = action.payload;
      state.message = action.payload.message;
    });
    builder.addCase(createNewUser.rejected, (state, action) => {
      state.loading = "rejected";
      state.message = action.payload.msg;
    });
    builder.addCase(loginUser.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.loading = "idle";
      state.data = action.payload;
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      state.loading = "rejected";
      state.error = action.payload;
    });
    builder.addCase(createUser.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(createUser.fulfilled, (state, action) => {
      state.loading = "idle";
      state.data = action.payload;
      state.message = action.payload.message;
    });
    builder.addCase(createUser.rejected, (state, action) => {
      state.loading = "rejected";
      state.error = action.payload.msg;
    });

    builder.addCase(forgotPassword.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(forgotPassword.fulfilled, (state, action) => {
      state.loading = "idle";
      state.data = action.payload;
      state.message = action.payload.message;
    });
    builder.addCase(forgotPassword.rejected, (state, action) => {
      state.loading = "rejected";
      state.error = action.payload.msg;
    });

    builder.addCase(resetPassword.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      state.loading = "idle";
      state.data = action.payload;
      state.message = action.payload.message;
    });
    builder.addCase(resetPassword.rejected, (state, action) => {
      state.loading = "rejected";
      state.error = action.payload.msg;
    });

    builder.addCase(socialLogin.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(socialLogin.fulfilled, (state, action) => {
      state.loading = "idle";
      state.data = action.payload;
      state.message = action.payload.message;
    });
    builder.addCase(socialLogin.rejected, (state, action) => {
      state.loading = "rejected";
      state.error = action.payload?.msg;
    });
  },
});

export default authSlice.reducer;

import { callAPi, callPrivateAPi } from "./http-common";

const addPresCription = (data) => {
  return callPrivateAPi.post("/api/createNewPrescription", data);
};
const getUserPrescriptions = () => {
  return callPrivateAPi.get("/api/prescriptions/user");
};
const renameUserPrescription = (id, data) => {
  return callPrivateAPi.put(`/api/prescriptions/rename/${id}`, data);
};

const deleteUserPrescription = (id) => {
  return callPrivateAPi.delete(`/api/prescriptions/${id}`);
};
const editUserPrescription = (id, data) => {
  return callPrivateAPi.put(`/api/prescriptions/${id}`, data);
};

const getPrescriptionById = (id) => {
  return callPrivateAPi.get(`/api/getPrescriptionById/${id}`);
};
const publicPrescriptions = () => {
  return callAPi.get("/api/getAllPublicPrescription");
};
const sharePrescriptions = (id) => {
  return callPrivateAPi.post(`/api/PrescriptionAsPublic/${id}`);
};
const copyPublicPrescription = (id) => {
  return callPrivateAPi.post(`/api/copyPublicPrescription/${id}`);
};

const sharePrescription = (id) => {
  return callAPi.post(`/api/requestDeeplink/${id}`);
};
const getSharePrescription = (id) => {
  return callAPi.get(`/api/deeplinks/${id}`);
};

const getPrescriptionHistoryById = (id) => {
  return callPrivateAPi.get(`/api/getPrescriptionHistoryById/${id}`);
};

const archivePrescription = (id) => {
  return callPrivateAPi.delete(`/api/prescriptions/archive/${id}`);
};

const searchUserPrescription = (data) => {
  return callPrivateAPi.post("/api/searchActivity", data);
};
const GetEngagementedActivityService = (id) => {
  return callPrivateAPi.get(`/api/getActivityEngagements/${id}`);
};

const getUserPrescriptionsName = () => {
  return callPrivateAPi.get("/api/getUserActivityName/");
};

const PrescriptionServices = {
  addPresCription,
  getUserPrescriptions,
  renameUserPrescription,
  deleteUserPrescription,
  editUserPrescription,
  getPrescriptionById,
  publicPrescriptions,
  sharePrescriptions,
  copyPublicPrescription,
  sharePrescription,
  getPrescriptionHistoryById,
  getSharePrescription,
  archivePrescription,
  searchUserPrescription,
  GetEngagementedActivityService,
  getUserPrescriptionsName,
};

export default PrescriptionServices;

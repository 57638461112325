import { callAPi, callPrivateAPi } from "./http-common";

const createNewProcess = (data) => {
  return callPrivateAPi.post("/api/createNewProcess", data);
};

const getUserProcesses = () => {
  return callPrivateAPi.get("api/getUserProcesses");
};
const getProcessesExperts = () => {
  return callPrivateAPi.get("/api/getProcessesExperts");
};
const getProcessById = (id) => {
  return callPrivateAPi.get(`/api/getProcess/${id}`);
};
const getEngagementsByProcess = (id) => {
  return callPrivateAPi.get(`/api/getEngagementsByProcess/${id}`);
};
const continueProcess = (id) => {
  return callPrivateAPi.get(`/api/getNextActivityOfProcess/${id}`);
};
const terminateProcess = (id) => {
  return callPrivateAPi.delete(`/api/terminateProcess/${id}`);
};
const ShowFullLogProcess = (id) => {
  return callPrivateAPi.get(`/api/processlog/full/${id}`);
};
const getAllUsersByProcess = () => {
  return callPrivateAPi.get("/api/getAllUsers");
};
const ShowUserLogProcess = (id) => {
  return callPrivateAPi.get(`/api/processlog/user/${id}`);
};
const updateProcessLog = (id, data) => {
  return callPrivateAPi.put(`/api/updateProcessLog/user/${id}`, data);
};
const assignUserToProcess = (data) => {
  return callPrivateAPi.put("/api/assignUserToProcess", data);
};
const ProcessServices = {
  createNewProcess,
  getUserProcesses,
  getProcessesExperts,
  getProcessById,
  continueProcess,
  terminateProcess,
  ShowFullLogProcess,
  ShowUserLogProcess,
  getAllUsersByProcess,
  assignUserToProcess,
  getEngagementsByProcess,
  updateProcessLog,
};

export default ProcessServices;

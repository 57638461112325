import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import UserServices from "../api/UserServices";

export const initialState = {
  data: [],
  activityData: [],
  loading: "idle",
  error: null,
  message: null,
};

export const getUsersData = createAsyncThunk("users/getUserData", async () => {
  try {
    const res = await UserServices.getUserData();
    if (res.data) {
      toast.success(res.data.message);
    }
    return res.data;
  } catch (error) {
    toast.error(error.response.message);
    return error.message;
  }
});

export const getUsersActivtyData = createAsyncThunk(
  "users/getUsersActivtyData",
  async () => {
    try {
      const res = await UserServices.userActivityData();
      if (res.data) {
        toast.success(res.data.message);
      }
      return res.data;
    } catch (error) {
      toast.error(error.response.message);
      return error.message;
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    handleLogout: (state) => {
      state.data = [];
      localStorage.clear();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUsersData.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(getUsersData.fulfilled, (state, action) => {
      state.loading = "idle";
      state.data = action.payload;
      state.message = action.payload.message;
    });
    builder.addCase(getUsersData.rejected, (state, action) => {
      state.loading = "rejected";
      state.message = action.payload.message;
    });

    builder.addCase(getUsersActivtyData.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(getUsersActivtyData.fulfilled, (state, action) => {
      state.loading = "idle";
      state.activityData = action.payload;
      state.message = action.payload.message;
    });
    builder.addCase(getUsersActivtyData.rejected, (state, action) => {
      state.loading = "rejected";
      state.message = action.payload.message;
    });
  },
});

export const { handleLogout } = userSlice.actions;

export default userSlice.reducer;

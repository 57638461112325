import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import CollaboratorsServices from "../api/CollaboratorsServices";

export const initialState = {
  data: [],
  loading: "idle",
  error: null,
  message: null,
};

export const getAllCollaborators = createAsyncThunk(
  "collaborators/getAllCollaborators",
  async () => {
    try {
      const res = await CollaboratorsServices.getAllCollaborators();
      if (res.data) {
        toast.success(res.data.message);
      }
      return res.data;
    } catch (error) {
      toast.error(error.response.message);
      return error.response.message;
    }
  }
);

export const getCollaboratorsEngagementById = createAsyncThunk(
  "collaborators/getCollaboratorsEngagementById",
  async (id) => {
    try {
      const res = await CollaboratorsServices.getCollaboratorsEngagementById(
        id
      );
      if (res.data) {
        toast.success(res.data.message);
      }
      return res.data;
    } catch (error) {
      toast.error(error.response.message);
      return error.response.message;
    }
  }
);

export const getCollaboratorActivitiesById = createAsyncThunk(
  "collaborators/getCollaboratorActivitiesById",
  async (id) => {
    try {
      const res = await CollaboratorsServices.getCollaboratorActivitiesById(id);
      if (res.data) {
        toast.success(res.data.message);
      }
      return res.data;
    } catch (error) {
      toast.error(error.response.message);
      return error.response.message;
    }
  }
);
export const getActivityEngagementsByCollaborator = createAsyncThunk(
  "collaborators/getActivityEngagementsByCollaborator",
  async (data) => {
    try {
      const res =
        await CollaboratorsServices.getActivityEngagementsByCollaborator(data);
      if (res.data) {
        toast.success(res.data.message);
      }
      return res.data;
    } catch (error) {
      toast.error(error.response.message);
      return error.response.message;
    }
  }
);

export const collaboratorsSlice = createSlice({
  name: "collaborators",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllCollaborators.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(getAllCollaborators.fulfilled, (state, action) => {
      state.loading = "idle";
      state.data = action.payload;
      state.message = action.payload.message;
    });
    builder.addCase(getAllCollaborators.rejected, (state, action) => {
      state.loading = "rejected";
      state.message = action.payload.message;
    });

    builder.addCase(getCollaboratorsEngagementById.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(
      getCollaboratorsEngagementById.fulfilled,
      (state, action) => {
        state.loading = "idle";
        state.data = action.payload;
        state.message = action.payload.message;
      }
    );
    builder.addCase(
      getCollaboratorsEngagementById.rejected,
      (state, action) => {
        state.loading = "rejected";
        state.message = action.payload.message;
      }
    );

    builder.addCase(getCollaboratorActivitiesById.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(
      getCollaboratorActivitiesById.fulfilled,
      (state, action) => {
        state.loading = "idle";
        state.data = action.payload;
        state.message = action.payload.message;
      }
    );
    builder.addCase(getCollaboratorActivitiesById.rejected, (state, action) => {
      state.loading = "rejected";
      state.message = action.payload.message;
    });

    builder.addCase(getActivityEngagementsByCollaborator.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(
      getActivityEngagementsByCollaborator.fulfilled,
      (state, action) => {
        state.loading = "idle";
        state.message = action.payload.message;
      }
    );
    builder.addCase(
      getActivityEngagementsByCollaborator.rejected,
      (state, action) => {
        state.loading = "rejected";
        state.message = action.payload.message;
      }
    );
  },
});

export default collaboratorsSlice.reducer;

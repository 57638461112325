import { Box } from "@mui/material";
import React from "react";
import SideMenu from "../../Component/HomeActivity/SideMenu/SideMenu";

const Layout = ({ children }) => {
  return (
    <Box
      height={"calc(100vh - 65px)"}
      position={"absolute"}
      display={"flex"}
      width={"100%"}
    >
      <Box
        width={"331px"}
        sx={{
          height: "calc(100vh - 65px)",
        }}
      >
        <SideMenu />
      </Box>
      <Box height={"calc(100vh - 65px)"} overflow={"auto"} width={"100%"}>
        {children}
      </Box>
    </Box>
  );
};

export default Layout;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import ProcessServices from "../api/ProcessServices";

export const initialState = {
  userProcess: [],
  expertProcess: [],
  loading: "idle",
  error: null,
  message: null,
};

export const createNewProcess = createAsyncThunk(
  "process/createNewProcess",
  async (data) => {
    try {
      const res = await ProcessServices.createNewProcess(data);
      if (res.data) {
        toast.success(res.data.message);
      }
      return res.data;
    } catch (error) {
      toast.error(error.response.message);
      return error.response.message;
    }
  }
);

export const getUserProcesses = createAsyncThunk(
  "process/getUserProcesses",
  async () => {
    try {
      const res = await ProcessServices.getUserProcesses();
      if (res.data) {
        toast.success(res.data.message);
      }
      return res.data;
    } catch (error) {
      toast.error(error.response.message);
      return error.response.message;
    }
  }
);

export const getProcessesExperts = createAsyncThunk(
  "process/getProcessesExperts",
  async () => {
    try {
      const res = await ProcessServices.getProcessesExperts();
      if (res.data) {
        toast.success(res.data.message);
      }
      return res.data;
    } catch (error) {
      toast.error(error.response.message);
      return error.response.message;
    }
  }
);

export const getProcessById = createAsyncThunk(
  "process/getProcessById",
  async (id) => {
    try {
      const res = await ProcessServices.getProcessById(id);
      if (res.data) {
        toast.success(res.data.message);
      }
      return res.data;
    } catch (error) {
      toast.error(error.response.message);
      return error.response.message;
    }
  }
);

export const continueProcessById = createAsyncThunk(
  "process/continueProcess",
  async (id) => {
    try {
      const res = await ProcessServices.continueProcess(id);
      if (res.data) {
        toast.success(res.data.message);
      }
      return res.data;
    } catch (error) {
      toast.error(error.response.message);
      return error.response.message;
    }
  }
);

export const terminateProcessById = createAsyncThunk(
  "process/terminateProcess",
  async (id) => {
    try {
      const res = await ProcessServices.terminateProcess(id);
      if (res.data) {
        toast.success(res.data.message);
      }
      return res.data;
    } catch (error) {
      toast.error(error.response.message);
      return error.response.message;
    }
  }
);

export const showUserLogProcess = createAsyncThunk(
  "process/showUserLogProcess",
  async (id) => {
    try {
      const res = await ProcessServices.ShowUserLogProcess(id);
      if (res.data) {
        toast.success(res.data.message);
      }
      return res.data;
    } catch (error) {
      toast.error(error.response.message);
      return error.response.message;
    }
  }
);
export const showFullLogProcess = createAsyncThunk(
  "process/showFullLogProcess",
  async (id) => {
    try {
      const res = await ProcessServices.ShowFullLogProcess(id);
      if (res.data) {
        toast.success(res.data.message);
      }
      return res.data;
    } catch (error) {
      toast.error(error.response.message);
      return error.response.message;
    }
  }
);
export const getAllUsersByProcess = createAsyncThunk(
  "process/getAllUsersByProcess",
  async (id) => {
    try {
      const res = await ProcessServices.getAllUsersByProcess(id);
      if (res.data) {
        toast.success(res.data.message);
      }
      return res.data;
    } catch (error) {
      toast.error(error.response.message);
      return error.response.message;
    }
  }
);

export const getEngagementsByProcess = createAsyncThunk(
  "process/getEngagementsByProcess",
  async (id) => {
    try {
      const res = await ProcessServices.getEngagementsByProcess(id);
      if (res.data) {
        toast.success(res.data.message);
      }
      return res.data;
    } catch (error) {
      toast.error(error.response.message);
      return error.response.message;
    }
  }
);

export const assignUserToProcess = createAsyncThunk(
  "process/assignUserToProcess",
  async (data) => {
    try {
      const res = await ProcessServices.assignUserToProcess(data);
      if (res.data) {
        toast.success(res.data.message);
      }
      return res.data;
    } catch (error) {
      toast.error(error.response.message);
      return error.response.message;
    }
  }
);

export const updateLogProcess = createAsyncThunk(
  "process/updateLogProcess",
  async (datas) => {
    const { id, data } = datas;
    try {
      const res = await ProcessServices.updateProcessLog(id, data);
      if (res.data) {
        toast.success(res.data.message);
      }
      return res.data;
    } catch (error) {
      toast.error(error.response.message);
      return error.response.message;
    }
  }
);

export const processSlice = createSlice({
  name: "process",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createNewProcess.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(createNewProcess.fulfilled, (state, action) => {
      state.loading = "idle";
      state.message = action.payload.message;
    });
    builder.addCase(createNewProcess.rejected, (state, action) => {
      state.loading = "rejected";
      state.message = action.payload.message;
    });

    builder.addCase(getUserProcesses.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(getUserProcesses.fulfilled, (state, action) => {
      state.loading = "idle";
      state.userProcess = action.payload;
      state.message = action.payload.message;
    });
    builder.addCase(getUserProcesses.rejected, (state, action) => {
      state.loading = "rejected";
      state.message = action.payload.message;
    });

    builder.addCase(getProcessesExperts.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(getProcessesExperts.fulfilled, (state, action) => {
      state.loading = "idle";
      state.expertProcess = action.payload;
      state.message = action.payload.message;
    });
    builder.addCase(getProcessesExperts.rejected, (state, action) => {
      state.loading = "rejected";
      state.message = action.payload.message;
    });

    builder.addCase(getProcessById.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(getProcessById.fulfilled, (state, action) => {
      state.loading = "idle";
      // state.expertProcess = action.payload
      state.message = action.payload.message;
    });
    builder.addCase(getProcessById.rejected, (state, action) => {
      state.loading = "rejected";
      state.message = action.payload.message;
    });

    builder.addCase(continueProcessById.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(continueProcessById.fulfilled, (state, action) => {
      state.loading = "idle";
      state.message = action.payload.message;
    });
    builder.addCase(continueProcessById.rejected, (state, action) => {
      state.loading = "rejected";
      state.message = action.payload.message;
    });

    builder.addCase(terminateProcessById.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(terminateProcessById.fulfilled, (state, action) => {
      state.loading = "idle";
      state.message = action.payload.message;
    });
    builder.addCase(terminateProcessById.rejected, (state, action) => {
      state.loading = "rejected";
      state.message = action.payload.message;
    });

    builder.addCase(showFullLogProcess.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(showFullLogProcess.fulfilled, (state, action) => {
      state.loading = "idle";
      state.message = action.payload.message;
    });
    builder.addCase(showFullLogProcess.rejected, (state, action) => {
      state.loading = "rejected";
      state.message = action.payload.message;
    });

    builder.addCase(showUserLogProcess.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(showUserLogProcess.fulfilled, (state, action) => {
      state.loading = "idle";
      state.message = action.payload;
    });
    builder.addCase(showUserLogProcess.rejected, (state, action) => {
      state.loading = "rejected";
      state.message = action.payload;
    });

    builder.addCase(getAllUsersByProcess.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(getAllUsersByProcess.fulfilled, (state, action) => {
      state.loading = "idle";
      state.message = action.payload;
    });
    builder.addCase(getAllUsersByProcess.rejected, (state, action) => {
      state.loading = "rejected";
      state.message = action.payload;
    });

    builder.addCase(assignUserToProcess.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(assignUserToProcess.fulfilled, (state, action) => {
      state.loading = "idle";
      state.message = action.payload;
    });
    builder.addCase(assignUserToProcess.rejected, (state, action) => {
      state.loading = "rejected";
      state.message = action.payload;
    });

    builder.addCase(getEngagementsByProcess.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(getEngagementsByProcess.fulfilled, (state, action) => {
      state.loading = "idle";
      state.message = action.payload;
    });
    builder.addCase(getEngagementsByProcess.rejected, (state, action) => {
      state.loading = "rejected";
      state.message = action.payload;
    });

    builder.addCase(updateLogProcess.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(updateLogProcess.fulfilled, (state, action) => {
      state.loading = "idle";
      state.message = action.payload;
    });
    builder.addCase(updateLogProcess.rejected, (state, action) => {
      state.loading = "rejected";
      state.message = action.payload;
    });
  },
});

export default processSlice.reducer;

import {
  Box,
  Divider,
  TablePagination,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import CustomTable from "../../../Ui/Shared/Table/CustomTable";
import { useDispatch, useSelector } from "react-redux";
import { getAllCollaborators } from "../../../Redux/slice/collaborators";
import { Link } from "react-router-dom";
import i18next from "i18next";

const Collaborators = () => {
  const collaborators = useSelector((s) => s.collaborators?.data?.data);
  const [refetch, setRefetch] = useState(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredCollaborators, setFilteredCollaborators] = useState([]);

  const tableHead = [
    {
      id: 0,
      headTitle: t("name"),
    },
    {
      id: 1,
      headTitle: t("email"),
    },
    {
      id: 2,
      headTitle: t("last_active"),
    },
    {
      id: 3,
      headTitle: t("role"),
    },
    {
      id: 4,
      headTitle: t("Actions"),
    },
  ];

  const getAllCollaborator = async () => {
    try {
      const res = await dispatch(getAllCollaborators());
    } catch (error) {}
  };

  useEffect(() => {
    getAllCollaborator();
  }, [dispatch]);

  useEffect(() => {
    if (searchQuery.trim()) {
      if (collaborators) {
        const filteredData = collaborators.filter((collaborator) =>
          collaborator.fullName
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
        );
        setFilteredCollaborators(filteredData);
      }
    } else {
      setFilteredCollaborators(collaborators);
    }
  }, [collaborators, searchQuery]);

  useEffect(() => {
    if (localStorage.getItem("collaboratorPrivate")) {
      const privateModeData = collaborators?.filter((item) => {
        return (
          item._id.toString() ===
          localStorage.getItem("collaboratorPrivate").toString()
        );
      });
      setFilteredCollaborators(privateModeData);
      setRefetch(true);
    }
  }, [collaborators, refetch]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <>
      {localStorage.getItem("access_token") ? (
        <Box>
          <Box>
            <Typography fontSize={"22px"} fontWeight={500} padding={2}>
              {t("collaborators")}
            </Typography>
            <Divider />
          </Box>
          <Box paddingX={4} paddingY={1}>
            {localStorage.getItem("collaboratorPrivate") ? null : (
              <TextField
                name="search"
                label={t("search")}
                value={searchQuery}
                onChange={handleSearchChange}
              />
            )}
          </Box>
          <Box paddingX={4} paddingY={1}>
            <CustomTable
              tableHead={tableHead}
              tableBody={filteredCollaborators}
            />
          </Box>
        </Box>
      ) : (
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          height={"calc(100vh - 65px)"}
        >
          <Typography>
            Please{" "}
            <Link to={"/login"}>
              <span
                style={{
                  color: "#175384",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
              >
                Sign In
              </span>
            </Link>{" "}
            for activities
          </Typography>
        </Box>
      )}
    </>
  );
};

export default Collaborators;

import { Box, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";

const ActivityBuilder = ({
  interactions,
  setInteractions,
  selectedButtons,
  setSelectedButtons,
  interactionsButton,
}) => {
  const [focusedItem, setFocusedItem] = useState(null);

  const handleButtonClick = (e) => {
    const btnId = e.target.dataset.id;
    // Check if the button ID is one of the special IDs
    if (
      btnId === "timer" ||
      btnId === "progressIndicator" ||
      btnId === "continuationOption" ||
      btnId === "guidanceText"
    ) {
      // Check if the button ID is already in the selectedButtons array
      if (!selectedButtons.some((button) => button.btnId === btnId)) {
        setSelectedButtons([
          ...selectedButtons,
          { id: uuidv4(), btnId: btnId },
        ]);
        setInteractions({
          ...interactions,
          [btnId]: true,
        });
      }
    } else {
      // Handle other button IDs
      if (Object.keys(interactions).includes(btnId)) {
        setSelectedButtons([
          ...selectedButtons,
          { id: uuidv4(), btnId: btnId },
        ]);
        setInteractions({
          ...interactions,
          [btnId]: true,
        });
      }
    }
  };

  return (
    <Stack
      sx={{
        width: "315px",
      }}
    >
      <Stack>
        <Typography
          sx={{
            paddingY: "24px",
            fontSize: "16px",
            fontWeight: 500,
            textAlign: "center",
            color: "#212427",
            backgroundColor: "white",
            boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.2)",
          }}
        >
          Activity Components
        </Typography>
        <Box display={"flex"} width={"100%"} justifyContent={"center"}>
          <Typography
            sx={{
              width: "189px",
              borderBottom: "3px solid rgba(0, 153, 255, 0.75)",
            }}
          ></Typography>
        </Box>
      </Stack>
      <Stack spacing={"4px"} mt={"22px"}>
        {interactionsButton.map((item) => (
          <Stack px={"34px"} key={item.id}>
            <Stack
              direction={"row"}
              alignItems={"center"}
              spacing={"12px"}
              paddingX={"12px"}
              paddingY={"10px"}
              className={`interaction ${
                focusedItem === item.id ? "focused" : ""
              }`}
              sx={{
                "&.focused": {
                  backgroundColor: "white",
                  border: "1px solid rgba(0, 153, 255, 0.75)",
                  borderRadius: "2px",
                  filter:
                    "drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.2)) drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.12)) drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.14))",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "22px",
                  height: "22px",
                  padding: "8px",
                  backgroundColor: "gray.100",
                  borderRadius: "2px",
                }}
              >
                <Typography
                  fontSize={"14px"}
                  fontWeight={400}
                  color={"#475467"}
                >
                  {item.icon}
                </Typography>
              </Box>
              <Stack>
                <Typography
                  component={"button"}
                  onClick={handleButtonClick}
                  data-id={item.id}
                  sx={{
                    backgroundColor: "transparent",
                    padding: 0,
                    cursor: "pointer",
                    textAlign: "start",
                  }}
                  fontSize={"14px"}
                  fontWeight={400}
                  color={"#060708"}
                  onFocus={() => setFocusedItem(item.id)}
                  onBlur={() => setFocusedItem(null)}
                >
                  {item.name}
                </Typography>
                <Typography
                  fontSize={"12px"}
                  fontWeight={400}
                  color={"#475467"}
                >
                  {item.desc}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

export default ActivityBuilder;

import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useRetryFetch from "../../hooks/useRetryFetch";
import { useTranslation } from "react-i18next";
import { dia, shapes, ui, setTheme, V, mvc } from "@clientio/rappid";
import { directionService } from "../../services/directionService";
import JointHelper from "../joint/jointHelper";
import Backbone from "backbone";
import $ from "jquery";
import _ from "lodash";
import loadingImage from "../../images/loading.gif";

import "./DesignView.css";
import MessageBox from "../MessageBox/MessageBox";
import { useEffectOnce } from "../../hooks/useEffectOnce";
import { config } from "../../Utils/Environment";

import useAuth from "../../hooks/useAuth";
import { useDispatch } from "react-redux";
import {
  createNewPrescription,
  editPrescriptions,
  getPrescriptionsById,
} from "../../Redux/slice/prescriptions";
import DesignMenus from "./DesignMenus";

const DesignView = React.forwardRef((props, ref) => {
  const {
    activityTime,
    setActivityData,
    activityData,
    saveActivity,
    setSaveActivity,
    activityName,
    setGraphStateClick,
    show,
    setRefetch,
    refetch,
    setRemoveGraphStateClick,
    removegraphStateClick,
    setFullActivity,
  } = props;
  const dispatch = useDispatch();
  const canvas = useRef();
  const graphStateRef = useRef();
  const unsavedChangesRef = useRef();
  const retryFetch = useRetryFetch();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { t } = useTranslation();

  const [graphState, setGraphState] = useState(null);
  const [paperState, setPaperState] = useState(null);

  const [paperScrollerState, setPaperScrollerState] = useState(null);

  const [presName, setPresName] = useState(activityName);
  const [newPresName, setNewPresName] = useState("");
  const [presOwner, setPresOwner] = useState("");
  const [currentPrescriptionId, setCurrentPrescriptionId] = useState("");
  const [saveIntent, setSaveIntent] = useState(false);
  const [saveIntentAssignMessage, setSaveIntentAssignMessage] = useState(false);
  const [saveBeforeNewIntent, setSaveBeforeNewIntent] = useState("");
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  const [showMessageBox, setShowMessageBox] = useState(false);
  const [msgBoxTitle, setMsgBoxTitle] = useState("");
  const [msgBoxButtons, setMsgBoxButtons] = useState({});
  const [msgBoxInputs, setMsgBoxInputs] = useState([]);
  const [msgBoxId, setMsgBoxId] = useState("");
  const [msgBoxMoreJSX, setMsgBoxMoreJSX] = useState(null);
  const [linkId, setLinkId] = useState();

  const [isLoading, setLoading] = useState(false);

  const openMessageBox = (title, id, buttons, inputs, moreJSX) => {
    setMsgBoxTitle(title);
    setMsgBoxId(id);
    setMsgBoxButtons(buttons);
    setMsgBoxInputs(inputs);
    setMsgBoxMoreJSX(moreJSX);
    setShowMessageBox(true);
  };

  useEffect(() => {
    graphStateRef.current = graphState;
  }, [graphState]);

  React.useImperativeHandle(ref, () => ({
    handleSubmit(setAutomations) {},
  }));

  useEffect(() => {
    if (saveIntent) {
      setSaveIntent(false);
      // call save
      callMenu("save");
    }
  }, [saveIntent]);

  useEffect(() => {
    if (saveBeforeNewIntent) {
      // call save
      callMenu("save");

      // new
      localStorage.removeItem("prescriptionID");
      setPresName(saveBeforeNewIntent);

      graphState.clear();
      setSaveBeforeNewIntent("");
    }
  }, [saveBeforeNewIntent]);

  const generateDiagram = (gS, dir) => {
    if (!gS) gS = graphState;

    let rtlMarker = dir || (document.body.dir === "rtl" ? "rtl" : "ltr");

    let diagram = {
      version: 1,
      cells: [],
      direction: rtlMarker,
    };

    for (let cell of gS.getCells()) {
      let newCell = {};
      if (cell.attributes.type === "qad.Question") {
        newCell.id = cell.attributes.id;
        newCell.data = {
          richText: cell.attributes.data?.richText,
          checkboxes: cell.attributes.data?.checkboxes,
          listItems: cell.attributes.data?.listItems,
          activityTitle: cell.attributes.data?.activityTitle,
          progressIndicator: cell.attributes.data?.progressIndicator,
          guidanceText: cell.attributes.data?.guidanceText,
          textBox: cell.attributes.data?.textBox,
          activityId: cell.attributes.data?.activityId,
          continuation: cell.attributes.data?.continuation,
          timerActivity: cell.attributes.data?.timerActivity,
          updateKey: cell.attributes.data?.updateKey,
        };
        newCell.position = cell.attributes.position;
        newCell.type = "Activity";
        newCell.title = cell.attributes.question;
        newCell.options = cell.attributes.options;
        newCell.questionRichText = cell.attributes.questionRichText;
        newCell.ports = cell.attributes.ports.items;
      } else {
        // link
        newCell.type = "Link";
        newCell.source = {
          activityId: cell.attributes.source.id,
          portId: cell.attributes.source.port,
        };
        newCell.target = {
          activityId: cell.attributes.target.id,
          portId: cell.attributes.target.port,
        };
      }
      diagram.cells.push(newCell);
    }
    return diagram;
  };

  const saveDraft = (gS, dir) => {
    let diagram = JSON.stringify(generateDiagram(gS, dir));
    localStorage.setItem("draftprescription", diagram);
  };

  useEffect(() => {
    // code to run when component mounts

    // cleanup function to run when component unmounts
    return () => {
      // code to run when component unmounts
      if (graphStateRef.current) {
        // are there any unsaved changes? Keep them!
        saveDraft(
          graphStateRef.current,
          document.body.dir === "rtl" ? "ltr" : "rtl"
        );
        setGraphStateClick();
        setActivityData();

        localStorage.removeItem("activityData");
        // will be called everytime the direction changes RTL<->LTR
      }
    };
  }, []);

  const compareArrays = (arr1, arr2) => {
    const result = [];

    for (let i = 0; i < arr1?.length; i++) {
      const item1 = arr1[i];
      if (item1.group === "in") {
        // add item from arr1 where group is 'in' to the result array
        result.push(item1);
      } else {
        // find item in arr2 where id matches and add to result array
        const resItem = arr2.find((item2) => item1.id === item2.id);
        if (resItem) {
          // create a new object with the `group` and `args` properties from
          // the item in `data`, and the `id` and `text` properties from the
          // matching item in `arr2`, and add it to the `result` array
          result.push({
            group: item1.group,
            id: resItem.id,
            args: item1.args,
          });
        }
      }
    }

    return result;
  };
  const removeDraft = () => {
    localStorage.removeItem("draftprescription");
  };

  const callMenu = (menuid) => {
    const pseudoEvent = {
      target: {
        dataset: {
          id: menuid,
        },
      },
    };
    onMenuClick(pseudoEvent);
  };

  useEffect(() => {
    let diagram = JSON.parse(localStorage.getItem("draftprescription"));
    setGraphState(diagram);
  }, []);

  const updateCell = (activityData) => {
    // Find the cell with the given attributes that uniquely identify it.
    const cellToUpdate = graphState
      ?.getCells()
      .find((cell) => cell.get("data")?.updateKey === activityData.updateKey);

    if (!cellToUpdate) {
      return null; // Return null or handle the case when the cell is not found.
    }

    // Update the cell attributes.

    cellToUpdate?.set("data", {
      richText: activityData.richText,
      checkboxes: activityData.checkboxes,
      listItems: activityData.listItems,
      activityTitle: activityData.activityTitle,
      progressIndicator: activityData.progressIndicator,
      guidanceText: activityData.guidanceText,
      textBox: activityData.textBox,
      continuation: activityData.continuation,
      timerActivity: activityData.timerActivity,
      updateKey: activityData.updateKey,
    });
    cellToUpdate?.set("options", activityData.options);
    cellToUpdate?.set("questionRichText", activityData?.question);
    cellToUpdate?.set(
      "question",
      activityData?.question?.replace(/<[^>]*>/g, "")
    );

    const compare = compareArrays(
      cellToUpdate?.attributes?.ports?.items,
      activityData?.options
    );
    if (compare) {
      cellToUpdate?.set("ports", {
        groups: cellToUpdate?.attributes?.ports?.groups,
        items: compare,
      });
    }

    // Regenerate the diagram object with the updated cell.
    const updatedDiagram = generateDiagram(); // Replace this with your method to generate the diagram.

    // Return the updated diagram object.
    return updatedDiagram;
  };

  if (
    activityData &&
    activityData.id !== undefined &&
    activityData.id !== null &&
    graphState !== null
  ) {
    updateCell(activityData);
  }

  if (
    activityData &&
    activityData.id === undefined &&
    activityData.id !== null
  ) {
    const res = JointHelper.addQuestion(
      graphState,
      shapes,
      paperScrollerState,
      activityData
    );
    setActivityData();
  }

  const createActivity = async () => {
    try {
      let diagram = generateDiagram();

      let id = localStorage.getItem("prescriptionID");

      if (!id) {
        // create new prescription
        let presHistoryId;
        if (localStorage.getItem("prescriptionHistoryID")) {
          presHistoryId = localStorage.getItem("prescriptionHistoryID");
          localStorage.removeItem("prescriptionHistoryID");
        }
        let newPrescription = {
          name: activityName,
          diagramJSON: JSON.stringify(diagram),
          dirty: true,
          fiPrescriptionHistory: presHistoryId,
        };
        localStorage.removeItem("newPrescriptionName");
        let newResponse;
        const res = await dispatch(
          createNewPrescription(
            JSON.stringify({
              name: newPrescription.name,
              diagramJSON: newPrescription.diagramJSON,
              time: activityTime,
            })
          )
        );
        if (res.payload.success) {
          setUnsavedChanges(false);
          setCurrentPrescriptionId(res.payload.data._id);
          navigate("/home/activities");
        } else {
        }
      } else {
        setCurrentPrescriptionId(id);
        // update existing prescription
        const res = await dispatch(
          editPrescriptions({
            id: id,
            diagramJSON: JSON.stringify(diagram),
            activityName: activityName,
          })
        );
        if (res.payload.success) {
          localStorage.removeItem("prescriptionID", res.payload.data._id);
          setUnsavedChanges(false);
          navigate("/home/activities");
        } else {
        }
      }
    } catch (e) {}
  };
  useEffect(() => {
    if (saveActivity) {
      createActivity();
      setSaveActivity(false);
    }
  }, [saveActivity]);

  const onMenuClick = async (e) => {
    let selectedMenu = e.target.dataset.id;

    switch (selectedMenu) {
      case "add":
        JointHelper.addQuestion(graphState, shapes, paperScrollerState);
        break;
      case "new":
        if (unsavedChanges) {
          openMessageBox(
            t("new_prescription"),
            selectedMenu + "unsaved",
            [
              {
                id: "dontsave",
                text: t("dont_save"),
              },
              {
                id: "save",
                text: t("save"),
              },
              {
                id: "cancel",
                text: t("cancel"),
              },
            ],
            [
              {
                id: "newName",
                text: t("name") + ":",
              },
            ],
            <p>{t("msg_save_before_new")}</p>
          );
        } else {
          openMessageBox(
            t("new_prescription"),
            selectedMenu,
            [
              {
                id: "ok",
                text: t("ok"),
              },
              {
                id: "cancel",
                text: t("cancel"),
              },
            ],
            [
              {
                id: "newName",
                text: t("name") + ":",
              },
            ]
          );
        }
        break;
      case "saveas":
        if (!user.email) {
          openMessageBox(
            t("msg_login_required_for_save"),
            "savelogin",
            [{ id: "ok", text: t("ok") }],
            [],
            ""
          );
          break;
        }
        if (newPresName) {
          localStorage.removeItem("prescriptionID");
          setPresName(newPresName);
          setSaveIntent(true);
          setNewPresName("");
        } else {
          openMessageBox(
            t("save_prescription_as"),
            selectedMenu,
            [
              {
                id: "ok",
                text: t("ok"),
              },
              {
                id: "cancel",
                text: t("cancel"),
              },
            ],
            [
              {
                id: "newName",
                text: t("name") + ":",
              },
            ]
          );
        }
        break;
      case "save":
        if (!user.email) {
          saveDraft();
          openMessageBox(
            t("msg_login_required_for_save"),
            "savelogin",
            [{ id: "ok", text: t("ok") }],
            [],
            ""
          );
          break;
        }
        if (!presName) {
          callMenu("saveas");
          break;
        }

        if (presOwner && presOwner !== user.email) {
          openMessageBox(
            t("question_not_owner_save_copy"),
            "savecopy",
            [
              { id: "yes", text: t("yes") },
              { id: "no", text: t("no") },
            ],
            [],
            ""
          );
          break;
        }

        let finalMessage = "";

        try {
          let diagram = generateDiagram();

          let id = localStorage.getItem("prescriptionID");

          if (!id) {
            // create new prescription
            let presHistoryId;
            if (localStorage.getItem("prescriptionHistoryID")) {
              presHistoryId = localStorage.getItem("prescriptionHistoryID");
              localStorage.removeItem("prescriptionHistoryID");
            }
            let newPrescription = {
              name: presName,
              diagramJSON: JSON.stringify(diagram),
              dirty: true,
              fiPrescriptionHistory: presHistoryId,
            };
            localStorage.removeItem("newPrescriptionName");
            let newResponse;
            const res = await dispatch(
              createNewPrescription(
                JSON.stringify({
                  name: newPrescription.name,
                  diagramJSON: newPrescription.diagramJSON,
                })
              )
            );

            if (res.payload.success) {
              localStorage.setItem("prescriptionID", res.payload.data._id);
              finalMessage = t("msg_save_successful");
              setUnsavedChanges(false);
              setCurrentPrescriptionId(res.payload.data._id);
            } else {
              finalMessage = t("msg_error_save_prescription");
            }
          } else {
            setCurrentPrescriptionId(id);
            // update existing prescription
            dispatch(
              editPrescriptions({
                id: id,
                diagramJSON: JSON.stringify(diagram),
                activityName: activityName,
              })
            );
            let updateResponse = await retryFetch(
              `${config.API_BASE}/api/prescriptions/${id}`,
              {
                method: "PUT",
                headers: {
                  "Content-Type": "application/json",
                },
                credentials: "include",
                body: JSON.stringify(diagram),
              }
            );
          }
        } catch (e) {
          finalMessage = t("msg_error_save_prescription");
        }
        if (saveIntentAssignMessage) {
          setSaveIntentAssignMessage(false);

          if (finalMessage === t("msg_save_successful")) {
            openMessageBox(
              t("msg_process_assigned"),
              "processassigned",
              [{ id: "ok", text: t("ok") }],
              [],
              ""
            );
          } else {
            openMessageBox(
              t("msg_process_assigned_but_save_failed"),
              "processassignedsavefailed",
              [{ id: "ok", text: t("ok") }],
              [],
              ""
            );
          }
        } else {
          openMessageBox(
            finalMessage,
            selectedMenu,
            [
              {
                id: "ok",
                text: t("ok"),
              },
            ],
            []
          );
        }
        break;
      case "preview":
        let pid = localStorage.getItem("prescriptionID");
        if (!pid || unsavedChanges || (!user.email && !presName)) {
          saveDraft();
        }
        window.open("/preview", "_blank");
        break;
      case "run":
        if (currentPrescriptionId && !unsavedChanges) {
          // create a new process and start it
          let newResponse = await retryFetch(
            config.API_BASE + `/api/processes/${currentPrescriptionId}`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              credentials: "include",
              body: "",
            }
          );
          let result = await newResponse.json();

          localStorage.setItem("prescriptionID", currentPrescriptionId);
          localStorage.setItem("processID", result.processId);
          localStorage.setItem("nextActivity", result.nextActivity);
          window.open("/preview", "_blank");
        } else {
          openMessageBox(
            t("msg_save_before_run"),
            "mustsavebeforerun",
            [{ id: "ok", text: t("ok") }],
            [],
            ""
          );
        }
        break;
      case "share":
        if (!user.email) {
          openMessageBox(
            t("msg_login_required_for_share"),
            "savelogin",
            [{ id: "ok", text: t("ok") }],
            [],
            ""
          );
          break;
        }
        let presid = localStorage.getItem("prescriptionID");
        if (!presid || unsavedChanges) {
          openMessageBox(
            t("msg_save_before_share"),
            "mustsave",
            [{ id: "ok", text: t("ok") }],
            [],
            ""
          );
        } else {
          let response = await retryFetch(
            `${config.API_BASE}/api/deeplinks/${presid}`,
            {
              method: "POST",
            }
          );
          let newDeeplink = await response.json();
          if (window.isSecureContext) {
            navigator.clipboard.writeText(
              `${window.location.origin}/preview?id=${newDeeplink.id}`
            );
            openMessageBox(
              t("msg_link_copied_to_clipboard"),
              "linkcopied",
              [{ id: "ok", text: t("ok") }],
              [],
              ""
            );
          } else {
            openMessageBox(
              t("msg_error_clipboard_insecure"),
              "linknotcopied",
              [{ id: "ok", text: t("ok") }],
              [],
              ""
            );
          }
        }
        break;
      case "zoomin":
        paperScrollerState.zoom(0.2, { max: 4 });
        break;
      case "zoomout":
        paperScrollerState.zoom(-0.2, { min: 0.2 });
        break;
      case "chat":
        navigate("/chat");
        break;
      default: {
      }
    }
  };

  const initJoint = (unsavedGraph) => {
    localStorage.removeItem("processID");
    localStorage.removeItem("nextActivity");

    let isRTL = document.body.dir === "rtl";

    let { graph, paper, paperScroller } = JointHelper.createGraphPaperScroller(
      canvas,
      dia,
      shapes,
      ui,
      Backbone,
      mvc,
      V,
      _,
      $,
      window.RichTextEditor,
      activityData,
      true,
      t,
      isRTL
    );
    setGraphState(graph);
    setPaperState(paper);
    setPaperScrollerState(paperScroller);

    let qadQuestion = JointHelper.createQuestionModel(dia, V, _, isRTL);
    let qadQuestionView = JointHelper.createQuestionView(dia, V, _);

    Object.assign(shapes, {
      qad: {
        Question: qadQuestion,
        QuestionView: qadQuestionView,
      },
    });

    // load model from database
    const loadPrescription = async () => {
      let restString = "";
      if (localStorage.getItem("draftprescription")) {
        restString = localStorage.getItem("draftprescription");
        setPresName("");
      } else if (localStorage.getItem("prescriptionHistoryID")) {
        let historyId = localStorage.getItem("prescriptionHistoryID");

        setLoading(true);

        let responseHistory = await retryFetch(
          `${config.API_BASE}/api/fullprescriptionhistory/${historyId}`,
          {
            method: "GET",
            credentials: "include",
          }
        );

        if (responseHistory.ok) {
          let presResponse = await responseHistory.json();

          setPresName(presResponse.fiPrescription?.name);
          setPresOwner(presResponse.fiPrescription?.fiUser?.email);

          // remove images!
          restString = presResponse.diagramJSON;
          let lookahead = 0;
          while (restString.indexOf("<img", lookahead) !== -1) {
            let startPos = restString.indexOf("<img");
            let endPos = restString.indexOf(">", startPos) + 1;
            // remove only base64 encoded images!
            let imageString = restString.substring(startPos, endPos);
            if (imageString.indexOf("base64") !== -1)
              restString =
                restString.substring(0, startPos) +
                restString.substring(endPos);
            else {
              lookahead = endPos;
            }
          }
        }
      } else {
        let id = localStorage.getItem("prescriptionID");
        if (id) {
          setLoading(true);
          setCurrentPrescriptionId(id);
          const res = await dispatch(getPrescriptionsById(id));
          if (res.payload.success) {
            const pres = res.payload.data;
            setPresName(pres.name);
            setPresOwner(pres.fiUser?.email);

            // remove images!
            restString = pres.diagramJSON;
            let lookahead = 0;
            while (restString.indexOf("<img", lookahead) !== -1) {
              let startPos = restString.indexOf("<img");
              let endPos = restString.indexOf(">", startPos) + 1;
              // remove only base64 encoded images!
              let imageString = restString.substring(startPos, endPos);
              if (imageString.indexOf("base64") !== -1)
                restString =
                  restString.substring(0, startPos) +
                  restString.substring(endPos);
              else {
                lookahead = endPos;
              }
            }
            setFullActivity(JSON.parse(restString));
          }
        } else {
          setCurrentPrescriptionId("");
        }
      }

      if (restString) {
        let result = JSON.parse(restString);

        let mapOldNewId = {};

        if (!result.version) {
          // original version 0
          graph.fromJSON(result);
        } else {
          // newer version
          for (let cell of result.cells) {
            if (cell.type === "Activity") {
              let newActivity = new shapes.qad.Question({
                position: {
                  x:
                    (isRTL && result.direction === "ltr") ||
                    (!isRTL && result.direction === "rtl")
                      ? window.visualViewport.width - cell.position.x
                      : cell.position.x,
                  y: cell.position.y,
                },
                question: cell.title,
                options: cell.options,
              });

              if (cell.data) newActivity.prop("data", cell.data);
              newActivity.addTo(graph);

              // Directory for all IDs (Acitivities and ports)
              for (let i = 0; i < cell.ports.length; i++) {
                if (mapOldNewId[cell.id + "|" + cell.ports[i].id])
                  mapOldNewId[cell.id + "|" + cell.ports[i].id] =
                    newActivity.id +
                    "|" +
                    newActivity.attributes.ports.items[i].id;
              }
            } else {
              // link
              if (
                !mapOldNewId[
                  cell.source.activityId + "|" + cell.source.portId
                ] ||
                !mapOldNewId[cell.target.activityId + "|" + cell.target.portId]
              ) {
              } else {
                let newLink = new shapes.devs.Link({
                  source: {
                    id: mapOldNewId[
                      cell.source.activityId + "|" + cell.source.portId
                    ].split("|")[0],
                    port: mapOldNewId[
                      cell.source.activityId + "|" + cell.source.portId
                    ].split("|")[1],
                  },
                  target: {
                    id: mapOldNewId[
                      cell.target.activityId + "|" + cell.target.portId
                    ].split("|")[0],
                    port: mapOldNewId[
                      cell.target.activityId + "|" + cell.target.portId
                    ].split("|")[1],
                  },
                  attrs: {
                    ".marker-target": {
                      d: "M 10 0 L 0 5 L 10 10 z",
                      fill: "#6a6c8a",
                      stroke: "#6a6c8a",
                    },
                    ".connection": {
                      stroke: "#6a6c8a",
                      strokeWidth: 2,
                    },
                  },
                });
                newLink.addTo(graph);
              }
            }
          }
        }
      }

      paper.unfreeze();

      setTheme("modern");

      setLoading(false);

      if (isRTL) {
        paperScroller.el.style.direction = "ltr";
      }
      paperScroller.centerContent();

      // is user not-logged-in and did they just run a process? => Login in or register and then assign user to process
      if (localStorage.getItem("runProcessID")) {
        if (!user.email) {
          openMessageBox(
            t("question_login_register"),
            "loginregister",
            [
              { id: "yes", text: t("yes") },
              { id: "no", text: t("no") },
            ],
            [],
            ""
          );
        } else {
          let response = await retryFetch(
            `${config.API_BASE}/api/processes/setuser/${localStorage.getItem(
              "runProcessID"
            )}/${user.email}`,
            {
              method: "PUT",
              credentials: "include",
            }
          );
          if (response.ok) {
            setPresName((prevName) => "Copy of " + prevName);
            setPresOwner(user.email);
            localStorage.removeItem("prescriptionID");
            setSaveIntent(true);
            setSaveIntentAssignMessage(true);
            localStorage.removeItem("runProcessID");
          }
        }
      }
    };

    loadPrescription();
  };

  useEffectOnce(() => {
    initJoint();

    let directionUnsubscribe = directionService
      .getDirectionChange()
      .subscribe(() => {
        if (graphStateRef.current) {
          // are there any unsaved changes? Keep them!
          if (unsavedChangesRef.current)
            saveDraft(
              graphStateRef.current,
              document.body.dir === "rtl" ? "ltr" : "rtl"
            );

          // will be called everytime the direction changes RTL<->LTR
          canvas.current.innerHTML = "";
          setGraphState(null);
          setPaperScrollerState(null);
          initJoint();
        }
      });

    return () => {
      directionUnsubscribe.unsubscribe();
    };
  }, []);

  useEffect(() => {
    // detect changes on the graph
    let cancelTimeout = null;
    if (graphState) {
      graphState.on("remove", (cell) => {
        setRemoveGraphStateClick(!removegraphStateClick);
        setGraphStateClick();
      });
      graphState.on("change", (cell) => {
        setUnsavedChanges(true);
        if (!user?.email) {
          if (cancelTimeout) {
            clearTimeout(cancelTimeout);
            cancelTimeout = null;
          }
          cancelTimeout = setTimeout(() => {
            saveDraft();
          }, 2000);
        }
      });
      paperState.on("cell:pointerclick", (cellView) => {
        if (cellView.model.attributes.type === "devs.Link") {
          setLinkId(cellView.id);
        }

        if (cellView.model.attributes.type === "qad.Question") {
          setGraphStateClick({
            id: cellView.model.id,
            data: cellView.model.attributes.data,
            activityName: cellView.model.attributes.question,
            options: cellView.model.attributes.options,
          });
          localStorage.setItem(
            "cell",
            JSON.stringify({
              id: cellView.model.id,
              data: cellView.model.attributes.data,
            })
          );
          setRefetch(!refetch);
        }
      });
    }
  }, [graphState, paperState]);

  const onButton = (result) => {
    if (result.btnId === "ok") {
      switch (result.msgBoxId) {
        case "new":
          if (result.data) {
            localStorage.removeItem("prescriptionID");
            setPresName(result.data.newName);
          }
          break;
        case "saveas":
          if (result.data) {
            localStorage.removeItem("prescriptionID");
            setPresName(result.data.newName);

            setSaveIntent(true);
          }
          break;
        default: {
        }
      }
    } else if (result.btnId === "dontsave") {
      if (result.data) {
        localStorage.removeItem("prescriptionID");
        setPresName(result.data.newName);
        setUnsavedChanges(false);
      }
    } else if (result.btnId === "save") {
      // save before creating new prescription
      if (result.data) {
        setSaveBeforeNewIntent(result.data.newName);
      } else {
        setSaveIntent(true);
      }
      setUnsavedChanges(false);
    } else if (result.btnId === "yes") {
      if (result.msgBoxId === "savecopy") {
        setPresName((prevName) => "Copy of " + prevName);
        setPresOwner(user.email);
        localStorage.removeItem("prescriptionID");
        setSaveIntent(true);
      } else {
        // login/register and come back to DesignView afterwards!
        navigate("/login", { state: { from: { pathname: "/design" } } });
      }
    } else if (result.btnId === "no") {
      if ((result.msgBoxId = "loginregister")) {
        localStorage.removeItem("runProcessID");
        localStorage.removeItem("prescriptionHistoryID");
      }
    }
  };

  return (
    <div ref={ref} className="flexArea">
      <div className="canvas" ref={canvas} />
      {showMessageBox ? (
        <MessageBox
          onButton={onButton}
          buttons={msgBoxButtons}
          text={msgBoxTitle}
          inputs={msgBoxInputs}
          id={msgBoxId}
          moreJSX={msgBoxMoreJSX}
          onClose={() => setShowMessageBox(false)}
        />
      ) : (
        ""
      )}
      {isLoading ? (
        <div className="loadingcenter">
          <img src={loadingImage} alt="" />
        </div>
      ) : (
        ""
      )}
      <DesignMenus
        onClick={onMenuClick}
        presName={presName}
        newPresName={newPresName}
        setNewPresName={setNewPresName}
      />
    </div>
  );
});

export default DesignView;

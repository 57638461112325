import React, { useEffect, useState } from "react";
import CustomizedDialogs from "../../../Ui/Shared/Modal/Modal";
import { Box, Divider, Popover, TextField, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useTranslation } from "react-i18next";
import CardActivity from "../Card/CardActivity";
import MenuOpenOutlinedIcon from "@mui/icons-material/MenuOpenOutlined";
import ReadOnlyView from "../../ReadOnlyView/ReadOnlyView";
import DesignView from "../../DesignView/DesignView";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  getPrescriptionHistoryById,
  getPrescriptionsById,
} from "../../../Redux/slice/prescriptions";
import { Link } from "react-router-dom";
import Timer from "../Card/Timer";

const CreateActivities = () => {
  const [open, setOpen] = useState(true);
  const [show, setShow] = useState(false);
  const [addActivty, setAddActivity] = useState(false);
  const [saveActivity, setSaveActivity] = useState(false);
  const [activityData, setActivityData] = useState();
  const dispatch = useDispatch();
  const [activityName, setActivityName] = useState("");
  const [graphStateClick, setGraphStateClick] = useState();
  const [removegraphStateClick, setRemoveGraphStateClick] = useState(false);
  const [fullActivity, setFullActivity] = useState();
  const [targetActivity, setTargetActivity] = useState();
  const [anchorElConnectOptions, setAnchorElConnectOptions] =
    React.useState(null);
  const [inputTime, setInputTime] = useState({
    seconds: 0,
    minutes: 0,
    hours: 0,
  });
  const [activityTime, setActivityTime] = useState();

  const [refetch, setRefetch] = useState(false);

  const { t } = useTranslation();

  const id = localStorage.getItem("prescriptionID");
  const getActivityById = async () => {
    if (id) {
      const res = await dispatch(getPrescriptionsById(id));
      if (res.payload.data) {
        setActivityName(res.payload.data.name);
      }
    }
  };

  useEffect(() => {
    localStorage.removeItem("activityData");
  }, []);

  useEffect(() => {
    if (show === true) {
      setActivityData(JSON.parse(localStorage.getItem("activityData")));
    }
  }, [show]);

  const handlePoperConnectOptions = (event, id) => {
    setAnchorElConnectOptions(event.currentTarget);
  };
  const handleCloseConnectOptions = () => {
    setAnchorElConnectOptions(null);
  };
  const openConnectOptions = Boolean(anchorElConnectOptions);
  const idConnectOptions = openConnectOptions ? "simple-popover" : undefined;

  useEffect(() => {
    getActivityById();
  }, [id]);
  return (
    <>
      {localStorage.getItem("access_token") ? (
        <Box width={"100%"}>
          <CustomizedDialogs title="Activity" open={open} setOpen={setOpen}>
            <TextField
              id="outlined-basic"
              label="Activity Name"
              value={activityName}
              name="activityName"
              fullWidth
              variant="filled"
              onChange={(e) => setActivityName(e.target.value)}
            />
          </CustomizedDialogs>
          <Box>
            <Box paddingX={2} display={"flex"} justifyContent={"space-between"}>
              <Box display="flex" alignItems={"center"}>
                <Typography fontSize={"18px"} fontWeight={500} padding={2}>
                  I am creating a
                </Typography>
                <TextField
                  sx={{ border: "none", "& fieldset": { border: "none" } }}
                  name="activity"
                  value={activityName}
                  onChange={(e) => setActivityName(e.target.value)}
                />
              </Box>
              <Box display={"flex"} alignItems={"center"} gap={2}>
                <Box display={"flex"}></Box>
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  border={"1px solid"}
                  borderRadius={"4px"}
                  paddingX={4}
                  sx={{
                    cursor: "pointer",
                  }}
                  component={"button"}
                  onClick={() => {
                    if (activityName === "") {
                      toast.warning("Activity Name Should not be empty");
                      setOpen(true);
                    } else if (activityName) {
                      setSaveActivity(true);
                    }
                  }}
                >
                  <Typography>{t("save_activity")}</Typography>
                </Box>
              </Box>
            </Box>
            <Divider />

            <Box gap={1} display={"flex"}>
              <Box
                width={"50%"}
                height={`calc(89vh - 65px)`}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                flexDirection={"column"}
                gap={2}
              >
                <Box
                  width={"80%"}
                  display="flex"
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Typography
                    aria-describedby={idConnectOptions}
                    variant="contained"
                    onClick={handlePoperConnectOptions}
                    component={"button"}
                  >
                    Add Time for master activity
                  </Typography>
                  <Popover
                    elevation={1}
                    id={idConnectOptions}
                    open={openConnectOptions}
                    anchorEl={anchorElConnectOptions}
                    onClose={handleCloseConnectOptions}
                    sx={{
                      left: 70,
                    }}
                  >
                    <Timer
                      inputTime={inputTime}
                      setInputTime={setInputTime}
                      setActivityTime={setActivityTime}
                      activityTime={activityTime}
                      setAnchorElConnectOptions={setAnchorElConnectOptions}
                    />
                  </Popover>
                </Box>
                <CardActivity
                  refetch={refetch}
                  graphStateClick={graphStateClick}
                  setActivityData={setActivityData}
                  removegraphStateClick={removegraphStateClick}
                  fullActivity={fullActivity}
                  setTargetActivity={setTargetActivity}
                />
              </Box>
              <Divider orientation="vertical" />
              <Box width={"50%"} position={"relative"}>
                {show && (
                  <DesignView
                    show={show}
                    setAddActivity={setAddActivity}
                    activityData={activityData}
                    setActivityData={setActivityData}
                    addActivty={addActivty}
                    saveActivity={saveActivity}
                    setSaveActivity={setSaveActivity}
                    activityName={activityName}
                    setGraphStateClick={setGraphStateClick}
                    setRefetch={setRefetch}
                    refetch={refetch}
                    setRemoveGraphStateClick={setRemoveGraphStateClick}
                    removegraphStateClick={removegraphStateClick}
                    setFullActivity={setFullActivity}
                    targetActivity={targetActivity}
                    activityTime={activityTime}
                  />
                )}
                <Box
                  component={"button"}
                  position={"absolute"}
                  top={30}
                  right={30}
                  onClick={() => setShow(!show)}
                >
                  <MenuOpenOutlinedIcon fontSize="large" />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          height={"calc(100vh - 65px)"}
        >
          <Typography>
            Please{" "}
            <Link to={"/login"}>
              <span
                style={{
                  color: "#175384",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
              >
                Sign In
              </span>
            </Link>{" "}
            for activities
          </Typography>
        </Box>
      )}
    </>
  );
};

export default CreateActivities;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import RunProcessServices from "../api/RunProcessServices";

export const initialState = {
  data: [],
  loading: "idle",
  error: null,
  message: null,
};

export const createLogEntry = createAsyncThunk(
  "runProcess/createLogEntry",
  async (arg) => {
    try {
      const { processId, data } = arg;
      const res = await RunProcessServices.createLogEntry(processId, data);
      if (res.data) {
        toast.success(res.data.message);
      }
      return res.data;
    } catch (error) {
      toast.error(error.response.message);
      return error.response.message;
    }
  }
);

export const updateProcess = createAsyncThunk(
  "runProcess/updateProcess",
  async (arg) => {
    try {
      const { processId, data } = arg;
      const res = await RunProcessServices.updateProcess(processId, data);
      if (res.data) {
        toast.success(res.data.message);
      }
      return res.data;
    } catch (error) {
      toast.error(error.response.message);
      return error.response.message;
    }
  }
);

export const processSlice = createSlice({
  name: "runProcess",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createLogEntry.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(createLogEntry.fulfilled, (state, action) => {
      state.loading = "idle";
      state.message = action.payload.message;
    });
    builder.addCase(createLogEntry.rejected, (state, action) => {
      state.loading = "rejected";
      state.message = action.payload.message;
    });

    builder.addCase(updateProcess.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(updateProcess.fulfilled, (state, action) => {
      state.loading = "idle";
      state.message = action.payload.message;
    });
    builder.addCase(updateProcess.rejected, (state, action) => {
      state.loading = "rejected";
      state.message = action.payload.message;
    });
  },
});

export default processSlice.reducer;

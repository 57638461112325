import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Checkbox,
  FormHelperText,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CustomInputs, {
  PasswordField,
} from "../../Ui/Shared/Inputs/CustomInputs";
import CustomButton from "../../Ui/Shared/Buttons/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import {
  createUser,
  socialLogin,
  verifyEmail,
} from "../../Redux/slice/authentication";
import { signInWithFacebook, signInWithGoogle } from "../../firebase/firebase";
import GoogleLogo from "../../Assets/Google.svg";
import FacebookLogo from "../../Assets/facebook.svg";
const initialValues = {
  fullName: "",
  email: "",
  password: "",
};

function Register() {
  const auth = useSelector((s) => s.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [values, setValues] = useState(initialValues);
  const [otpOpen, setOtpOpen] = useState(false);
  const [otp, setOtp] = useState();
  const [errors, setErrors] = useState({});
  const [otpMessage, setOtpMessage] = useState();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    validations({ [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validations()) {
      if (!otpOpen) {
        dispatch(createUser(values));
      } else if (otpOpen) {
        const res = await dispatch(
          verifyEmail({
            email: auth?.data.data.email,
            userId: auth?.data.data._id,
            code: otp,
          })
        );
        if (!res.payload.success) {
          setOtpMessage(res.payload.message);
        } else if (res.payload.success) {
          setOtp("");
        }

        if (res.payload.access_token) {
          navigate("/login");
          setValues(initialValues);
        }
      }
    }
  };
  const validations = (fieldValue = values) => {
    let temp = { ...errors };
    if ("fullName" in fieldValue)
      temp.fullName = fieldValue.fullName ? "" : "This field requires";
    if ("email" in fieldValue)
      temp.email = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
        fieldValue.email
      )
        ? ""
        : "Email is not valid";
    if ("password" in fieldValue)
      temp.password =
        fieldValue.password.length > 6 ? "" : "Minimum 6 Characters Required";

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };
  useEffect(() => {
    setOtpOpen(auth?.data.success);
  }, [auth]);

  return (
    <Box
      height={"100%"}
      display="flex"
      justifyContent={"center"}
      alignItems={"center"}
      sx={{ backgroundColor: "#E5E5E5" }}
      width="100%"
    >
      <Box my={"100px"} display={"flex"} width="100%" flexDirection="column">
        <Box
          mx={"auto"}
          width="372px"
          height="100%"
          boxShadow={0.8}
          borderRadius={1}
          sx={{ backgroundColor: "white" }}
          padding={"32px"}
          component="form"
          onSubmit={handleSubmit}
        >
          <Box display={"flex"} gap={"16px"} flexDirection="column">
            <Typography
              textAlign={"center"}
              fontSize={"16px"}
              fontWeight={500}
              lineHeight={"19px"}
              color={"gray.600"}
              fontFamily={"Roboto"}
            >
              Sign up to Clarynext
            </Typography>

            <Box display={"flex"} flexDirection="column" gap={"16px"}>
              <CustomInputs
                inputLabel={"Full Name"}
                labelColor="gray.300"
                name="fullName"
                value={values.fullName}
                onChange={handleChange}
                error={errors.fullName}
              />
              <CustomInputs
                inputLabel={"Email"}
                labelColor="gray.300"
                name="email"
                value={values.email}
                onChange={handleChange}
                error={errors.email}
              />
              <PasswordField
                type="password"
                inputLabel={"Password"}
                labelColor="gray.300"
                name="password"
                value={values.password}
                onChange={handleChange}
                error={errors.password}
              />
              {otpOpen && (
                <Box display={"flex"} pt={"8px"} flexDirection="column" gap={1}>
                  <Typography
                    fontWeight={400}
                    fontFamily={"Roboto"}
                    lineHeight={"19px"}
                    color={"gray.400"}
                    fontSize="12px"
                  >
                    We have just send you a temporary sign up code. Please open
                    your inbox and paste the code below.{" "}
                  </Typography>
                  <CustomInputs
                    error={otpMessage}
                    type="text"
                    name="otp"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    placeholder="Enter Otp"
                  />
                </Box>
              )}
              <CustomButton
                type="submit"
                bgColor="#F5F5F5"
                color="gray.300"
                borderColor="#E5E5E5"
                fontSize="16px"
                variant={"gray"}
                fontFamily="Roboto"
                fontWeight={400}
                lineHeight="22px"
              >
                {otpOpen ? "Submit" : "Sign up"}
              </CustomButton>
              <Typography
                fontFamily={"Roboto"}
                color={"gray.400"}
                fontSize={"12px"}
              >
                By clicking “continue” , you agree to the Clarynext&nbsp;
                <Typography
                  fontFamily={"Roboto"}
                  fontSize={"12px"}
                  color={"primary.400"}
                  sx={{ cursor: "pointer" }}
                  component={"span"}
                  onClick={() => navigate("/terms")}
                >
                  Terms of service
                </Typography>
                &nbsp;and acknowledge the&nbsp;
                <Typography
                  fontFamily={"Roboto"}
                  fontSize={"12px"}
                  color={"primary.400"}
                  sx={{ cursor: "pointer" }}
                  onClick={() => navigate("/terms")}
                  component={"span"}
                >
                  Privacy Policy
                </Typography>
              </Typography>
              <Typography
                fontSize={"15px"}
                fontWeight={600}
                lineHeight={"24px"}
                fontFamily={"Manrope"}
                color={"gray.400"}
                textAlign={"center"}
              >
                Or
              </Typography>
              <Box
                sx={{
                  height: "1px",
                  backgroundColor: "#F0F0F0",
                }}
              ></Box>
              <Stack gap={"10px"}>
                <CustomButton
                  onClick={async () => {
                    const user = await signInWithGoogle();
                    if (user) {
                      const res = await dispatch(
                        socialLogin({
                          userData: user,
                          provider: "Google",
                        })
                      );
                      if (res.payload.access_token) {
                        localStorage.setItem(
                          "access_token",
                          res.payload.access_token
                        );
                        navigate("/home/activities");
                      }
                    }
                  }}
                  fontSize="14px"
                  variant={"socialButton"}
                  color="gray.500"
                  fontWeight={600}
                  type="button"
                >
                  <Box
                    width={"100%"}
                    display={"flex"}
                    gap={"8px"}
                    justifyContent="center"
                    alignItems={"center"}
                  >
                    <img
                      width={"18px"}
                      height={"18px"}
                      src={GoogleLogo}
                      alt=""
                    />
                    <Typography fontFamily={"Roboto"} textAlign={"center"}>
                      Continue with Google
                    </Typography>
                  </Box>
                </CustomButton>
                <CustomButton
                  fontSize="14px"
                  variant={"socialButton"}
                  color="gray.500"
                  fontWeight={600}
                  type="button"
                  onClick={signInWithFacebook}
                >
                  <Box
                    width={"100%"}
                    display={"flex"}
                    gap={"16px"}
                    justifyContent="center"
                    alignItems={"center"}
                  >
                    <img
                      width={"18px"}
                      height={"18px"}
                      src={FacebookLogo}
                      alt=""
                    />
                    <Typography fontFamily={"Roboto"} textAlign={"center"}>
                      Continue with Facebook
                    </Typography>
                  </Box>
                </CustomButton>
              </Stack>

              <Link
                style={{
                  textAlign: "center",
                  color: "#1F6FB0",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "24px",
                  fontFamily: "Roboto",
                  marginTop: "16px",
                  textDecoration: "underline",
                }}
                to={"/login"}
              >
                Already have an account? Sign in
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Register;

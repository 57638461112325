import { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { directionService } from "../../services/directionService";
import useRetryFetch from "../../hooks/useRetryFetch";
import { dia, shapes, ui, setTheme, V, mvc } from "@clientio/rappid";
import JointHelper from "../joint/jointHelper";
import Backbone from "backbone";
import $ from "jquery";
import _ from "lodash";
import loadingImage from "../../images/loading.gif";

import "./ReadOnlyView.css";
import { useEffectOnce } from "../../hooks/useEffectOnce";
import { config } from "../../Utils/Environment";

import useAuth from "../../hooks/useAuth";
import { useDispatch } from "react-redux";
import {
  getPrescriptionHistoryById,
  getPrescriptionsById,
} from "../../Redux/slice/prescriptions";

const ReadOnlyView = ({ presHistoryId, highlightId }) => {
  const canvas = useRef();
  const graphStateRef = useRef();
  const dispatch = useDispatch();
  const retryFetch = useRetryFetch();
  const { auth } = useAuth();

  const { t } = useTranslation();

  const [graphState, setGraphState] = useState(null);
  const [paperScrollerState, setPaperScrollerState] = useState(null);
  const [paperState, setPaperState] = useState(null);
  const [presHistId, setPresHistId] = useState("");
  const [mapping, setMapping] = useState({});

  const [isLoading, setLoading] = useState(false);

  const initJoint = () => {
    let isRTL = document.body.dir === "rtl";

    let { graph, paper, paperScroller } = JointHelper.createGraphPaperScroller(
      canvas,
      dia,
      shapes,
      ui,
      Backbone,
      mvc,
      V,
      _,
      $,
      window.RichTextEditor,
      false,
      t,
      isRTL
    );

    setGraphState(graph);
    setPaperState(paper);
    setPaperScrollerState(paperScroller);

    let qadQuestion = JointHelper.createQuestionModel(dia, V, _, isRTL);
    let qadQuestionView = JointHelper.createQuestionView(dia, V, _);

    Object.assign(shapes, {
      qad: {
        Question: qadQuestion,
        QuestionView: qadQuestionView,
      },
    });

    // load model from database
    const loadPrescription = async () => {
      let restString = "";
      let res;
      if (!!presHistoryId) {
        setLoading(true);
        res = await dispatch(getPrescriptionHistoryById(presHistoryId));
      } else {
        let id = localStorage.getItem("prescriptionID");
        setLoading(true);
        res = await dispatch(getPrescriptionsById(id));
      }
      let pres = res?.payload.data;

      // remove images!
      restString = pres.diagramJSON;
      let lookahead = 0;
      while (restString.indexOf("<img", lookahead) !== -1) {
        let startPos = restString.indexOf("<img");
        let endPos = restString.indexOf(">", startPos) + 1;
        // remove only base64 encoded images!
        let imageString = restString.substring(startPos, endPos);
        if (imageString.indexOf("base64") !== -1)
          restString =
            restString.substring(0, startPos) + restString.substring(endPos);
        else {
          lookahead = endPos;
        }
      }

      if (restString) {
        let result = JSON.parse(restString);

        let mapOldNewId = {};
        let cellMap = {};

        if (!result.version) {
          // original version 0
          graph.fromJSON(result);
        } else {
          // newer version
          for (let cell of result.cells) {
            if (cell.type === "Activity") {
              let newActivity = new shapes.qad.Question({
                position: {
                  x: isRTL
                    ? window.visualViewport.width - cell.position.x
                    : cell.position.x,
                  y: cell.position.y,
                },
                question: cell.title,
                options: cell.options,
              });

              if (cell.data) newActivity.prop("data", cell.data);
              newActivity.addTo(graph);

              cellMap[cell.id] = newActivity.id;

              // Directory for all IDs (Acitivities and ports)
              for (let i = 0; i < cell.ports.length; i++) {
                if (mapOldNewId[cell.id + "|" + cell.ports[i].id])
                  mapOldNewId[cell.id + "|" + cell.ports[i].id] =
                    newActivity.id +
                    "|" +
                    newActivity.attributes.ports.items[i].id;
              }
            } else {
              // link
              if (
                !mapOldNewId[
                  cell.source.activityId + "|" + cell.source.portId
                ] ||
                !mapOldNewId[cell.target.activityId + "|" + cell.target.portId]
              ) {
              } else {
                let newLink = new shapes.devs.Link({
                  source: {
                    id: mapOldNewId[
                      cell.source.activityId + "|" + cell.source.portId
                    ].split("|")[0],
                    port: mapOldNewId[
                      cell.source.activityId + "|" + cell.source.portId
                    ].split("|")[1],
                  },
                  target: {
                    id: mapOldNewId[
                      cell.target.activityId + "|" + cell.target.portId
                    ].split("|")[0],
                    port: mapOldNewId[
                      cell.target.activityId + "|" + cell.target.portId
                    ].split("|")[1],
                  },
                  attrs: {
                    ".marker-target": {
                      d: "M 10 0 L 0 5 L 10 10 z",
                      fill: "#6a6c8a",
                      stroke: "#6a6c8a",
                    },
                    ".connection": {
                      stroke: "#6a6c8a",
                      strokeWidth: 2,
                    },
                  },
                });
                newLink.addTo(graph);

                cellMap[cell.source.activityId + "|" + cell.source.portId] =
                  newLink.id;
              }
            }
          }
        }
        setMapping(cellMap);
      }

      paper.unfreeze();

      setTheme("modern");

      setLoading(false);

      paperScroller.zoom(-0.2);

      if (isRTL) {
        paperScroller.el.style.direction = "ltr";
      }
      paperScroller.centerContent();
    };

    loadPrescription();
  };

  useEffect(() => {
    if (graphState) {
    }
  }, [mapping]);

  useEffect(() => {
    if (graphState) {
    }
    graphStateRef.current = graphState;
  }, [graphState]);

  // is it used to view a prescription directly, or is it used to view a prescriptionHistory for a log review?
  useEffectOnce(() => {
    if (presHistoryId && !graphState) {
      initJoint();
    }
  }, [presHistoryId]);

  useEffect(() => {
    if (graphState && paperState) {
      let cell;
      let cellView;

      // unhighlight all
      for (let cell of graphState.getCells()) {
        cellView = paperState.findViewByModel(cell);
        cellView.unhighlight();
      }

      if (highlightId) {
        if (highlightId.indexOf("|") !== -1) {
          // highlight link
          if (mapping[highlightId]) {
            cell = graphState.getCell(mapping[highlightId]);
            cellView = paperState.findViewByModel(cell);

            cellView.highlight();
          }

          // get activity
          cell = graphState.getCell(mapping[highlightId.split("|")[0]]);
        } else {
          // only get activity
          cell = graphState.getCell(mapping[highlightId]);
        }

        cellView = paperState.findViewByModel(cell);
        cellView.highlight();
      }
    }
  }, [highlightId]);

  useEffectOnce(() => {
    if (localStorage.getItem("prescriptionID")) {
      initJoint();
    }
    let directionUnsubscribe = directionService
      .getDirectionChange()
      .subscribe(() => {
        if (graphStateRef.current) {
          // will be called everytime the direction changes RTL<->LTR
          canvas.current.innerHTML = "";
          setGraphState(null);
          setPaperScrollerState(null);
          initJoint();
        }
      });

    return () => {
      directionUnsubscribe.unsubscribe();
    };
  }, []);

  return (
    <div className="flexArea">
      <div className="viewcanvas" ref={canvas} />
      {isLoading ? (
        <div className="loadingcenter">
          <img src={loadingImage} alt="" />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default ReadOnlyView;

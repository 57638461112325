import "./messenger.css";
import { useEffect, useRef, useState } from "react";
import { io } from "socket.io-client";
import useAuth from "../../hooks/useAuth";
import Conversation from "../components/conversation/Conversation";
import Message from "../components/message/Message";
import ChatOnline from "../components/chatOnline/ChatOnline";
import { useDispatch, useSelector } from "react-redux";
import { getUserConversations } from "../../Redux/slice/chat";
import ChatServices from "../../Redux/api/ChatServices";
import { Box, Paper, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { config } from "../../Utils/Environment";

export default function Messenger() {
  const { t } = useTranslation();
  const [currentChat, setCurrentChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [arrivalMessage, setArrivalMessage] = useState(null);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [experts, setExperts] = useState([]);
  const [friend, setFriend] = useState({
    fullName: "",
  });

  const socket = useRef();
  const dispatch = useDispatch();
  const chat = useSelector((s) => s.chat?.data.data);
  const { auth, user } = useAuth();
  const scrollRef = useRef();

  useEffect(() => {
    socket.current = io(config.API_BASE);
    socket.current.on("getMessage", (data) => {
      setArrivalMessage({
        sender: data.senderId,
        text: data.text,
        createdAt: Date.now(),
      });
    });
  }, []);

  useEffect(() => {
    arrivalMessage &&
      currentChat?.members.includes(arrivalMessage.sender) &&
      setMessages((prev) => [...prev, arrivalMessage]);
  }, [arrivalMessage, currentChat]);

  useEffect(() => {
    socket.current.emit("addUser", user?._id);
    socket.current.on("getUsers", (users) => {
      setOnlineUsers(
        user.followings.filter((f) => users.some((u) => u.userId === f))
      );
    });
  }, [user]);

  useEffect(() => {
    dispatch(getUserConversations());
  }, [user?._id]);

  useEffect(() => {
    const getExperts = async () => {
      try {
        const res = await ChatServices.getExperts();
        setExperts(res.data.data);
      } catch (err) {}
    };
    getExperts();
  }, []);

  useEffect(() => {
    const friendId = currentChat?.members?.find(
      (member) => member !== user._id
    );

    const getFriendName = async () => {
      try {
        const res = await ChatServices.getUserInfo(friendId);
        setFriend(res.data.data);
      } catch (err) {}
    };
    const getMessages = async () => {
      try {
        const res = await ChatServices.getAllMessagesByConversationId(
          currentChat?._id
        );
        setMessages(res.data.data);
      } catch (err) {}
    };
    getMessages();
    getFriendName();
  }, [currentChat]);

  useEffect(() => {
    chat && chat[0] && setCurrentChat(chat[0]);
  }, [chat]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const receiverId = currentChat.members.find(
      (member) => member !== user._id
    );

    socket.current.emit("sendMessage", {
      senderId: user._id,
      receiverId,
      text: newMessage,
    });

    try {
      const res = await ChatServices.addMessages({
        text: newMessage,
        conversationId: currentChat?._id,
      });
      setMessages([...messages, res.data.data]);
      setNewMessage("");
    } catch (err) {}
  };

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return (
    <>
      {localStorage.getItem("access_token") ? (
        <div>
          <div className="messenger">
            <div className="chatMenu">
              <div className="chatMenuWrapper">
                <Typography fontSize={"18px"} fontWeight="bold" padding={2}>
                  {t("recent_chats")}
                </Typography>
                {chat?.map((c) => (
                  <div onClick={() => setCurrentChat(c)}>
                    <Conversation
                      key={c._id}
                      conversation={c}
                      currentUser={user}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="chatBox">
              <div className="chatBoxWrapper">
                {currentChat ? (
                  <>
                    {friend.fullName}
                    <div className="chatBoxTop">
                      {messages?.map((m) => (
                        <div ref={scrollRef}>
                          <Message
                            key={m._id}
                            message={m}
                            own={m.senderId === user?._id}
                          />
                        </div>
                      ))}
                    </div>
                    <div className="chatBoxBottom">
                      <textarea
                        className="chatMessageInput"
                        placeholder={t("write_something")}
                        onChange={(e) => setNewMessage(e.target.value)}
                        value={newMessage}
                      ></textarea>
                      <button
                        className="chatSubmitButton"
                        onClick={handleSubmit}
                      >
                        {t("send")}
                      </button>
                    </div>
                  </>
                ) : (
                  <span className="noConversationText">
                    {t("recent_chats")}
                  </span>
                )}
              </div>
            </div>
            <div className="chatOnline">
              <Typography fontSize={"18px"} fontWeight="bold" padding={2}>
                {t("experts")}
              </Typography>
              <div className="chatOnlineWrapper">
                <ChatOnline
                  experts={experts}
                  onlineUsers={onlineUsers}
                  currentId={user?._id}
                  setCurrentChat={setCurrentChat}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          height={"calc(100vh - 65px)"}
        >
          <Typography>
            Please{" "}
            <Link to={"/login"}>
              <span
                style={{
                  color: "#175384",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
              >
                Sign In
              </span>
            </Link>{" "}
            for activities
          </Typography>
        </Box>
      )}
    </>
  );
}

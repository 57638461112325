import "./Anchor.css";

function Anchor({ onClick, text }) {
  return (
    <button className="anchorButton" onClick={onClick}>
      {text}
    </button>
  );
}

export default Anchor;

import { Box } from "@mui/material";
import { useState } from "react";
import "./MessageBox.css";

function MessageBox({ onButton, buttons, text, inputs, onClose, id, moreJSX }) {
  const getDefaultValues = () => {
    let result = null;
    for (let input of inputs) {
      if (input.defaultValue) {
        if (!result) result = {};
        result[input.id] = input.defaultValue;
      }
    }
    return result;
  };

  const [formData, setFormData] = useState(getDefaultValues());

  const handleButton = (e) => {
    let btnId = e.target.dataset.id;
    let result = {
      btnId,
      msgBoxId: id,
      data: formData,
    };
    onButton(result);
    onClose();
  };

  const changeFormData = (e) => {
    setFormData((prevFormData) => {
      return { ...prevFormData, [e.target.id]: e.target.value };
    });
  };

  return (
    <Box className="overlay">
      <Box className="messagebox">
        <Box className="msgtext">
          <h4>{text}</h4>
          {inputs.map((input, index) => (
            <Box key={index}>
              <h5>{input.text}</h5>
              <input
                type="text"
                id={input.id}
                onChange={changeFormData}
                value={formData ? formData[input.id] : ""}
              />
            </Box>
          ))}
        </Box>
        {moreJSX}
        {buttons.map((button, index) => (
          <button onClick={handleButton} data-id={button.id} key={index}>
            {button.text}
          </button>
        ))}
      </Box>
    </Box>
  );
}

export default MessageBox;

export const colors = {
    gray : {
     50 : "#F0F0F0",
     100 : "#E0E0E0",
     200 : "#C2C2C2",
     300 : "#A3A3A3",
     400 : "#858585",
     500 : "#666666",
     600 : "#4D4D4D",
     700 : "#333333",
     800 : "#1A1A1A",
     900 : "#0A0A0A",
    },
   primary : {
    50: "#E9F1F8", 
    100: "#D2E2EF", 
    200: "#A5C5DF", 
    300: "#79A9D0", 
    400: "#4C8CC0", 
    500: "#1F6FB0", 
    600: "#175384", 
    700: "#103858", 
    800: "#081C2C", 
    900: "#030B12"
    
   }
}

import auth from "./authentication";
import user from "./user";
import prescription from "./prescriptions";
import publicPrescription from "./publicPrescriptions";
import process from "./process";
import chat from "./chat";
import collaborators from "./collaborators";
const rootReducer = {
  auth,
  user,
  prescription,
  publicPrescription,
  process,
  chat,
  collaborators,
};

export default rootReducer;

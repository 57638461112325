import { callAPi } from "./http-common";

const publicPrescriptions = () => {
  return callAPi.get("/api/getAllPublicPrescription");
};
const searchPublicPrescription = (data) => {
  return callAPi.post("api/public/searchPrescription", data);
};

const PublicPrescriptionsServices = {
  publicPrescriptions,
  searchPublicPrescription,
};

export default PublicPrescriptionsServices;

import { createTheme, ThemeProvider } from "@mui/material/styles";
import React, { useRef, useState } from "react";
import Navbar from "./Component/Navbar";
import { useEffectOnce } from "./hooks/useEffectOnce";
import { distributionService } from "./services/distributionService";
import { colors } from "./theme/colors";
import Routers from "./Utils/Routers";
import { Box } from "@mui/material";
import i18next from "i18next";

const theme = createTheme({
  palette: {
    gray: {
      50: colors.gray[50],
      100: colors.gray[100],
      200: colors.gray[200],
      300: colors.gray[300],
      400: colors.gray[400],
      500: colors.gray[500],
      600: colors.gray[600],
      700: colors.gray[700],
      800: colors.gray[800],
      900: colors.gray[900],
    },
    primary: {
      50: colors.primary[50],
      100: colors.primary[100],
      200: colors.primary[200],
      300: colors.primary[300],
      400: colors.primary[400],
      500: colors.primary[500],
      600: colors.primary[600],
      700: colors.primary[700],
      800: colors.primary[800],
      900: colors.primary[900],
    },
    bgColors: {
      primary: "#313131",
      secondary: "#1580BE",
      white: "#ffffff",
    },
    borderColors: {
      gray: "#D3D3D3",
    },
  },
  typography: {
    display: {
      fontSize: "64px",
      lineHeight: "120%",
      "@media (min-width:600px)": {
        fontSize: "44px",
      },
    },
    h1: {
      fontSize: "54px",
      lineHeight: "120%",
      "@media (min-width:600px)": {
        fontSize: "34px",
      },
    },
    h2: {
      fontSize: "48px",
      lineHeight: "130%",
      "@media (min-width:600px)": {
        fontSize: "28px",
      },
    },
    h3: {
      fontSize: "32px",
      lineHeight: "120%",
      "@media (min-width:600px)": {
        fontSize: "12px",
      },
    },
    h4: {
      fontSize: "24px",
      lineHeight: "120%",
      "@media (min-width:600px)": {
        fontSize: "12px",
      },
    },
    basic: {
      fontSize: "16px",
      lineHeight: "120%",
    },
  },
});

export const WSContext = React.createContext();

function App() {
  const [socket, setSocket] = useState(null);

  const ws = useRef();

  useEffectOnce(() => {
    // chat server connection, get current host
    let host = window.location.host;
    let origin = window.location.origin;
    if (host.indexOf("localhost:3") !== -1)
      host = "ws://" + host.split(":")[0] + ":8090";
    else if (origin.indexOf("https:") !== -1) host = "wss://" + host;
    else host = "ws://" + host;

    ws.current = new WebSocket(host);

    setSocket(ws.current);

    ws.current.onopen = () => {};

    ws.current.onmessage = (e) => {
      const msg = JSON.parse(e.data);

      if (msg.command === "userlist")
        distributionService.sendUserlist(msg.payload);
      else distributionService.sendMessage(msg);
    };

    return () => {
      ws.current.close();
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Box display={"flex"} flexDirection={"column"}>
        <Navbar />
        <Box>
          <Routers />
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default App;

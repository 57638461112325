import {
  Box,
  Button,
  Checkbox,
  Divider,
  Drawer,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import ActivitySetting from "./ActivitySetting/ActivitySetting";
import SettingBox from "./ActivitySetting/SettingBox";
import { ReactSortable } from "react-sortablejs";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import he from "he";

const CreateActivity = ({
  selectedButtons,
  interactions,
  setSelectedButtons,
  setInteractions,
  setActivityData,
  interactionsValues,
  refetch,
  setAddActivity,
  graphStateClick,
  removegraphStateClick,
  updateActivity,
  fullActivity,
  setIsUpdate,
  setTargetActivity,
}) => {
  const activityTitles = JSON.parse(localStorage.getItem("activityTitle"));
  const [state, setState] = React.useState({ right: false });
  const [settingId, setSettingId] = useState({});
  const [activityTitle, setActivityTitle] = useState();
  const [fetch, setFetch] = useState(false);
  const [values, setValues] = useState([]);
  const [cellId, setCellId] = useState();
  const [graphState, setGraphState] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [duplicateShow, setDuplicateShow] = useState(false);
  const [totalSeconds, setTotalSeconds] = useState(0);
  const [inputTime, setInputTime] = useState({
    seconds: 0,
    minutes: 0,
    hours: 0,
  });
  const [listItems, setListItems] = useState("");
  const [allListItems, setallListItems] = useState([]);
  const [isPlaceholderVisible, setPlaceholderVisible] = useState(true);
  const [Key, setKey] = useState("");

  let cellsData = [];
  let cellsValue = {};
  let interactionsClick = {};
  const connections = fullActivity?.cells?.filter(
    (item) => item.type === "Activity"
  );

  useEffect(() => {
    if (graphStateClick) {
      setGraphState(true);
    }
  }, [graphStateClick]);
  useEffect(() => {
    if (graphStateClick && graphState) {
      const id = graphStateClick.data && graphStateClick.id;

      const richText = graphStateClick.data && graphStateClick?.data?.richText;
      const listItems =
        graphStateClick.data && graphStateClick?.data?.listItems;
      const progressIndicator =
        graphStateClick.data && graphStateClick?.data?.progressIndicator;
      const guidanceText =
        graphStateClick.data && graphStateClick?.data?.guidanceText;
      const textBox = graphStateClick?.data && graphStateClick?.data?.textBox;

      const activityName = graphStateClick && graphStateClick?.activityName;
      setCellId(id);
      if (activityName) {
        localStorage.setItem(
          "activityTitle",
          JSON.stringify({ richTextBody: activityName })
        );
      }
      setActivityTitle(activityName);
      const checkboxes =
        graphStateClick.data && graphStateClick?.data?.checkboxes;
      const options = graphStateClick.data && graphStateClick?.options;
      setKey(graphStateClick.data && graphStateClick?.data?.updateKey);

      const continuation =
        graphStateClick.data && graphStateClick?.data?.continuation;
      const timerActivity =
        graphStateClick.data && graphStateClick?.data?.timerActivity;

      const richId = uuidv4();

      if (textBox) {
        interactionsClick.textBox = true;
      }
      if (checkboxes) {
        interactionsClick.selectionItems = true;
      }
      if (listItems) {
        interactionsClick.listOfItems = true;
      }
      if (progressIndicator) {
        interactionsClick.progressIndicator = true;
      }
      if (guidanceText) {
        interactionsClick.guidanceText = true;
      }
      if (timerActivity) {
        interactionsClick.timer = true;
      }

      textBox?.forEach((item) => {
        localStorage.setItem(
          `textBoxSettings_${item.id}`,
          JSON.stringify(item)
        );
        cellsData.push({
          btnId: "textBox",
          id: item.id,
          value: item.textBox,
        });
        cellsValue[item.id] = item.label;
      });

      guidanceText?.forEach((item) => {
        localStorage.setItem(
          `guidanceTextSettings_${item.id}`,
          JSON.stringify(item)
        );
        cellsData.push({
          btnId: "guidanceText",
          id: item.id,
          value: item.guidanceText,
        });
        cellsValue[item.id] = item.label;
      });
      progressIndicator?.forEach((item) => {
        localStorage.setItem(
          `progressIndicator_${item.id}`,
          JSON.stringify(item)
        );
        cellsData.push({
          btnId: "progressIndicator",
          id: item.id,
          value: item.progress,
        });
        cellsValue[item.id] = item.label;
      });
      listItems?.forEach((item) => {
        localStorage.setItem(
          `listItemsSettings_${item.id}`,
          JSON.stringify(item)
        );
        cellsData.push({
          btnId: "listOfItems",
          id: item.id,
          value: item.listItem,
        });
        cellsValue[item.id] = item.label;
      });
      checkboxes?.forEach((item) => {
        localStorage.setItem(
          `selectItemsSettings_${item.id}`,
          JSON.stringify(item)
        );
        cellsData.push({
          btnId: "selectionItems",
          id: item.id,
          value: item.selectionItem,
        });
        cellsValue[item.id] = item.label;
      });
      if (options) {
        interactionsClick.continuationOption = true;
      }
      if (continuation) {
        localStorage.setItem(
          `continuationOptionsSettings_${continuation.id}`,
          JSON.stringify(continuation)
        );
        cellsData.push({
          btnId: "continuationOption",
          id: continuation.id,
          value: continuation,
        });
      }
      if (timerActivity) {
        localStorage.setItem(
          `timerSettings_${timerActivity.id}`,
          JSON.stringify(timerActivity)
        );
        cellsData.push({
          btnId: "timer",
          id: timerActivity.id,
          value: timerActivity,
        });
      }

      setInteractions({
        ...interactions,
        ...interactionsClick,
      });
      setDuplicateShow(true);
      setSelectedButtons(cellsData);
      setValues(cellsValue);
      setFetch(true);
    }
  }, [graphStateClick, fetch]);
  const handleInteractionDelete = (id) => {
    setSelectedButtons((prevButtons) => {
      const buttonToDelete = prevButtons.find((item) => item.id === id);
      if (buttonToDelete) {
        return prevButtons.filter((item) => item.id !== id);
      }
      return prevButtons;
    });
  };
  const handleInputChange = (e, id) => {
    if (e.target.type === "checkbox") {
      setValues({
        ...values,
        [e.target.id]: e.target.checked,
      });
    } else {
      setValues({
        ...values,
        [e.target.id]: e.target.value,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const options = [];
    let richText = "";
    const checkboxes = [];
    const ListItems = [];
    const ProgressIndicator = [];
    const GuidanceText = [];
    const TextBox = [];
    const res = selectedButtons.find(
      (item) => item?.btnId === "continuationOption"
    );

    const continuation = JSON.parse(
      localStorage.getItem(`continuationOptionsSettings_${res?.id}`)
    );

    const commentBox = selectedButtons.find((item) => item.btnId === "textBox");
    const itemElement = document?.getElementById(commentBox?.id)?.textContent;
    if (commentBox) {
      richText = itemElement;
    }

    const checkBox = selectedButtons.filter(
      (item) => item.btnId === "selectionItems"
    );
    const progressIndicaator = selectedButtons.filter(
      (item) => item?.btnId === "progressIndicator"
    );
    const listItems = selectedButtons.filter(
      (item) => item.btnId === "listOfItems"
    );
    const textBoxes = selectedButtons.filter(
      (item) => item.btnId === "textBox"
    );
    const guidanceText = selectedButtons.filter(
      (item) => item.btnId === "guidanceText"
    );
    const timer = selectedButtons.find((item) => item?.btnId === "timer");

    const timerActivity = JSON.parse(
      localStorage.getItem(`timerSettings_${timer?.id}`)
    );

    textBoxes.forEach((item, index) => {
      const itemSelect = JSON.parse(
        localStorage.getItem(`textBoxSettings_${item.id}`)
      );
      TextBox.push({
        id: item.id,
        textBox: itemSelect,
      });
    });

    guidanceText.forEach((item, index) => {
      const itemSelect = JSON.parse(
        localStorage.getItem(`guidanceTextSettings_${item.id}`)
      );
      GuidanceText.push({
        id: item.id,
        guidanceText: itemSelect,
      });
    });

    progressIndicaator.forEach((item, index) => {
      const itemSelect = JSON.parse(
        localStorage.getItem(`progressIndicator_${item.id}`)
      );
      ProgressIndicator.push({
        id: item.id,
        progress: itemSelect,
      });
    });

    listItems.forEach((item, index) => {
      const itemSelect = JSON.parse(
        localStorage.getItem(`listItemsSettings_${item.id}`)
      );
      ListItems.push({
        id: item.id,
        listItem: itemSelect,
      });
    });

    continuation?.allListContinuation?.forEach((item) => {
      options.push({
        id: item?.id,
        text: item?.label,
      });
    });

    checkBox.forEach((item, index) => {
      const itemSelect = JSON.parse(
        localStorage.getItem(`selectItemsSettings_${item.id}`)
      );
      checkboxes.push({
        id: item.id,
        selectionItem: itemSelect,
      });
    });
    const updateKey = uuidv4();

    const data = {
      id: cellId,
      question: activityTitles?.richTextBody,
      activityTitle: activityTitles,
      continuation: continuation,
      options: continuation?.allListContinuation,
      richText: richText,
      timerActivity: timerActivity,
      checkboxes: checkboxes,
      listItems: ListItems,
      progressIndicator: ProgressIndicator,
      guidanceText: GuidanceText,
      textBox: TextBox,
      updateKey: Key !== "" ? Key : updateKey,
    };

    setActivityData({
      id: cellId,
      question: activityTitles?.richTextBody,
      activityTitle: activityTitles,
      continuation: continuation,
      options: continuation?.allListContinuation,
      richText: richText,
      timerActivity: timerActivity,
      updateKey: Key !== "" ? Key : updateKey,
      checkboxes: checkboxes,
      listItems: ListItems,
      progressIndicator: ProgressIndicator,
      guidanceText: GuidanceText,
      textBox: TextBox,
    });
    localStorage.setItem(
      "activityData",
      JSON.stringify({
        id: cellId,
        question: activityTitles?.richTextBody,
        activityTitle: activityTitles,
        options: options,
        continuation: continuation,
        richText: richText,
        checkboxes: checkboxes,
        listItems: ListItems,
        progressIndicator: ProgressIndicator,
        guidanceText: GuidanceText,
        timerActivity: timerActivity,
        updateKey: Key !== "" ? Key : updateKey,
        textBox: TextBox,
      })
    );

    if (cellId) {
      updateActivity(data);
      setIsUpdate(true);
    }
    removeFromLocalStorage();
    setGraphState(false);
    setInteractions(interactionsValues);
    setCellId();
    setSelectedButtons([]);
    setActivityTitle("");
  };

  const activityTitleChange = (e) => {
    setActivityTitle(e.target.value);
    setPlaceholderVisible(e.target.value === "");
  };
  const handleButtonDuplicate = (btnId) => {
    if (Object.keys(interactions).includes(btnId)) {
      setSelectedButtons([...selectedButtons, { id: uuidv4(), btnId: btnId }]);
      setInteractions({
        ...interactions,
        [btnId]: true,
      });
    }
  };
  const options = [];
  let richText = "";
  const checkboxes = [];
  const ListItems = [];
  const ProgressIndicator = [];
  const GuidanceText = [];
  const TextBox = [];

  const handleDuplicate = () => {
    setCellId();

    const res = selectedButtons.find(
      (item) => item?.btnId === "continuationOption"
    );

    const continuation = JSON.parse(
      localStorage.getItem(`continuationOptionsSettings_${res?.id}`)
    );

    const commentBox = selectedButtons.find(
      (item) => item?.btnId === "textBox"
    );
    const itemElement = document?.getElementById(commentBox?.id)?.textContent;
    if (commentBox) {
      richText = itemElement;
    }
    const timer = selectedButtons.find((item) => item?.btnId === "timer");

    const timerActivity = JSON.parse(
      localStorage.getItem(`timerSettings_${timer?.id}`)
    );
    const checkBox = selectedButtons.filter(
      (item) => item?.btnId === "selectionItems"
    );
    const progressIndicaator = selectedButtons.filter(
      (item) => item?.btnId === "progressIndicator"
    );
    const listItems = selectedButtons.filter(
      (item) => item?.btnId === "listOfItems"
    );
    const textBoxes = selectedButtons.filter(
      (item) => item?.btnId === "textBox"
    );
    const guidanceText = selectedButtons.filter(
      (item) => item?.btnId === "guidanceText"
    );

    textBoxes?.forEach((item, index) => {
      const itemSelect = JSON.parse(
        localStorage.getItem(`textBoxSettings_${item?.id}`)
      );
      TextBox.push({
        id: item.id,
        textBox: itemSelect.textBox ? itemSelect.textBox : itemSelect,
      });
    });

    guidanceText?.forEach((item, index) => {
      const itemSelect = JSON.parse(
        localStorage.getItem(`guidanceTextSettings_${item?.id}`)
      );
      GuidanceText.push({
        id: item.id,
        guidanceText: itemSelect?.guidanceText
          ? itemSelect.guidanceText
          : itemSelect,
      });
    });

    progressIndicaator?.forEach((item, index) => {
      const itemSelect = JSON.parse(
        localStorage.getItem(`progressIndicator_${item?.id}`)
      );
      ProgressIndicator.push({
        id: item.id,
        progress: itemSelect.progress ? itemSelect.progress : itemSelect,
      });
    });

    listItems?.forEach((item, index) => {
      const itemSelect = JSON.parse(
        localStorage.getItem(`listItemsSettings_${item?.id}`)
      );
      ListItems.push({
        id: item.id,
        listItem: itemSelect.listItem ? itemSelect.listItem : itemSelect,
      });
    });

    continuation?.allListContinuation?.forEach((item) => {
      options.push({
        id: item.id,
        text: item.label,
      });
    });

    checkBox?.forEach((item, index) => {
      const itemSelect = JSON.parse(
        localStorage.getItem(`selectItemsSettings_${item?.id}`)
      );
      checkboxes.push({
        id: item.id,
        selectionItem: itemSelect.selectionItem
          ? itemSelect.selectionItem
          : itemSelect,
      });
    });

    const updateKey = uuidv4();

    setActivityData({
      question: activityTitles?.richTextBody,
      activityTitle: activityTitles,
      continuation: continuation,
      options: options,
      richText: richText,
      checkboxes: checkboxes,
      listItems: ListItems,
      timerActivity: timerActivity,
      updateKey: updateKey,

      progressIndicator: ProgressIndicator,
      guidanceText: GuidanceText,
      textBox: TextBox,
    });
    localStorage.setItem(
      "activityData",
      JSON.stringify({
        question: activityTitles?.richTextBody,
        activityTitle: activityTitles,
        continuation: continuation,
        options: options,
        richText: richText,
        checkboxes: checkboxes,
        listItems: ListItems,
        timerActivity: timerActivity,
        progressIndicator: ProgressIndicator,
        guidanceText: GuidanceText,
        textBox: TextBox,
        updateKey: updateKey,
      })
    );
    removeFromLocalStorage();
    setGraphState(false);
    setInteractions(interactionsValues);
    setSelectedButtons([]);
    setActivityTitle("");
  };
  const toggleDrawer = (anchor, open, btnId, id) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    if (open === true) {
      setSettingId({ btnId: btnId, id: id });
    }
    setState({ ...state, [anchor]: open });
  };

  const clearLocalStorageExcept = (desiredKeys) => {
    for (let key in localStorage) {
      if (!desiredKeys.includes(key)) {
        localStorage.removeItem(key);
      }
    }
  };
  const removeFromLocalStorage = () => {
    selectedButtons.forEach((item) => {
      localStorage.removeItem(`textBoxSettings_${item.id}`);
    });
    selectedButtons.forEach((item) => {
      localStorage.removeItem(`selectItemsSettings_${item.id}`);
    });
    selectedButtons.forEach((item) => {
      localStorage.removeItem(`selectItemsSettings_${item.id}`);
    });
    localStorage.removeItem("activityTitle");
  };

  useEffect(() => {
    if (!graphStateClick) {
      removeFromLocalStorage();
      const desiredKeys = [
        "draftprescription",
        "access_token",
        "cell",
        "prescriptionID",
      ];
      clearLocalStorageExcept(desiredKeys);
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("cell")) {
    }
  }, [refetch]);

  const handleListChange = (e) => {
    setListItems(e.target.value);
  };

  const handleAddList = () => {
    if (listItems) {
      setallListItems([
        ...allListItems,
        {
          id: uuidv4(),
          value: listItems,
        },
      ]);
    }
    setListItems("");
  };

  const allSelectionItemEmptyState = [
    {
      id: 0,
      title: "Option 1",
    },
    {
      id: 1,
      title: "Option 2",
    },
    {
      id: 2,
      title: "Option 3",
    },
  ];

  const allSListItemEmptyState = [
    {
      id: 0,
      title: "Option 1",
    },
    {
      id: 1,
      title: "Option 2",
    },
    {
      id: 2,
      title: "Option 3",
    },
  ];

  return (
    <Box display={"flex"} justifyContent={"center"}>
      <Stack
        my={"51px"}
        width={"775px"}
        height={"75vh"}
        sx={{
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            width: "0.2em",
          },
          "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#1F6FB0",
            outline: "1px solid slategrey",
          },
        }}
      >
        <Stack
          width={"717px"}
          justifyContent={"space-between"}
          sx={{ backgroundColor: "white" }}
        >
          <Stack>
            <Box
              onClick={toggleDrawer("right", true, "activityTitle")}
              sx={{
                paddingX: "24px",
                paddingY: "22.5px",
                boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.2)",
                borderRadius: "1px",
                borderBottom: "1px solid #E5E5E5",
              }}
            >
              <div
                style={{
                  width: "50%",
                  padding: "5px",
                  outline: "none",
                }}
              >
                {activityTitles?.richTextBody ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: activityTitles?.richTextBody,
                    }}
                    activityTitle
                  ></div>
                ) : activityTitle ? (
                  <div
                    dangerouslySetInnerHTML={{ __html: activityTitle }}
                  ></div>
                ) : (
                  "Untitled Activity"
                )}
              </div>
              <input type="hidden" value={activityTitle} readonly />
            </Box>
            <Stack>
              {selectedButtons.length === 0 && (
                <>
                  <Box padding={"24px"}>
                    <Typography
                      fontSize={"16px"}
                      paddingY={"27px"}
                      fontWeight={400}
                      color={"#344054"}
                      textAlign={"center"}
                      border="1px dashed #A3A3A3"
                    >
                      Select an element from the left side bar to start building
                      an activity.
                    </Typography>
                  </Box>
                  <Divider />
                </>
              )}

              <ReactSortable
                list={selectedButtons}
                setList={setSelectedButtons}
              >
                {selectedButtons.length > 0 &&
                  selectedButtons.map((item) => {
                    const { id, btnId, value } = item;

                    const textBoxSettings = JSON.parse(
                      localStorage.getItem(`textBoxSettings_${id}`)
                    );

                    const selectItemsSettings = JSON.parse(
                      localStorage.getItem(`selectItemsSettings_${id}`)
                    );
                    const listItemsSettings = JSON.parse(
                      localStorage.getItem(`listItemsSettings_${id}`)
                    );
                    const guidanceTextSettings = JSON.parse(
                      localStorage.getItem(`guidanceTextSettings_${id}`)
                    );

                    const progressIndicators = JSON.parse(
                      localStorage.getItem(`progressIndicator_${id}`)
                    );

                    const continuationOptionsSettings = JSON.parse(
                      localStorage.getItem(`continuationOptionsSettings_${id}`)
                    );
                    const timerSettings = JSON.parse(
                      localStorage.getItem(`timerSettings_${id}`)
                    );

                    return (
                      <Box key={id} component={"form"} onSubmit={handleSubmit}>
                        {interactions[btnId] ? (
                          btnId === "textBox" ? (
                            <>
                              <Stack
                                padding={"24px"}
                                sx={{ position: "relative" }}
                                spacing={"12px"}
                                onClick={toggleDrawer("right", true, btnId, id)}
                              >
                                <Typography fontSize={"16px"} fontWeight={400}>
                                  {textBoxSettings && textBoxSettings.id === id
                                    ? textBoxSettings?.label
                                      ? textBoxSettings?.label
                                      : value?.label
                                      ? value?.label
                                      : "Text Box"
                                    : "Text Box"}
                                </Typography>
                                <Box
                                  alignItems={"center"}
                                  display={"flex"}
                                  gap={2}
                                  sx={{
                                    backgroundColor: " rgba(71, 84, 103, 0.05)",
                                    color: "#475467",
                                    border: "1px solid #C2C2C2",
                                  }}
                                  paddingX={"8px"}
                                  paddingY={"13px"}
                                >
                                  {textBoxSettings &&
                                  textBoxSettings.id === id ? (
                                    textBoxSettings?.richText ? (
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: textBoxSettings?.richTextBody,
                                        }}
                                      ></div>
                                    ) : textBoxSettings?.placeholder ? (
                                      <div>{textBoxSettings?.placeholder}</div>
                                    ) : value?.richText ? (
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: he.decode(
                                            value?.richTextBody
                                          ),
                                        }}
                                      ></div>
                                    ) : value?.placeholder ? (
                                      <div>{value?.placeholder}</div>
                                    ) : (
                                      <div>
                                        "Display in this line the placeholder
                                        text that the user adds from the
                                        properties on this line..."
                                      </div>
                                    )
                                  ) : (
                                    <div>
                                      "Display in this line the placeholder text
                                      that the user adds from the properties on
                                      this line..."
                                    </div>
                                  )}
                                </Box>
                                <div
                                  onClick={(event) => event.stopPropagation()}
                                >
                                  <SettingBox
                                    openSettings={toggleDrawer(
                                      "right",
                                      true,
                                      btnId,
                                      id
                                    )}
                                    duplicate={() =>
                                      handleButtonDuplicate(btnId)
                                    }
                                    deleteInteraction={() =>
                                      handleInteractionDelete(id)
                                    }
                                  />
                                </div>
                              </Stack>
                              <Divider />
                            </>
                          ) : btnId === "selectionItems" ? (
                            <>
                              <Stack
                                padding={"24px"}
                                sx={{ position: "relative" }}
                                onClick={toggleDrawer("right", true, btnId, id)}
                              >
                                {value && value.label !== "" ? (
                                  <Typography
                                    fontSize={"16px"}
                                    fontWeight={400}
                                  >
                                    {value.label}
                                  </Typography>
                                ) : (
                                  <Typography
                                    fontSize={"16px"}
                                    fontWeight={400}
                                  >
                                    {selectItemsSettings &&
                                    selectItemsSettings.id === id
                                      ? selectItemsSettings?.label
                                        ? selectItemsSettings?.label
                                        : value?.label
                                        ? value?.label
                                        : "Selection Items"
                                      : "Selection Items"}
                                  </Typography>
                                )}
                                <Stack spacing={"8px"}>
                                  {selectItemsSettings &&
                                  selectItemsSettings?.id === id ? (
                                    selectItemsSettings?.allSelectionItem ? (
                                      selectItemsSettings?.allSelectionItem.map(
                                        (item) => (
                                          <Box
                                            key={item.id}
                                            alignItems="center"
                                            display="flex"
                                          >
                                            <Checkbox
                                              checked={item.checked}
                                              name="selectionItems"
                                            />
                                            <TextField
                                              size="small"
                                              sx={{
                                                "& fieldset": {
                                                  border: "none",
                                                  padding: 0,
                                                },
                                              }}
                                              fullWidth
                                              name="label"
                                              placeholder="Option 1"
                                              id={id}
                                              value={
                                                item ? item.label : values[id]
                                              }
                                              onChange={(e) =>
                                                handleInputChange(e, id)
                                              }
                                            />
                                          </Box>
                                        )
                                      )
                                    ) : value !== undefined ? (
                                      <>
                                        {value?.allSelectionItem?.map(
                                          (item) => (
                                            <Box
                                              key={item.id}
                                              alignItems="center"
                                              display="flex"
                                            >
                                              <Checkbox
                                                checked={item.checked}
                                                name="selectionItems"
                                              />
                                              <TextField
                                                size="small"
                                                sx={{
                                                  "& fieldset": {
                                                    border: "none",
                                                    padding: 0,
                                                  },
                                                }}
                                                fullWidth
                                                name="label"
                                                placeholder="Option 1"
                                                id={id}
                                                value={
                                                  item ? item.label : values[id]
                                                }
                                                onChange={(e) =>
                                                  handleInputChange(e, id)
                                                }
                                              />
                                            </Box>
                                          )
                                        )}
                                      </>
                                    ) : null
                                  ) : (
                                    <>
                                      {allSelectionItemEmptyState.map(
                                        (item) => (
                                          <Box
                                            key={item.id}
                                            alignItems="center"
                                            display="flex"
                                          >
                                            <Checkbox
                                              disabled
                                              checked={item.checked}
                                              name="selectionItems"
                                            />
                                            <TextField
                                              disabled
                                              size="small"
                                              sx={{
                                                "& fieldset": {
                                                  border: "none",
                                                  padding: 0,
                                                },
                                              }}
                                              fullWidth
                                              name="label"
                                              placeholder="Option 1"
                                              id={id}
                                              value={
                                                item ? item.title : values[id]
                                              }
                                            />
                                          </Box>
                                        )
                                      )}
                                    </>
                                  )}
                                </Stack>
                                <div
                                  onClick={(event) => event.stopPropagation()}
                                >
                                  {" "}
                                  <SettingBox
                                    openSettings={toggleDrawer(
                                      "right",
                                      true,
                                      btnId,
                                      id
                                    )}
                                    duplicate={() =>
                                      handleButtonDuplicate(btnId)
                                    }
                                    deleteInteraction={() =>
                                      handleInteractionDelete(id)
                                    }
                                  />
                                </div>
                              </Stack>
                              <Divider />
                            </>
                          ) : btnId === "guidanceText" ? (
                            <>
                              <Stack
                                padding={"24px"}
                                sx={{ position: "relative" }}
                                spacing={"12px"}
                                onClick={toggleDrawer("right", true, btnId, id)}
                              >
                                <Typography fontSize={"16px"} fontWeight={400}>
                                  {guidanceTextSettings &&
                                  guidanceTextSettings?.id === id
                                    ? guidanceTextSettings?.label
                                      ? guidanceTextSettings?.label
                                      : value?.label
                                      ? value?.label
                                      : "Guidance Text"
                                    : "Guidance Text"}
                                </Typography>
                                <Box
                                  alignItems={"center"}
                                  display={"flex"}
                                  gap={2}
                                  sx={{
                                    backgroundColor: "#F3F7FB",
                                    color: "primary.400",
                                  }}
                                  paddingX={"11px"}
                                  paddingY={"8px"}
                                >
                                  <IconButton>
                                    <InfoOutlinedIcon
                                      sx={{ color: "primary.400" }}
                                    />
                                  </IconButton>
                                  {guidanceTextSettings &&
                                  guidanceTextSettings.id === id ? (
                                    guidanceTextSettings?.richText ? (
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            guidanceTextSettings?.richTextBody
                                              ? guidanceTextSettings?.richTextBody
                                              : value?.richTextBody,
                                        }}
                                      ></div>
                                    ) : guidanceTextSettings?.placeholder ? (
                                      <div>
                                        {guidanceTextSettings?.placeholder}
                                      </div>
                                    ) : value?.richText ? (
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: he.decode(
                                            value?.richTextBody
                                          ),
                                        }}
                                      ></div>
                                    ) : value?.placeholder ? (
                                      <div>{value?.placeholder}</div>
                                    ) : (
                                      <div>
                                        "The guidance text will be displayed in
                                        the activity"
                                      </div>
                                    )
                                  ) : (
                                    <div>
                                      "The guidance text will be displayed in
                                      the activity"
                                    </div>
                                  )}
                                </Box>
                                <div
                                  onClick={(event) => event.stopPropagation()}
                                >
                                  <SettingBox
                                    openSettings={toggleDrawer(
                                      "right",
                                      true,
                                      btnId,
                                      id
                                    )}
                                    duplicate={() =>
                                      handleButtonDuplicate(btnId)
                                    }
                                    deleteInteraction={() =>
                                      handleInteractionDelete(id)
                                    }
                                  />
                                </div>
                              </Stack>
                              <Divider />
                            </>
                          ) : btnId === "listOfItems" ? (
                            <>
                              <Stack
                                padding={"24px"}
                                sx={{ position: "relative" }}
                                onClick={toggleDrawer("right", true, btnId, id)}
                              >
                                {value && value.label !== "" ? (
                                  <Typography
                                    fontSize={"16px"}
                                    fontWeight={400}
                                  >
                                    {value.label}
                                  </Typography>
                                ) : (
                                  <Typography
                                    fontSize={"16px"}
                                    fontWeight={400}
                                  >
                                    {listItemsSettings &&
                                    listItemsSettings.id === id
                                      ? listItemsSettings?.label
                                        ? listItemsSettings?.label
                                        : value?.label
                                        ? value?.label
                                        : "list of Items"
                                      : "list of Items"}
                                  </Typography>
                                )}
                                <Stack spacing={"8px"}>
                                  {listItemsSettings &&
                                  listItemsSettings?.id === id ? (
                                    listItemsSettings?.allListItem ? (
                                      listItemsSettings?.allListItem.map(
                                        (item) => (
                                          <Box
                                            key={item.id}
                                            alignItems="center"
                                            display={"flex"}
                                          >
                                            <TextField
                                              size="small"
                                              sx={{
                                                "& fieldset": {
                                                  border: "none",
                                                  padding: 0,
                                                },
                                              }}
                                              fullWidth
                                              name="lable"
                                              placeholder="Option 1"
                                              id={id}
                                              value={
                                                item ? item.label : values[id]
                                              }
                                              onChange={(e) =>
                                                handleInputChange(e, id)
                                              }
                                            />
                                          </Box>
                                        )
                                      )
                                    ) : value !== undefined ? (
                                      <>
                                        {value?.allListItem?.map((item) => (
                                          <Box
                                            key={item.id}
                                            alignItems="center"
                                            display={"flex"}
                                          >
                                            <TextField
                                              size="small"
                                              sx={{
                                                "& fieldset": {
                                                  border: "none",
                                                  padding: 0,
                                                },
                                              }}
                                              fullWidth
                                              name="lable"
                                              placeholder="Option 1"
                                              id={id}
                                              value={
                                                item ? item.label : values[id]
                                              }
                                              onChange={(e) =>
                                                handleInputChange(e, id)
                                              }
                                            />
                                          </Box>
                                        ))}
                                      </>
                                    ) : null
                                  ) : (
                                    <>
                                      {allSListItemEmptyState.map((item) => (
                                        <Box
                                          key={item.id}
                                          alignItems="center"
                                          display={"flex"}
                                        >
                                          <Checkbox
                                            disabled
                                            checked={item.checked}
                                            name="selectionItems"
                                          />
                                          <TextField
                                            disabled
                                            size="small"
                                            sx={{
                                              "& fieldset": {
                                                border: "none",
                                                padding: 0,
                                              },
                                            }}
                                            fullWidth
                                            name="lable"
                                            placeholder="Option 1"
                                            id={id}
                                            value={
                                              item ? item.title : values[id]
                                            }
                                          />
                                        </Box>
                                      ))}
                                    </>
                                  )}
                                </Stack>
                                <div
                                  onClick={(event) => event.stopPropagation()}
                                >
                                  <SettingBox
                                    openSettings={toggleDrawer(
                                      "right",
                                      true,
                                      btnId,
                                      id
                                    )}
                                    duplicate={() =>
                                      handleButtonDuplicate(btnId)
                                    }
                                    deleteInteraction={() =>
                                      handleInteractionDelete(id)
                                    }
                                  />
                                </div>
                              </Stack>
                              <Divider />
                            </>
                          ) : btnId === "continuationOption" ? (
                            <>
                              <Stack
                                padding={"24px"}
                                sx={{ position: "relative" }}
                                onClick={toggleDrawer("right", true, btnId, id)}
                              >
                                {value && value.label !== "" ? (
                                  <Typography
                                    fontSize={"16px"}
                                    fontWeight={400}
                                  >
                                    {value.label}
                                  </Typography>
                                ) : (
                                  <Typography
                                    fontSize={"16px"}
                                    fontWeight={400}
                                  >
                                    {continuationOptionsSettings &&
                                    continuationOptionsSettings.id === id
                                      ? continuationOptionsSettings?.label
                                        ? continuationOptionsSettings?.label
                                        : value?.label
                                        ? value?.label
                                        : "Continuations Options"
                                      : "Continuations Options"}
                                  </Typography>
                                )}
                                <Stack spacing={"8px"}>
                                  {continuationOptionsSettings &&
                                  continuationOptionsSettings?.id === id ? (
                                    continuationOptionsSettings?.allListContinuation ? (
                                      continuationOptionsSettings?.allListContinuation.map(
                                        (item) => (
                                          <Box
                                            key={item.id}
                                            alignItems="center"
                                            display={"flex"}
                                          >
                                            <TextField
                                              size="small"
                                              sx={{
                                                "& fieldset": {
                                                  border: "none",
                                                  padding: 0,
                                                },
                                              }}
                                              fullWidth
                                              name="lable"
                                              placeholder="Option 1"
                                              id={id}
                                              value={item.label}
                                              onChange={(e) =>
                                                handleInputChange(e, id)
                                              }
                                            />
                                          </Box>
                                        )
                                      )
                                    ) : value !== undefined ? (
                                      <>
                                        {value?.allListContinuation?.map(
                                          (item) => (
                                            <Box
                                              key={item.id}
                                              alignItems="center"
                                              display={"flex"}
                                            >
                                              <TextField
                                                size="small"
                                                sx={{
                                                  "& fieldset": {
                                                    border: "none",
                                                    padding: 0,
                                                  },
                                                }}
                                                fullWidth
                                                name="lable"
                                                placeholder="Option 1"
                                                id={id}
                                                value={
                                                  item ? item.label : values[id]
                                                }
                                                onChange={(e) =>
                                                  handleInputChange(e, id)
                                                }
                                              />
                                            </Box>
                                          )
                                        )}
                                      </>
                                    ) : null
                                  ) : (
                                    <>
                                      {allSListItemEmptyState.map((item) => (
                                        <Box
                                          key={item.id}
                                          alignItems="center"
                                          display={"flex"}
                                        >
                                          <TextField
                                            disabled
                                            size="small"
                                            sx={{
                                              "& fieldset": {
                                                border: "none",
                                                padding: 0,
                                              },
                                            }}
                                            fullWidth
                                            name="lable"
                                            placeholder="Option 1"
                                            id={id}
                                            value={
                                              item ? item.title : values[id]
                                            }
                                          />
                                        </Box>
                                      ))}
                                    </>
                                  )}
                                </Stack>
                                <div
                                  onClick={(event) => event.stopPropagation()}
                                >
                                  <SettingBox
                                    openSettings={toggleDrawer(
                                      "right",
                                      true,
                                      btnId,
                                      id
                                    )}
                                    id={btnId}
                                    duplicate={() =>
                                      handleButtonDuplicate(btnId)
                                    }
                                    deleteInteraction={() =>
                                      handleInteractionDelete(id)
                                    }
                                  />
                                </div>
                              </Stack>
                              <Divider />
                            </>
                          ) : btnId === "progressIndicator" ? (
                            <>
                              <Stack
                                padding={"24px"}
                                sx={{ position: "relative" }}
                                spacing={"12px"}
                                onClick={toggleDrawer("right", true, btnId, id)}
                              >
                                <Typography
                                  fontSize={"16px"}
                                  id={id}
                                  value={
                                    progressIndicators &&
                                    progressIndicators?.id === id
                                      ? progressIndicators?.label
                                      : "Progress Indicator"
                                  }
                                  fontWeight={400}
                                >
                                  {progressIndicators &&
                                  progressIndicators.id === id
                                    ? progressIndicators?.label
                                      ? progressIndicators?.label
                                      : value?.label
                                      ? value?.label
                                      : "Progress Indicator"
                                    : "Progress Indicator"}
                                </Typography>
                                <Box
                                  alignItems={"center"}
                                  display={"flex"}
                                  gap={2}
                                  sx={{
                                    backgroundColor: "#F3F7FB",
                                    color: "primary.400",
                                  }}
                                  paddingX={"11px"}
                                  paddingY={"8px"}
                                >
                                  <IconButton>
                                    <InfoOutlinedIcon
                                      sx={{ color: "primary.400" }}
                                    />
                                  </IconButton>

                                  <Typography>
                                    The indicator will be available for users to
                                    assess their progress for this activity.
                                  </Typography>
                                </Box>
                                <div
                                  onClick={(event) => event.stopPropagation()}
                                >
                                  <SettingBox
                                    openSettings={toggleDrawer(
                                      "right",
                                      true,
                                      btnId,
                                      id
                                    )}
                                    duplicate={() =>
                                      handleButtonDuplicate(btnId)
                                    }
                                    id={btnId}
                                    deleteInteraction={() =>
                                      handleInteractionDelete(id)
                                    }
                                  />
                                </div>
                              </Stack>
                              <Divider />
                            </>
                          ) : btnId === "timer" ? (
                            <>
                              <Stack
                                padding={"24px"}
                                sx={{ position: "relative" }}
                                spacing={"12px"}
                                onClick={toggleDrawer("right", true, btnId, id)}
                              >
                                <Typography
                                  fontSize={"16px"}
                                  id={id}
                                  value={
                                    timerSettings && timerSettings?.id === id
                                      ? timerSettings?.label
                                      : "Progress Indicator"
                                  }
                                  fontWeight={400}
                                >
                                  {timerSettings && timerSettings.id === id
                                    ? timerSettings?.label
                                      ? timerSettings?.label
                                      : value?.label
                                      ? value?.label
                                      : "Timer"
                                    : "Timer"}
                                </Typography>
                                <Box
                                  alignItems={"center"}
                                  display={"flex"}
                                  gap={2}
                                  sx={{
                                    backgroundColor: "#F3F7FB",
                                    color: "primary.400",
                                  }}
                                  paddingX={"11px"}
                                  paddingY={"8px"}
                                >
                                  <IconButton>
                                    <InfoOutlinedIcon
                                      sx={{ color: "primary.400" }}
                                    />
                                  </IconButton>

                                  <Typography>
                                    {timerSettings && timerSettings.id === id
                                      ? timerSettings?.time
                                        ? timerSettings?.time
                                        : value?.time
                                        ? value?.time
                                        : " The timer will run based on the properties you selected."
                                      : " The timer will run based on the properties you selected."}
                                  </Typography>
                                </Box>
                                <div
                                  onClick={(event) => event.stopPropagation()}
                                >
                                  <SettingBox
                                    openSettings={toggleDrawer(
                                      "right",
                                      true,
                                      btnId,
                                      id
                                    )}
                                    duplicate={() =>
                                      handleButtonDuplicate(btnId)
                                    }
                                    id={btnId}
                                    deleteInteraction={() =>
                                      handleInteractionDelete(id)
                                    }
                                  />
                                </div>
                              </Stack>
                              <Divider />
                            </>
                          ) : null
                        ) : null}
                      </Box>
                    );
                  })}
              </ReactSortable>
            </Stack>
          </Stack>
          <Stack padding={"16px"} direction={"row"} justifyContent={"flex-end"}>
            <Stack direction={"row"} spacing={1}>
              <Button type="submit" onClick={handleSubmit}>
                {cellId ? "Update Activity" : "Add Activity"}
              </Button>
              <Button onClick={handleDuplicate}>
                {" "}
                {cellId ? "Duplicate Activity" : ""}
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <React.Fragment>
        <Drawer
          anchor={"right"}
          open={state["right"]}
          onClose={toggleDrawer("right", false)}
        >
          <ActivitySetting
            onClose={toggleDrawer("right", false)}
            setState={setState}
            settingId={settingId}
            values={values}
            setValues={setValues}
            anchor="right"
          />
        </Drawer>
      </React.Fragment>
    </Box>
  );
};

export default CreateActivity;

import { Box, Stack, TextField, Typography } from "@mui/material";
import React from "react";

const Timer = ({
  inputTime,
  setInputTime,
  setActivityTime,
  setAnchorElConnectOptions,
}) => {
  const formatTime = (seconds) => {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = seconds % 60;
    return `${h.toString().padStart(2, "0")}:${m
      .toString()
      .padStart(2, "0")}:${s.toString().padStart(2, "0")}`;
  };

  const handleInput = (event) => {
    const { name, value } = event.target;
    setInputTime({ ...inputTime, [name]: parseInt(value) });
  };

  const startTimer = () => {
    const { seconds, minutes, hours } = inputTime;
    const totalTime = seconds + minutes * 60 + hours * 3600;
    setActivityTime(formatTime(totalTime));
    setAnchorElConnectOptions(false);
  };

  return (
    <Stack>
      <Typography fontSize={"16px"} fontWeight={400}>
        Select Time
      </Typography>
      <Box p={2} gap={1} display="flex">
        <Stack>
          <label>Hours:</label>
          <TextField
            type="number"
            name="hours"
            value={inputTime.hours}
            onChange={handleInput}
            id="standard-basic"
            variant="standard"
            InputProps={{
              inputProps: {
                min: 0,
              },
            }}
          />
        </Stack>

        <Stack>
          <label>Minutes:</label>
          <TextField
            type="number"
            name="minutes"
            value={inputTime.minutes}
            onChange={handleInput}
            id="standard-basic"
            variant="standard"
            InputProps={{
              inputProps: {
                min: 0,
              },
            }}
          />
        </Stack>

        <Stack>
          <label>Seconds:</label>
          <TextField
            type="number"
            name="seconds"
            value={inputTime.seconds}
            onChange={handleInput}
            id="standard-basic"
            variant="standard"
            InputProps={{
              inputProps: {
                min: 0,
              },
            }}
          />
        </Stack>
      </Box>
    </Stack>
  );
};

export default Timer;

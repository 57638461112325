import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import "./SideMenu.css";
import { Box, Divider } from "@mui/material";
import { Link, useParams } from "react-router-dom";

function SideMenu({ onClick, presName, newPresName, setNewPresName }) {
  const { t } = useTranslation();

  const params = useParams();

  return (
    <Box className="SideMenu">
      <ul
        style={{ display: "flex", flexDirection: "column", gap: "16px" }}
        onClick={onClick}
      >
        <Link
          style={{
            marginTop: "10px",
          }}
          className={params.id === "activities" ? "active " : "li"}
          to="/home/activities"
          data-id="activities"
        >
          {t("activities")}
        </Link>
        <Link
          className={params.id === "public_activities" ? "active " : "li"}
          to="/home/public_activities"
          data-id="public_activities"
        >
          {t("public_activities")}
        </Link>
        <Link
          className={params.id === "settings" ? "active " : "li"}
          to="/home/settings"
          data-id="settings"
        >
          {t("settings")}
        </Link>
        <Link
          className={params.id === "collaborators" ? "active " : "li"}
          to="/home/collaborators"
          data-id="collaborators"
        >
          {t("collaborators")}
        </Link>
        <Link
          className={params.id === "help_&_contact" ? "active " : "li"}
          to="/home/help_&_contact"
          data-id="help_&_contact"
        >
          {t("help_&_contact")}
        </Link>
        <Link
          className={params.id === "communicate" ? "active " : "li"}
          to="/home/communicate"
          data-id="communicate"
        >
          {t("communicate")}
        </Link>
      </ul>
    </Box>
  );
}

export default SideMenu;

import {
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ActivityBuilder from "./ActivityBuilder/ActivityBuilder";
import CreateActivity from "./CreateActivity";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import DesignView from "../../DesignView/DesignView";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getPrescriptionsById } from "../../../Redux/slice/prescriptions";
import { toast } from "react-toastify";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";

const interactionsValues = {
  guidanceText: false,
  textBox: false,
  selectionItems: false,
  listOfItems: false,
  timer: false,
  progressIndicator: false,
  continuationOption: false,
};

const interactionsButton = [
  {
    id: "guidanceText",
    name: "Guidance text",
    desc: "Provide guidance for the activity ",
    icon: "A",
  },
  {
    id: "textBox",
    name: "Text Box",
    desc: "Enable users to write text",
    icon: "A",
  },
  {
    id: "selectionItems",
    name: "Selection items",
    desc: "Check / Uncheck",
    icon: "A",
  },
  {
    id: "listOfItems",
    name: "List of items",
    desc: "Create and reorder a list ",
    icon: "A",
  },
  {
    id: "timer",
    name: "Timer",
    desc: "Countdown or elapsed time of an activity",
    icon: "A",
  },
  {
    id: "progressIndicator",
    name: "Progress Indicator",
    desc: "Enable users to assess their progress",
    icon: "A",
  },
  {
    id: "continuationOption",
    name: "Continuation options",
    desc: "Possible next activity ",
    icon: "A",
  },
];
const CreatActivityLayout = () => {
  const [interactions, setInteractions] = useState(interactionsValues);
  const [selectedButtons, setSelectedButtons] = useState([]);
  const [isForm, setIsForm] = useState(true);
  const [activityData, setActivityData] = useState();
  const [addActivty, setAddActivity] = useState(false);
  const [saveActivity, setSaveActivity] = useState(false);
  const dispatch = useDispatch();
  const [activityName, setActivityName] = useState("");
  const [graphStateClick, setGraphStateClick] = useState();
  const [removegraphStateClick, setRemoveGraphStateClick] = useState(false);
  const [fullActivity, setFullActivity] = useState();
  const [targetActivity, setTargetActivity] = useState();
  const [refetch, setRefetch] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);

  const id = localStorage.getItem("prescriptionID");
  const designViewRef = useRef();

  const updateActivity = React.useCallback(
    (data) => {
      if (designViewRef.current && designViewRef.current.updateCell) {
        designViewRef.current.updateCell(data);
      }
    },
    [designViewRef]
  );
  const getActivityById = async () => {
    if (id) {
      const res = await dispatch(getPrescriptionsById(id));
      if (res.payload.data) {
        setActivityName(res.payload.data.name);
      }
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    localStorage.removeItem("activityData");
  }, []);

  useEffect(() => {
    if (isForm === false) {
      setActivityData(JSON.parse(localStorage.getItem("activityData")));
    }
  }, [isForm]);
  useEffect(() => {
    getActivityById();
  }, [id]);
  return (
    <Stack height={"calc(100vh - 65px)"}>
      <Box
        sx={{ backgroundColor: "white" }}
        justifyContent={"space-between"}
        position={"relative"}
        width={"100%"}
        paddingX={"28px"}
        display={"flex"}
        alignItems={"center"}
        height={"70px"}
      >
        <Stack
          width={"100%"}
          alignItems={"center"}
          direction={"row"}
          spacing={1}
        >
          <IconButton onClick={() => navigate("/home/activities")}>
            <ArrowBackOutlinedIcon />
          </IconButton>
          <Typography fontSize={"20px"} fontWeight={500} lineHeight={"20px"}>
            Activity Builder |{" "}
          </Typography>
          <TextField
            size="small"
            sx={{
              "& fieldset": {
                border: "none",
                padding: 0,
              },
            }}
            inputProps={{
              style: {
                fontSize: "20px",
                fontWeight: 500,
                color: "#7B61FF",
                padding: 0,
                paddingTop: 0,
              },
            }}
            name="lable"
            placeholder="Untitled Activity"
            value={activityName}
            onChange={(e) => setActivityName(e.target.value)}
          />
        </Stack>
        <Stack
          direction={"row"}
          sx={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: "4px",
            border: "1px solid #A3A3A3",
          }}
        >
          <Button
            sx={{
              backgroundColor: "#ECEDEE",
              color: "#344054",
              border: 0,
              borderRadius: 0,
              borderTopLeftRadius: "4px",
              borderBottomLeftRadius: "4px",
              borderRight: "1px solid #A3A3A3",
            }}
            onClick={() => setIsForm(true)}
          >
            Form
          </Button>
          <Button
            sx={{
              backgroundColor: "gray.50",
              color: "gray.300",
              border: 0,
              borderRadius: 0,
              borderTopRightRadius: "4px",
              borderBottomRightRadius: "4px",
            }}
            onClick={() => setIsForm(false)}
          >
            Flow
          </Button>
        </Stack>
        <Stack
          justifyContent={"flex-end"}
          width={"100%"}
          spacing={2}
          direction={"row"}
          alignItems={"center"}
        >
          <Typography color={"primary.500"} fontSize={"16px"} fontWeight={500}>
            Go to activity map
          </Typography>
          <Divider orientation="vertical" sx={{ borderBottomWidth: "30px" }} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#E8F0F8",
              width: "30px",
              height: "30px",
              borderRadius: "2px",
              padding: 2,
            }}
          >
            <RemoveRedEyeOutlinedIcon sx={{ color: "primary.500" }} />
          </Box>
          {isForm ? null : (
            <Button
              sx={{ width: "auto", border: 1, borderColor: "primary.600" }}
              onClick={() => {
                if (activityName === "") {
                  toast.warning("Activity Name Should not be empty");
                } else if (activityName) {
                  setSaveActivity(true);
                }
              }}
            >
              Save
            </Button>
          )}
        </Stack>
      </Box>
      <Box
        height={"calc(100vh - 136px)"}
        sx={{ backgroundColor: "gray.50" }}
        display={"flex"}
        width={"100%"}
      >
        <Box
          width={"315px"}
          sx={{
            backgroundColor: "white",
            boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.2)",
            borderTop: "1px solid rgba(0, 0, 0, 0.1)",
          }}
        >
          <ActivityBuilder
            interactionsButton={interactionsButton}
            interactions={interactions}
            setInteractions={setInteractions}
            selectedButtons={selectedButtons}
            setSelectedButtons={setSelectedButtons}
          />
        </Box>
        <Box width={"100%"} overflow={"hidden"}>
          {isForm ? (
            <CreateActivity
              setIsUpdate={setIsUpdate}
              setTargetActivity={setTargetActivity}
              updateActivity={updateActivity}
              fullActivity={fullActivity}
              removegraphStateClick={removegraphStateClick}
              refetch={refetch}
              graphStateClick={graphStateClick}
              setActivityData={setActivityData}
              interactionsValues={interactionsValues}
              setSelectedButtons={setSelectedButtons}
              setInteractions={setInteractions}
              interactions={interactions}
              selectedButtons={selectedButtons}
            />
          ) : (
            <Box width={"100%"}>
              <DesignView
                show={!isForm}
                setAddActivity={setAddActivity}
                activityData={activityData}
                setActivityData={setActivityData}
                addActivty={addActivty}
                saveActivity={saveActivity}
                setSaveActivity={setSaveActivity}
                activityName={activityName}
                setGraphStateClick={setGraphStateClick}
                setRefetch={setRefetch}
                refetch={refetch}
                setRemoveGraphStateClick={setRemoveGraphStateClick}
                removegraphStateClick={removegraphStateClick}
                setFullActivity={setFullActivity}
                targetActivity={targetActivity}
                graphStateClick={graphStateClick}
                ref={designViewRef}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Stack>
  );
};

export default CreatActivityLayout;

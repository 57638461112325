import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import RichEditor from "../RichTextEditor/RichEditor";
import { v4 as uuidv4 } from "uuid";
import AddIcon from "@mui/icons-material/Add";
import { ReactSortable } from "react-sortablejs";
import RichTextEditor from "../../../RichTextEditor";
import Timer from "../../Card/Timer";

const activities = [];
const formatTime = (seconds) => {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = seconds % 60;
  return `${h.toString().padStart(2, "0")}:${m.toString().padStart(2, "0")}:${s
    .toString()
    .padStart(2, "0")}`;
};

const ActivitySetting = ({ anchor, settingId, onClose, setState }) => {
  const activitiess = JSON.parse(localStorage.getItem("draftprescription"));
  const activityTitles = JSON.parse(localStorage.getItem("activityTitle"));

  const textBoxSettingss = JSON.parse(
    localStorage.getItem(`textBoxSettings_${settingId.id}`)
  );

  const timerSettingss = JSON.parse(
    localStorage.getItem(`timerSettings_${settingId.id}`)
  );
  const selectItemsSettings = JSON.parse(
    localStorage.getItem(`selectItemsSettings_${settingId.id}`)
  );
  const listItemsSettingss = JSON.parse(
    localStorage.getItem(`listItemsSettings_${settingId.id}`)
  );
  const continuationOptionsSettingss = JSON.parse(
    localStorage.getItem(`continuationOptionsSettings_${settingId.id}`)
  );
  const guidanceTextSettingss = JSON.parse(
    localStorage.getItem(`guidanceTextSettings_${settingId.id}`)
  );
  const progressIndicators = JSON.parse(
    localStorage.getItem(`progressIndicator_${settingId.id}`)
  );

  const [inputTime, setInputTime] = useState({
    seconds:
      timerSettingss?.inputTime?.seconds || timerSettingss?.timer?.seconds || 0,
    minutes:
      timerSettingss?.inputTime?.minutes || timerSettingss?.timer?.minutes || 0,
    hours:
      timerSettingss?.inputTime?.hours || timerSettingss?.timer?.hours || 0,
  });
  const [activityTime, setActivityTime] = useState();

  const [actiivityTitle, setActivityTitle] = useState({
    richTextBody: activityTitles ? activityTitles.richTextBody : "",
    showTitle: activityTitles ? activityTitles.showTitle : false,
    restoreDefault: activityTitles ? activityTitles.restoreDefault : false,

    tags: activityTitles ? activityTitles.tags : "",
  });

  const [textBoxSettings, setTextBoxSettings] = useState(() => {
    if (textBoxSettingss && textBoxSettingss.id === settingId.id) {
      return {
        id: textBoxSettingss.id,
        label: textBoxSettingss.label || textBoxSettingss.textBox?.label || "",
        placeholder:
          textBoxSettingss.placeholder ||
          textBoxSettingss.textBox?.placeholder ||
          "",
        richText:
          textBoxSettingss.richText ||
          (textBoxSettingss.textBox && textBoxSettingss.textBox.richText) ||
          false,
        richTextBody:
          textBoxSettingss.richTextBody ||
          (textBoxSettingss.textBox && textBoxSettingss.textBox.richTextBody) ||
          "",
      };
    } else {
      return {
        id: settingId.id,
        label: "",
        placeholder: "",
        richText: false,
      };
    }
  });

  const [guidanceTextSettings, setGuidanceTextSettings] = useState(() => {
    if (guidanceTextSettingss && guidanceTextSettingss.id === settingId.id) {
      return {
        id: guidanceTextSettingss.id,
        label:
          guidanceTextSettingss.label ||
          guidanceTextSettingss.guidanceText?.label ||
          "",
        placeholder:
          guidanceTextSettingss.placeholder ||
          guidanceTextSettingss.guidanceText?.placeholder ||
          "",
        showTitle:
          guidanceTextSettingss.showTitle ||
          guidanceTextSettingss.guidanceText?.showTitle ||
          false,
        richText:
          guidanceTextSettingss.richText ||
          guidanceTextSettingss.guidanceText?.richText ||
          false,
        richTextBody:
          guidanceTextSettingss.richTextBody ||
          guidanceTextSettingss.guidanceText?.richTextBody ||
          false,
      };
    } else {
      return {
        id: settingId.id,
        label: "",
        placeholder: "",
        showTitle: false,
      };
    }
  });

  const [timerSettings, setTimerSettings] = useState(() => {
    if (timerSettingss && timerSettingss.id === settingId.id) {
      return {
        id: timerSettingss.id,
        label: timerSettingss.label || timerSettingss.timer?.label || "",
        showTitle:
          timerSettingss.showTitle ||
          (timerSettingss.timer && timerSettingss.time.showTitle) ||
          false,
        timerType:
          timerSettingss.timerType ||
          (timerSettingss.timer && timerSettingss.timer.timerType) ||
          "",
        timerActivity:
          timerSettingss.timerActivity ||
          (timerSettingss.timer && timerSettingss.timer.timerActivity) ||
          "",
      };
    } else {
      return {
        id: settingId.id,
        label: "",
        placeholder: "",
        showTitle: false,
        timerType: "",
        timerActivity: "",
      };
    }
  });

  const [progressIndicatorSettings, setProgressIndicatorSettings] = useState(
    () => {
      if (progressIndicators && progressIndicators.id === settingId.id) {
        return {
          id: progressIndicators.id,
          label:
            progressIndicators.label ||
            progressIndicators.progress?.label ||
            "",

          showTitle:
            progressIndicators.showTitle ||
            progressIndicators.progress?.showTitle ||
            false,
        };
      } else {
        return {
          id: settingId.id,
          label: "",
          showTitle: false,
        };
      }
    }
  );

  const [selectionItemsSettings, setSelectionItemsSettings] = useState(() => {
    if (selectItemsSettings && selectItemsSettings.id === settingId.id) {
      return {
        id: selectItemsSettings.id,
        label:
          selectItemsSettings.label ||
          selectItemsSettings.selectionItem?.label ||
          "",
        minChoice:
          selectItemsSettings.minChoice ||
          selectItemsSettings.selectionItem?.minChoice ||
          "",
        maxChoice:
          selectItemsSettings.maxChoice ||
          selectItemsSettings.selectionItem?.maxChoice ||
          "",
      };
    } else {
      return {
        id: settingId.id,
        label: "",
        minChoice: "",
        maxChoice: "",
      };
    }
  });

  const [listItemsSettings, setListItemsSettings] = useState(() => {
    if (listItemsSettingss && listItemsSettingss.id === settingId.id) {
      return {
        id: listItemsSettingss.id,
        label:
          listItemsSettingss.label || listItemsSettingss.listItem?.label || "",
        showTitle:
          listItemsSettingss.showTitle ||
          listItemsSettingss.listItem?.showTitle ||
          false,
      };
    } else {
      return {
        id: settingId.id,
        label: "",
        showTitle: false,
      };
    }
  });

  const [continuationOptionsSettings, setcCntinuationOptionsSettings] =
    useState(() => {
      if (
        continuationOptionsSettingss &&
        continuationOptionsSettingss.id === settingId.id
      ) {
        return {
          id: continuationOptionsSettingss.id,
          label:
            continuationOptionsSettingss.label ||
            continuationOptionsSettingss.listItem?.label ||
            "",
          showTitle:
            continuationOptionsSettingss.showTitle ||
            continuationOptionsSettingss.listItem?.showTitle ||
            false,
        };
      } else {
        return {
          id: settingId.id,
          label: "",
          showTitle: false,
        };
      }
    });

  const editorRef = useRef(null);

  const [allSelectionItems, setAllSelectionItems] = useState([
    {
      id: uuidv4(),
      label: "",
      checked: false,
    },
  ]);
  const [allListItems, setAllListItems] = useState([
    {
      id: uuidv4(),
      label: "",
      checked: false,
    },
  ]);
  const [allListContinuation, setAllListContinuation] = useState([
    {
      id: uuidv4(),
      label: "",
    },
  ]);

  const handleDeleteSelect = (id) => {
    const updatedSelectionItems = allSelectionItems.filter(
      (item) => item.id !== id
    );
    setAllSelectionItems(updatedSelectionItems);
  };
  const handleDeleteItem = (id) => {
    const updatedListItems = allListItems.filter((item) => item.id !== id);
    setAllListItems(updatedListItems);
  };

  const handleDeleteContinuation = (id) => {
    const updatedListContinuation = allListContinuation.filter(
      (item) => item.id !== id
    );
    setAllListContinuation(updatedListContinuation);
  };

  const handleSelectChange = (e, id) => {
    const updatedSelectionItems = allSelectionItems.map((item) => {
      if (item.id === id) {
        if (e.target.name === "selectionItems") {
          return {
            ...item,
            checked: e.target.checked,
          };
        } else if (e.target.name === "textField") {
          return {
            ...item,
            label: e.target.value,
          };
        }
      }
      return item;
    });

    setAllSelectionItems(updatedSelectionItems);
  };

  const handleListItemChange = (e, id) => {
    const updatedListItems = allListItems.map((item) => {
      if (item.id === id) {
        if (e.target.name === "selectionItems") {
          return {
            ...item,
            checked: e.target.checked,
          };
        } else if (e.target.name === "textField") {
          return {
            ...item,
            label: e.target.value,
          };
        }
      }
      return item;
    });

    setAllListItems(updatedListItems);
  };

  const handleListContinuationChange = (e, id) => {
    const updatedListItems = allListContinuation.map((item) => {
      if (item.id === id) {
        if (e.target.name === "textField") {
          return {
            ...item,
            label: e.target.value,
          };
        }
      }
      return item;
    });

    setAllListContinuation(updatedListItems);
  };

  const handleActivityChange = (e) => {
    if (e.target.type === "checkbox") {
      setActivityTitle({
        ...actiivityTitle,
        [e.target.name]: e.target.checked,
      });
    } else {
      setActivityTitle({
        ...actiivityTitle,
        [e.target.name]: e.target.value,
      });
    }
  };

  useEffect(() => {
    const selectItemsSettings = JSON.parse(
      localStorage.getItem(`selectItemsSettings_${settingId?.id}`)
    );

    if (selectItemsSettings?.allSelectionItem) {
      setAllSelectionItems(selectItemsSettings?.allSelectionItem);
    } else if (selectItemsSettings?.selectionItem?.allSelectionItem) {
      setAllSelectionItems(selectItemsSettings?.selectionItem.allSelectionItem);
    }
  }, []);
  useEffect(() => {
    const continuationOptionsSettingss = JSON.parse(
      localStorage.getItem(`continuationOptionsSettings_${settingId.id}`)
    );

    if (continuationOptionsSettingss?.allListContinuation) {
      setAllListContinuation(continuationOptionsSettingss?.allListContinuation);
    } else if (continuationOptionsSettingss?.allListContinuation) {
      setAllListContinuation(continuationOptionsSettingss?.allListContinuation);
    }
  }, []);
  useEffect(() => {
    const listItemsSettingss = JSON.parse(
      localStorage.getItem(`listItemsSettings_${settingId.id}`)
    );

    if (listItemsSettingss?.allListItem) {
      setAllListItems(listItemsSettingss?.allListItem);
    } else if (listItemsSettingss?.listItem.allListItem) {
      setAllListItems(listItemsSettingss?.listItem.allListItem);
    }
  }, []);
  const handleSelectionItemsChange = (e) => {
    const { name, value } = e.target;
    setSelectionItemsSettings({
      ...selectionItemsSettings,
      [name]: value,
    });
  };
  const handleListItemsChange = (e) => {
    const { name, value } = e.target;
    if (e.target.type === "checkbox") {
      setListItemsSettings({
        ...listItemsSettings,
        showTitle: e.target.checked,
      });
    } else {
      setListItemsSettings({
        ...listItemsSettings,
        [name]: value,
      });
    }
  };
  const handleContinuationOptionsChange = (e) => {
    const { name, value } = e.target;
    if (e.target.type === "checkbox") {
      setcCntinuationOptionsSettings({
        ...continuationOptionsSettings,
        showTitle: e.target.checked,
      });
    } else {
      setcCntinuationOptionsSettings({
        ...continuationOptionsSettings,
        [name]: value,
      });
    }
  };
  const handleTextBoxSettingChange = (e) => {
    if (e.target.type === "checkbox") {
      setTextBoxSettings({
        ...textBoxSettings,
        richText: e.target.checked,
      });
    } else {
      setTextBoxSettings({
        ...textBoxSettings,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleTimerSettingChange = (e) => {
    if (e.target.type === "checkbox") {
      setTimerSettings({
        ...timerSettings,
        showTitle: e.target.checked,
      });
    } else {
      setTimerSettings({
        ...timerSettings,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleGuidanceTextSettingsChange = (e) => {
    if (e.target.type === "checkbox") {
      setGuidanceTextSettings({
        ...guidanceTextSettings,
        [e.target.name]: e.target.checked,
      });
    } else {
      setGuidanceTextSettings({
        ...guidanceTextSettings,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleProgressIndicatorSettingsChange = (e) => {
    if (e.target.type === "checkbox") {
      setProgressIndicatorSettings({
        ...progressIndicatorSettings,
        showTitle: e.target.checked,
      });
    } else {
      setProgressIndicatorSettings({
        ...progressIndicatorSettings,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleAddSelect = () => {
    setAllSelectionItems([
      ...allSelectionItems,
      {
        id: uuidv4(),
        label: "",
        checked: false,
      },
    ]);
  };
  const handleListSelect = () => {
    setAllListItems([
      ...allListItems,
      {
        id: uuidv4(),
        label: "",
        checked: false,
      },
    ]);
  };

  const handleAddContinuation = () => {
    setAllListContinuation([
      ...allListContinuation,
      {
        id: uuidv4(),
        label: "",
      },
    ]);
  };

  const startTimer = () => {
    const { seconds, minutes, hours } = inputTime;
    const totalTime = seconds + minutes * 60 + hours * 3600;
    return formatTime(totalTime);
  };
  const handleSaveSettings = () => {
    if (settingId.btnId === "activityTitle") {
      if (actiivityTitle.richTextBody) {
        localStorage.setItem("activityTitle", JSON.stringify(actiivityTitle));
      }
    }
    if (settingId.btnId === "textBox") {
      if (Object.values(textBoxSettings).length > 0) {
        localStorage.setItem(
          `textBoxSettings_${textBoxSettings.id}`,
          JSON.stringify(textBoxSettings)
        );
      }
    }
    if (settingId.btnId === "selectionItems") {
      if (Object.values(selectionItemsSettings).length > 0) {
        localStorage.setItem(
          `selectItemsSettings_${settingId.id}`,
          JSON.stringify({
            ...selectionItemsSettings,
            allSelectionItem: allSelectionItems,
          })
        );
      }
    }
    if (settingId.btnId === "listOfItems") {
      if (
        Object.values(listItemsSettings).length > 0 &&
        allListItems.length > 0
      ) {
        localStorage.setItem(
          `listItemsSettings_${settingId.id}`,
          JSON.stringify({
            ...listItemsSettings,
            allListItem: allListItems,
          })
        );
      }
    }
    if (settingId.btnId === "guidanceText") {
      if (Object.values(guidanceTextSettings).length > 0) {
        localStorage.setItem(
          `guidanceTextSettings_${settingId.id}`,
          JSON.stringify(guidanceTextSettings)
        );
      }
    }
    if (settingId.btnId === "progressIndicator") {
      if (Object.values(progressIndicatorSettings).length > 0) {
        localStorage.setItem(
          `progressIndicator_${settingId.id}`,
          JSON.stringify(progressIndicatorSettings)
        );
      }
    }
    if (settingId.btnId === "timer") {
      if (Object.values(timerSettings).length > 0) {
        localStorage.setItem(
          `timerSettings_${settingId.id}`,
          JSON.stringify({
            ...timerSettings,
            time: startTimer(),
            inputTime: inputTime,
          })
        );
      }
    }
    if (settingId.btnId === "continuationOption") {
      if (
        Object.values(continuationOptionsSettings).length > 0 &&
        allListContinuation.length > 0
      ) {
        localStorage.setItem(
          `continuationOptionsSettings_${settingId.id}`,
          JSON.stringify({
            ...continuationOptionsSettings,
            allListContinuation: allListContinuation,
          })
        );
      }
    }
    setState({ right: false });
  };

  let torender;
  if (settingId.btnId === "textBox") {
    torender = (
      <Stack>
        <Stack padding={"24px"}>
          <Typography fontSize={"16px"} fontWeight={400}>
            Label
          </Typography>
          <TextField
            fullWidth
            name="label"
            variant="outlined"
            value={textBoxSettings.label}
            onChange={handleTextBoxSettingChange}
            defaultValue={"Text Box"}
          />
        </Stack>
        <Divider />
        <Stack padding={"24px"}>
          {!textBoxSettings.richText && (
            <>
              <Typography fontSize={"16px"} fontWeight={400}>
                Placeholder text
              </Typography>
              <TextField
                fullWidth
                onChange={handleTextBoxSettingChange}
                name="placeholder"
                variant="outlined"
                value={textBoxSettings.placeholder}
                defaultValue={"Input"}
              />
            </>
          )}
        </Stack>
        <FormControlLabel
          sx={{ paddingX: "24px" }}
          control={
            <Checkbox
              checked={textBoxSettings.richText}
              onChange={handleTextBoxSettingChange}
            />
          }
          label="Enable rich text"
        />
        {textBoxSettings.richText && (
          <Box mx={"auto"} width={"90%"}>
            <RichTextEditor
              setBody={setTextBoxSettings}
              body={textBoxSettings}
            />
          </Box>
        )}
      </Stack>
    );
  } else if (settingId.btnId === "activityTitle") {
    torender = (
      <Stack>
        <Stack spacing={"16px"} alignItems={"flex-start"} padding={"24px"}>
          <Typography fontSize={"16px"} fontWeight={400}>
            Activity Title
          </Typography>
          <Box>
            <RichTextEditor setBody={setActivityTitle} body={actiivityTitle} />
          </Box>
        </Stack>
        <Divider />
        <Stack spacing={"16px"} alignItems={"flex-start"} padding={"24px"}>
          <Typography fontSize={"16px"} fontWeight={400}>
            Add tag(s)
          </Typography>
          <Typography fontSize={"12px"} fontWeight={400} color={"gray.300"}>
            Use # to create a hashtag. You can create up to 3 tags.
          </Typography>
          <TextField
            fullWidth
            name="tags"
            variant="outlined"
            value={actiivityTitle.tags}
            defaultValue={"Optional"}
            onChange={(e) => handleActivityChange(e)}
          />
        </Stack>
        <Divider />
        <Stack spacing={"16px"} alignItems={"flex-start"} padding={"24px"}>
          <FormControlLabel
            name="restoreDefault"
            control={
              <Checkbox
                checked={actiivityTitle.restoreDefault}
                onChange={(e) => handleActivityChange(e)}
              />
            }
            label="Restore defaults on rentry
"
          />
        </Stack>
      </Stack>
    );
  } else if (settingId.btnId === "selectionItems") {
    torender = (
      <Stack>
        <Stack spacing={"16px"} alignItems={"flex-start"} padding={"24px"}>
          <Typography fontSize={"16px"} fontWeight={400}>
            Label
          </Typography>
          <TextField
            fullWidth
            name="label"
            variant="outlined"
            value={selectionItemsSettings.label}
            onChange={(e) => handleSelectionItemsChange(e)}
            defaultValue={"Selection Items"}
          />
        </Stack>
        <Divider />
        <Stack spacing={"16px"} alignItems={"flex-start"} padding={"24px"}>
          <Typography fontSize={"16px"} fontWeight={400}>
            Selection Items
          </Typography>
          <Stack width={"100%"} spacing={"16px"}>
            <ReactSortable
              list={allSelectionItems}
              setList={setAllSelectionItems}
            >
              {allSelectionItems?.map((item) => (
                <Box
                  paddingY={"16px"}
                  width={"100%"}
                  alignItems="center"
                  gap={1}
                  display={"flex"}
                  key={item.id}
                >
                  <Checkbox
                    name="selectionItems"
                    checked={item.checked}
                    onChange={(e) => handleSelectChange(e, item.id)}
                  />
                  <TextField
                    fullWidth
                    name="textField"
                    variant="outlined"
                    value={item.label}
                    defaultValue={"Input"}
                    onChange={(e) => handleSelectChange(e, item.id)}
                  />
                  <IconButton>
                    <CloseOutlinedIcon
                      onClick={() => handleDeleteSelect(item.id)}
                    />
                  </IconButton>
                </Box>
              ))}
            </ReactSortable>
          </Stack>
          <Stack width={"100%"} direction={"row"} justifyContent={"center"}>
            <Button onClick={handleAddSelect}>
              <AddIcon /> Add More
            </Button>
          </Stack>
        </Stack>
        <Divider />
        <Stack spacing={"16px"} alignItems={"flex-start"} padding={"24px"}>
          <Typography fontSize={"16px"} fontWeight={400}>
            Choice of Limit
          </Typography>
          <Stack direction={"row"} spacing={2}>
            <TextField
              fullWidth
              name="minChoice"
              variant="outlined"
              type="number"
              value={selectionItemsSettings.minChoice}
              defaultValue={0}
              onChange={handleSelectionItemsChange}
              InputProps={{
                inputProps: {
                  min: 0,
                },
              }}
            />
            <TextField
              fullWidth
              name="maxChoice"
              variant="outlined"
              type="number"
              value={selectionItemsSettings.maxChoice}
              onChange={handleSelectionItemsChange}
              defaultValue={1}
              InputProps={{
                inputProps: {
                  min: 0,
                },
              }}
            />
          </Stack>
        </Stack>
      </Stack>
    );
  } else if (settingId.btnId === "listOfItems") {
    torender = (
      <Stack>
        <Stack spacing={"16px"} alignItems={"flex-start"} padding={"24px"}>
          <Typography fontSize={"16px"} fontWeight={400}>
            List of Items title
          </Typography>
          <TextField
            fullWidth
            name="label"
            variant="outlined"
            value={listItemsSettings.label}
            onChange={(e) => handleListItemsChange(e)}
            defaultValue={"Selection Items"}
          />
        </Stack>
        <Divider />
        <Stack spacing={"16px"} alignItems={"flex-start"} padding={"24px"}>
          <Typography fontSize={"16px"} fontWeight={400}>
            List of Items
          </Typography>
          <Typography fontSize={"14px"} color={"gray.300"}>
            Drag an item to reorder the sequence that will be displayed.
          </Typography>
          <Stack width={"100%"} spacing={"16px"}>
            <ReactSortable list={allListItems} setList={setAllListItems}>
              {allListItems.map((item) => (
                <Box
                  paddingY={"16px"}
                  width={"100%"}
                  alignItems="center"
                  gap={1}
                  display={"flex"}
                  key={item.id}
                >
                  <TextField
                    fullWidth
                    name="textField"
                    variant="outlined"
                    value={item.label}
                    defaultValue={"Input"}
                    onChange={(e) => handleListItemChange(e, item.id)}
                  />
                  <IconButton>
                    <CloseOutlinedIcon
                      onClick={() => handleDeleteItem(item.id)}
                    />
                  </IconButton>
                </Box>
              ))}
            </ReactSortable>
          </Stack>
          <Stack width={"100%"} direction={"row"} justifyContent={"center"}>
            <Button onClick={handleListSelect}>
              <AddIcon /> Add More
            </Button>
          </Stack>
        </Stack>
        <Divider />
      </Stack>
    );
  } else if (settingId.btnId === "guidanceText") {
    torender = (
      <Stack>
        <Stack spacing={"10px"} justifyContent={"flex-start"} padding={"24px"}>
          <Typography fontSize={"16px"} fontWeight={400}>
            Title
          </Typography>
          <TextField
            fullWidth
            name="label"
            variant="outlined"
            value={guidanceTextSettings.label}
            onChange={handleGuidanceTextSettingsChange}
            defaultValue={"Guidance Text"}
          />
        </Stack>
        <Divider />
        <Stack padding={"24px"}>
          {!guidanceTextSettings.richText && (
            <>
              <Typography fontSize={"16px"} fontWeight={400}>
                Add guidance text
              </Typography>
              <TextField
                fullWidth
                onChange={handleGuidanceTextSettingsChange}
                name="placeholder"
                variant="outlined"
                multiline
                rows={4}
                value={guidanceTextSettings.placeholder}
                defaultValue={"Input"}
              />
            </>
          )}

          <FormControlLabel
            control={
              <Checkbox
                name="richText"
                checked={guidanceTextSettings.richText}
                onChange={handleGuidanceTextSettingsChange}
              />
            }
            label="Enable rich text"
          />
          {guidanceTextSettings.richText && (
            <Box mx={"auto"} width={"90%"}>
              <RichTextEditor
                setBody={setGuidanceTextSettings}
                body={guidanceTextSettings}
              />
            </Box>
          )}
        </Stack>
      </Stack>
    );
  } else if (settingId.btnId === "progressIndicator") {
    torender = (
      <Stack>
        <Stack spacing={"10px"} justifyContent={"flex-start"} padding={"24px"}>
          <Typography fontSize={"16px"} fontWeight={400}>
            Progress indicator title
          </Typography>
          <TextField
            fullWidth
            name="label"
            variant="outlined"
            value={progressIndicatorSettings.label}
            onChange={handleProgressIndicatorSettingsChange}
          />
        </Stack>
        <Divider />
      </Stack>
    );
  } else if (settingId.btnId === "timer") {
    torender = (
      <Stack>
        <Stack spacing={"10px"} justifyContent={"flex-start"} padding={"24px"}>
          <Typography fontSize={"16px"} fontWeight={400}>
            Timer Title
          </Typography>
          <TextField
            fullWidth
            name="label"
            variant="outlined"
            value={timerSettings.label}
            onChange={handleTimerSettingChange}
          />
          <Divider />
          <Stack spacing={2}>
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">
                Select type of timer
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="timerType"
                value={timerSettings.timerType}
                onChange={handleTimerSettingChange}
              >
                <FormControlLabel
                  value="Countdown timer"
                  control={<Radio />}
                  label="Countdown timer"
                />
                <FormControlLabel
                  value="Stopwatch"
                  control={<Radio />}
                  label="Stopwatch"
                />
              </RadioGroup>
            </FormControl>
            <Divider />
            <Timer
              inputTime={inputTime}
              setInputTime={setInputTime}
              setActivityTime={setActivityTime}
              activityTime={activityTime}
            />
          </Stack>
        </Stack>
        <Divider />
        <Stack padding={4} spacing={2}>
          <Typography>Choice Of Rules</Typography>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-standard-label">
              Select Activity
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              name="timerActivity"
              value={timerSettings.timerActivity}
              onChange={handleTimerSettingChange}
              label=" Select Activity"
            >
              {activitiess &&
                activitiess.cells &&
                activitiess.cells.map((item) =>
                  item.type === "Activity" ? (
                    <MenuItem value={item.title} key={item.id}>
                      {item.title}
                    </MenuItem>
                  ) : null
                )}
            </Select>
          </FormControl>
        </Stack>
      </Stack>
    );
  } else if (settingId.btnId === "continuationOption") {
    torender = (
      <Stack>
        <Stack spacing={"16px"} alignItems={"flex-start"} padding={"24px"}>
          <Typography fontSize={"16px"} fontWeight={400}>
            Label
          </Typography>
          <TextField
            fullWidth
            name="label"
            variant="outlined"
            value={continuationOptionsSettings.label}
            onChange={(e) => handleContinuationOptionsChange(e)}
            defaultValue={"Selection Items"}
          />
        </Stack>
        <Divider />
        <Stack spacing={"16px"} alignItems={"flex-start"} padding={"24px"}>
          <Typography fontSize={"16px"} fontWeight={400}>
            List of continuation Options
          </Typography>
          <Typography fontSize={"14px"} color={"gray.300"}>
            Drag an item to reorder the sequence that will be displayed.
          </Typography>
          <Stack width={"100%"} spacing={"16px"}>
            <ReactSortable
              list={allListContinuation}
              setList={setAllListContinuation}
            >
              {allListContinuation.map((item) => (
                <Box
                  paddingY={"16px"}
                  width={"100%"}
                  alignItems="center"
                  gap={1}
                  display={"flex"}
                  key={item.id}
                >
                  <TextField
                    fullWidth
                    name="textField"
                    variant="outlined"
                    value={item.label}
                    defaultValue={"Input"}
                    onChange={(e) => handleListContinuationChange(e, item.id)}
                  />
                  <IconButton>
                    <CloseOutlinedIcon
                      onClick={() => handleDeleteContinuation(item.id)}
                    />
                  </IconButton>
                </Box>
              ))}
            </ReactSortable>
          </Stack>
          <Stack width={"100%"} direction={"row"} justifyContent={"center"}>
            <Button onClick={handleAddContinuation}>
              <AddIcon /> Add More
            </Button>
          </Stack>
        </Stack>
        <Divider />
      </Stack>
    );
  }
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"space-between"}
      height={"100vh"}
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : "421px",
      }}
      role="presentation"
      //   onClick={toggleDrawer(anchor, false)}
      //   onKeyDown={toggleDrawer(anchor, false)}
    >
      <Stack>
        <Stack my={"16px"} spacing={"16px"}>
          <Stack
            paddingX={"16px"}
            direction={"row"}
            justifyContent={"space-between"}
          >
            <Typography fontSize={"18px"} fontWeight={500} color={"#475467"}>
              Properties
            </Typography>
            <IconButton onClick={onClose}>
              <CloseOutlinedIcon />
            </IconButton>
          </Stack>
        </Stack>
        <Divider />
        {torender}
      </Stack>
      <Stack
        paddingX={"24px"}
        paddingY={"16px"}
        sx={{ backgroundColor: "gray.50" }}
        justifyContent={"flex-end"}
        direction={"row"}
      >
        <Stack direction={"row"} spacing={1}>
          <Button onClick={onClose} sx={{ width: "auto" }}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleSaveSettings();
            }}
            sx={{
              width: "auto",
              backgroundColor: "primary.500",
              color: "white",
            }}
          >
            Save
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default ActivitySetting;

import "./chatOnline.css";
import ProfilePicture from "../../../Assets/profile.png";
import ChatServices from "../../../Redux/api/ChatServices";

export default function ChatOnline({ setCurrentChat, experts }) {
  const handleClick = async (user) => {
    try {
      const res = await ChatServices.createNewConversation({
        receiverId: user._id,
      });
      setCurrentChat(res.data.data);
    } catch (err) {}
  };

  return (
    <div className="chatOnline">
      {experts?.map((o) => (
        <div
          className="chatOnlineFriend"
          onClick={() => handleClick(o)}
          key={o._id}
        >
          <div className="chatOnlineImgContainer">
            <img
              className="chatOnlineImg"
              src={o?.profilePicture ? o?.profilePicture : ProfilePicture}
              alt=""
            />
            <div className="chatOnlineBadge"></div>
          </div>
          <span className="chatOnlineName">{o?.fullName}</span>
        </div>
      ))}
    </div>
  );
}

import { callAPi } from "./http-common";

const login = (data) => {
  return callAPi.post("/auth/login", data);
};
const register = (data) => {
  return callAPi.post("/auth/signUpByEmail", data);
};
const createUser = (data) => {
  return callAPi.post("/auth/createUser", data);
};
const verifyEmail = (data) => {
  return callAPi.post("/auth/verifyCode", data);
};

const forgotPassword = (data) => {
  return callAPi.post("/auth/forgotPassword", data);
};

const resetPassword = (data) => {
  return callAPi.post("/auth/resetPassword", data);
};
const socialLogin = (data) => {
  return callAPi.post("/auth/socialLogin", data);
};

const UserServices = {
  login,
  register,
  verifyEmail,
  forgotPassword,
  resetPassword,
  createUser,
  socialLogin,
};

export default UserServices;
